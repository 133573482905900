import { AppSettings } from "@/types";

class SettingsManager {

    public data: AppSettings | null

    constructor(public def: AppSettings | null) {
        this.data = def
    }

    setData(newSettings: AppSettings) {
        this.data = newSettings
    }
}

export default new SettingsManager(null)