import { useRef, useState } from "react";
import { emitEvent, eventConstants } from "../main";
import { useAtomValue } from "jotai";
import { SymbolsAtom } from "@/atoms";
import { CreateBasketItem, Symbol } from "@/types";
import NumberInput from "./ui/NumberInput";
import { createBasket } from "@/utils";
import useBoundStore from "./stores/store";


export default function () {

    const basketItems = useBoundStore(state => state.basketItems)
    const basketName = useBoundStore(state => state.basketName)
    const setBasketName = useBoundStore(state => state.setBasketName)
    const addBasketItem = useBoundStore(state => state.addBasketItem)
    const deleteBasketItem = useBoundStore(state => state.deleteBasketItem)

    const checkboxRef = useRef<null | HTMLInputElement>(null)
    const [positionsMenuOpen, setPositionsMenuOpen] = useState(false)


    const closeDrawer = () => {
        emitEvent(eventConstants.CLOSE_DRAWER, "")
    }

    const openAddPositionMenu = () => {
        setPositionsMenuOpen(true)
    }

    const addSelectedPositionToList = (data: CreateBasketItem) => {
        addBasketItem(data)
        setPositionsMenuOpen(false)
    }


    const submitBasket = async () => {


        if (basketName.trim().length === 0) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Lütfen Sepet İsmi Yazınız!" })
            return
        }

        if (basketItems.length < 2 || basketItems.length > 10) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Sepetinizde en az 2, en fazla 10 pozisyon olabilir!" })
            return
        }

        const [_, err] = await createBasket({
            name: basketName, basketItems: [...basketItems.map(item => {
                return { symbol: item.symbol.Symbol, volume: item.lot.toFixed(2), type: item.mode }
            })]
        })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Sepet Oluşturulurken hata oluştu!" })
            return
        }


        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Sepet Başarıyla oluşturuldu!" })
        emitEvent(eventConstants.RE_FETCH_TRADER_BASKETS, "")
    }

    return <>
        <div>

            <div className="drawer drawer-end w- relative z-50">
                <input ref={checkboxRef} id="createBasketDrawer" type="checkbox" defaultChecked onChange={(e) => {
                    if (!e.target.checked) {
                        closeDrawer()
                    }

                }} className="drawer-toggle" />
                <div className="drawer-content">
                    {/* Page content here */}
                </div>
                <div className="drawer-side">
                    <label htmlFor="createBasketDrawer" aria-label="close sidebar" className="drawer-overlay"></label>
                    <ul className="menu flex-nowrap p-4 w-96 h-screen bg-base-200 text-base-content">

                        {positionsMenuOpen && <ChoosePositionsMenu close={() => setPositionsMenuOpen(false)} sendData={addSelectedPositionToList} />}

                        {!positionsMenuOpen && <>
                            <div className="mb-10"></div>
                            <div className="flex items-center justify-between">
                                <span className="text-4xl font-bold">Sepet Yarat</span>
                                <button className="btn btn-circle " onClick={() => closeDrawer()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                    </svg>
                                </button>

                            </div>
                            <div className="mb-10"></div>

                            <div className="flex flex-col gap-3">
                                <p className="p-4 bg-base-100 rounded-lg">
                                    Pozisyon sepetinizde en az 2 tane, en fazla 10 tane pozisyon olabilir. Eklediğin pozisyonları düzenlemek için sağa kaydır, eklediğin poziyonu silmek için sola kaydır.
                                </p>
                                <button className="btn btn-primary btn-sm w-full" onClick={openAddPositionMenu}>Pozisyon Ekle</button>
                            </div>

                            <div className="flex flex-col gap-3 mt-10">
                                <p className="font-bold">Sepet İsmi</p>
                                <input className="input input-bordered rounded" onChange={(e) => setBasketName(e.target.value)} value={basketName} />
                            </div>

                            <div className="flex flex-col flex-1 gap-3 mt-10 overflow-y-auto">
                                <p className="font-bold">Sepetimdeki Pozisyonlar</p>

                                {basketItems.length > 0 ? <div className="flex flex-1 overflow-y-auto flex-col gap-3">
                                    {basketItems.map(item => {
                                        return <div key={item.symbol.Name} className="p-3 font-bold flex justify-between items-center rounded bg-base-300 hover:bg-base-100 cursor-pointer ">
                                            <div className="flex gap-2 items-center">
                                                <div className="w-12">
                                                    <img className="h-6" src={`https://cdn.fxcrm.me/parite/${item.symbol.Symbol.toLowerCase()}.png`} />
                                                </div>

                                                <div className="flex flex-col gap-2">
                                                    <p>{item.symbol.Name}</p>
                                                    <p className="text-xs opacity-75">{item.lot.toFixed(2)}</p>
                                                </div>
                                            </div>

                                            <div className="flex items-center justify-center text-error gap-2" onClick={() => deleteBasketItem(item.symbol.Symbol)}>
                                                <div className={"rounded btn btn-xs " + (item.mode === "BUY" ? "btn-success" : "btn-error")}>{item.mode === "BUY" ? "AL" : "SAT"}</div>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                        </div>
                                    })}
                                </div> : <p className="p-4 bg-base-100 rounded-lg">
                                    Sepetinizde henüz pozisyon bulunmamaktadır. Pozisyon Ekle butonuna tıklayarak sepetinize pozisyon ekleyebilirsiniz.
                                </p>}

                            </div>

                            <div className="flex flex-col justify-end mt-4">
                                <button className="btn btn-primary" onClick={submitBasket}>Sepeti Aç</button>
                            </div>
                        </>}

                    </ul>
                </div>
            </div>
        </div>
    </>
}



function ChoosePositionsMenu({ sendData, close }: { sendData: (positionsData: CreateBasketItem) => void, close: () => void }) {

    const symbolsValue = useAtomValue(SymbolsAtom)
    const [symbolMenuOpen, setSymbolMenuOpen] = useState(false);
    const [selectedSymbol, setSelectedSymbol] = useState<null | Symbol>(null)

    function handleSendData(data: CreateBasketItem) {
        sendData(data)
    }

    function addSymbolToSelected(symbol: Symbol) {
        setSelectedSymbol(symbol)
    }

    return <>

        {symbolMenuOpen && <>
            <div className="mb-10"></div>
            <div className="flex items-center gap-3">
                <button onClick={() => {
                    setSelectedSymbol(null)
                    setSymbolMenuOpen(false)
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                </button>

                <span className="text-3xl font-bold">Pozisyon Seç</span>
            </div>
            <div className="mb-10"></div>

            <div className="join">
                <input type="text" placeholder="Ara" name="search" className="input input-bordered border-base-300 w-full bg-base-300 join-item" />
                <button className="btn border border-base-300 bg-base-300 border-l-2 border-l-base-100 join-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clipRule="evenodd" />
                    </svg>

                </button>
            </div>

            <div className="mb-4"></div>


            <div className="flex flex-col gap-2 flex-1 overflow-scroll">
                {symbolsValue && symbolsValue.map(symbol => {
                    return <div onClick={() => addSymbolToSelected(symbol)} key={symbol.Name} className="p-3 font-bold flex justify-between items-center rounded bg-base-300 hover:bg-base-100 cursor-pointer">
                        <div className="flex gap-2 items-center">
                            <div className="w-12">
                                <img className="h-6" src={`https://cdn.fxcrm.me/parite/${symbol.Symbol.toLowerCase()}.png`} />
                            </div>
                            <p>{symbol.Name}</p>
                        </div>

                        {symbol.Name === selectedSymbol?.Name && <div className="flex items-center justify-center text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                            </svg>
                        </div>}
                    </div>
                })}

            </div>

            <div className="flex items-end mt-6">
                <button className="btn btn-primary w-full btn-sm" onClick={() => setSymbolMenuOpen(false)}>Seç</button>
            </div>
        </>}

        {!symbolMenuOpen && <>
            <div className="mb-10"></div>
            <div className="flex items-center gap-3">
                <button onClick={() => {
                    close()
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                </button>
                <span className="text-xl font-bold">Sepete Pozisyon Ekle</span>
            </div>

            <div className="mb-10"></div>
            <button className="btn btn-primary w-full btn-sm" onClick={() => setSymbolMenuOpen(true)}>Sembol Seç</button>

            <div className="flex flex-col gap-3">

                {selectedSymbol && <SelectedSymbolForm save={handleSendData} selectedSymbol={selectedSymbol} unselectSymbol={() => setSelectedSymbol(null)} />}

            </div>


        </>}

    </>
}



function SelectedSymbolForm({ selectedSymbol, unselectSymbol, save }: { selectedSymbol: Symbol, unselectSymbol: () => void, save: ({ symbol, lot, mode }: CreateBasketItem) => void }) {

    const [mode, setMode] = useState<"BUY" | "SELL">("BUY")
    const [lot, setLot] = useState(0.01)


    function handleLotChange(val: string) {
        if (parseFloat(val) < 0.01) {
            return
        }

        if (parseFloat(val) > 25.00) {
            return
        }
        setLot(parseFloat(val))
    }



    function handleSave() {
        save({ id: selectedSymbol.Symbol, symbol: selectedSymbol, lot: lot, mode: mode })
    }
    return <div className="flex flex-col gap-3">

        <div className="flex flex-col gap-3 mt-8">
            <p className="font-semibold">Seçilen Sembol</p>
            <div className="p-3 font-bold flex justify-between items-center rounded bg-base-300 hover:bg-base-100 cursor-pointer ">
                <div className="flex gap-2 items-center">
                    <div className="w-12">
                        <img className="h-6" src={`https://cdn.fxcrm.me/parite/${selectedSymbol.Symbol.toLowerCase()}.png`} />
                    </div>
                    <p>{selectedSymbol.Name}</p>
                </div>

                <div className="flex items-center justify-center text-error" onClick={unselectSymbol}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                    </svg>
                </div>
            </div>
        </div>


        <div className="flex flex-col gap-3">
            <p className="font-semibold">İşlem Türü</p>

            <div className="flex items-center gap-2">
                <div onClick={() => setMode("BUY")} className={`font-bold bg-base-200 flex p-2 rounded-lg flex-1 justify-center cursor-pointer border-dashed border hover:border-success ${mode === "BUY" ? "bg-success border-success" : " border-neutral"}`}>
                    <button className=" rounded px-2 text-sm flex items-center uppercase text-white ">Al</button>
                </div>
                <div onClick={() => setMode("SELL")} className={`font-bold bg-base-200 flex p-2 rounded-lg flex-1 justify-center cursor-pointer border border-dashed hover:border-error ${mode === "SELL" ? "bg-error border-error" : " border-neutral"}`}>
                    <div className=" rounded px-2 text-sm flex items-center uppercase text-white">Sat</div>
                </div>
            </div>
        </div>

        <div className="flex flex-col gap-3">
            <p className="font-semibold">Pozisyon Miktarı</p>

            <div className="bg-base-100 rounded-lg p-4">

                <NumberInput digits={2} min={0.01} max={25.00} defaultValue={lot} onChange={(val) => {
                    if (val) {
                        handleLotChange(val.toFixed(2))
                    }
                }} />
            </div>
        </div>


        <div className="flex-1 flex items-end">
            <button className="btn btn-primary w-full btn-sm" onClick={handleSave}>Ekle</button>
        </div>
    </div>
}