import { Trader } from "@/types";
import TraderProfileModal from "./TraderProfileModal";
import { FormEvent, useEffect, useState } from "react";
import { emitEvent, eventConstants, onEvent } from "../main";
import { deleteUserTraderProfile, editUserTraderProfile, getTrader } from "@/utils";
import { useNavigate } from "react-router-dom";



export default function () {

    const [traderModalSettings, setTraderModalSettings] = useState<{ open: boolean, trader: Trader | null }>({ open: false, trader: null })
    const navigate = useNavigate()

    async function handleDeleteTrader() {

        const [_, err] = await deleteUserTraderProfile()

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Trader profiliniz silinirken hata oluştu!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Trader profiliniz başarıyla silindi!" })
        emitEvent(eventConstants.RE_FETCH_USER_INFO, {})
        setTraderModalSettings({ open: false, trader: null })
    }

    function handleOnClose() {
        setTraderModalSettings({ open: false, trader: null })
    }


    async function handleEditTraderProfile(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const traderData = new FormData(e.target as HTMLFormElement)


        const [_, err] = await editUserTraderProfile(traderData)

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Trader profiliniz güncellenirken hata oluştu!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Trader profiliniz başarıyla güncellendi!" })
        setTraderModalSettings({ open: false, trader: null })
    }

    useEffect(() => {

        const onTraderProfileModalOpen = onEvent(eventConstants.OPEN_TRADER_PROFILE_MODEL, async () => {
            const [res, err] = await getTrader()

            if (err) {
                return
            }

            setTraderModalSettings({ open: true, trader: res })
        })


        const onNavigateTraderProfile = onEvent(eventConstants.NAVIGATE_TRADER_PROFILE, async () => {

            const [res, err] = await getTrader()

            if (err) {
                return
            }

            navigate("/traders/" + res._id);
        })

        return () => {
            onTraderProfileModalOpen.unsubscribe()
            onNavigateTraderProfile.unsubscribe()
        }
    }, [traderModalSettings])

    return <>
        {traderModalSettings.open ? <TraderProfileModal trader={traderModalSettings.trader as Trader} onClose={handleOnClose} onDeleteTrader={handleDeleteTrader} onSubmit={handleEditTraderProfile} /> : null}
    </>

}