import Error from "@/error";
import { Basket, BasketWithComplete, Result } from "@/types";
import http from "./http";


type CreateBasketObject = {
    name: string,
    basketItems: {
        "symbol": string,
        "volume": string,
        "type": "BUY" | "SELL"
    }[]
}


export async function getBasketList(traderId: null | string = null): Promise<Result<Basket[], Error>> {

    try {
        const res = await http.get("user/baskets", { params: { "trader": traderId } })
        const data: Basket[] = res.data.data
        return [data, null]
    } catch (error) {
        return [null, new Error("Error while getting basket list", { cause: error })]
    }
}


export async function getSingleBasket(id: string): Promise<Result<BasketWithComplete, Error>> {

    try {
        const res = await http.get("user/basket", { params: { id } })
        const data: BasketWithComplete = res.data.data

        return [data, null]
    } catch (error) {
        return [null, new Error("Error while trying to get basket")]

    }

}

export async function basketRequest(categories: string): Promise<Result<any, Error>> {

    try {
        const res = await http.post("user/basket_request", { categories })
        const data: any = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting a basket request", { cause: err })]
    }
}

export async function basketCompleteItem(basketItemId: string, positionId: string): Promise<Result<any, Error>> {
    try {
        const res = await http.post("user/basket_item_done", { basketItemId: basketItemId, positionId: positionId })
        const data: any = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting a basket request", { cause: err })]
    }
}


export async function createBasket(incData: CreateBasketObject): Promise<Result<{
    "message": "OK",
    "status": true
}, Error>> {
    try {
        const res = await http.post("trader/create_basket", incData)
        const data: {
            "message": "OK",
            "status": true
        } = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while creating basket", { cause: err })]
    }
}