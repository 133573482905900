import { SymbolList, UpdatedPosition } from "@/types"

import { useMemo, useState } from "react"
import { SymbolsAtom } from "@/atoms"
import { useAtomValue } from "jotai"
import PriceText from "./PriceText"
import Collapse from "./Collapse"
import NumberInput from "./ui/NumberInput"
import { ClosePosition, EditPosition } from "@/utils"
import { emitEvent, eventConstants } from "../main"
// import ClientPaginatedList from "./ClientPaginatedList"
import useBoundStore from "./stores/store"
import ClientPaginatedList from "./ClientPaginatedList"

export default function () {

    const zusUpdatedPositions = useBoundStore(state => state.updatedPositions)
    const [selectedPosition, setSelectedPosition] = useState<null | UpdatedPosition>(null)
    function handlePositionClick(position: UpdatedPosition) {

        setSelectedPosition(position)
    }

    function handleClose() {

        setSelectedPosition(null)

    }


    return <>
        {selectedPosition && <>
            <PositionListModal position={selectedPosition} onClosed={() => handleClose()} />
        </>}
        <div className="flex flex-col gap-4 w-full rounded-lg p-0 lg:p-4 ">
            <h2 className="lg:text-xl text-lg">Pozisyonlar</h2>
            <div className="flex flex-1 flex-col gap-4 overflow-y-auto overflow-x-hidden">
                {/* {...zusUpdatedPositions.map(item => {
                    const mode = item.Action === "0" ? "AL" : "SAT"
                   
                })} */}
                <ClientPaginatedList<UpdatedPosition> items={zusUpdatedPositions} paginationLength={10} length={zusUpdatedPositions.length} listComponent={({ item }) => {
                    const mode = item.Action === "0" ? "AL" : "SAT"

                    return <div key={item.Position} className="content-container p-4 rounded-lg flex cursor-pointer hover:bg-base-200" onClick={() => handlePositionClick(item)}>

                        <div className="flex flex-col flex-1 gap-2">
                            <div className="flex gap-3">
                                <span className="font-semibold">{item.Symbol} </span>
                                <span className={"font-semibold " + (mode === "AL" ? "text-success" : "text-error")}>
                                    {mode}  {(parseInt(item.Volume) / 10000).toFixed(2)}
                                </span>
                            </div>

                            <div className="flex gap-2 items-center">
                                <span>{parseFloat(item.PriceOpen).toLocaleString()}</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                                </span>
                                <span>{parseFloat(item.PriceCurrent).toLocaleString()}</span>
                            </div>
                        </div>
                        <div className={"flex items-center font-semibold text-lg " + (parseFloat(item.Profit) > 0 ? "text-success" : "text-error")}>
                            {parseFloat(item.Profit).toFixed(2)}$
                        </div>
                    </div>
                }} />

            </div>
        </div>
    </>
}


function PositionListModal({ position, onClosed }: { position: UpdatedPosition, onClosed: () => void }) {

    const symbols = useAtomValue(SymbolsAtom) as SymbolList
    const [stopLoss, setStopLoss] = useState<any>(parseFloat(position.PriceSL))
    const [takeProfit, setTakeProfit] = useState<any>(parseFloat(position.PriceTP))
    const mode = position.Action === "0" ? "BUY" : "SELL"

    const index = useMemo(() => {
        return symbols.findIndex(item => item.Symbol === position.Symbol) as number
    }, [symbols])


    const onChecked = (property: "stopLoss" | "takeProfit") => {

        return () => {

            if (property === "stopLoss") {
                const hasSL = parseFloat(position.PriceSL) === 0 ? false : true

                if (!hasSL) {
                    setStopLoss(parseFloat(symbols[index].Ask).toFixed(parseInt(position.Digits)))
                }

            } else {
                const hasTP = parseFloat(position.PriceTP) === 0 ? false : true

                if (!hasTP) {
                    setTakeProfit(parseFloat(symbols[index].Ask).toFixed(parseInt(position.Digits)))
                }
            }

        }
    }

    async function handleEditPosition() {
        const parsedSL = parseFloat(stopLoss) === 0 ? "0.0" : parseFloat(stopLoss)
        const parsedTP = parseFloat(takeProfit) === 0 ? "0.0" : parseFloat(takeProfit)


        const [_, err] = await EditPosition({ id: position.Position, sl: parsedSL, tp: parsedTP, digit: parseInt(position.Digits), symbolName: position.Symbol })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { message: "Pozisyon düzenlerken bir hata oluştu" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Posizyon Başarıyla Düzenlendi" })
    }


    async function handleClosePosition() {

        const [_, err] = await ClosePosition({ id: position.Position });

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Posizyon Başarıyla Kapatıldı." })
        emitEvent(eventConstants.REMOVE_POSITION_FROM_LIST, position.Position)
        emitEvent(eventConstants.ON_ORDER_CLOSE, "")

        onClosed()
    }

    function handleSetStopLoss(val: any) {

        const parsedAsk = parseFloat(symbols[index].Ask)
        const parsedBid = parseFloat(symbols[index].Bid)
        const parsedVal = parseFloat(val)

        if (mode === "BUY" && parsedVal > parsedBid) {
            setStopLoss(parsedBid)
            return
        }
        if (mode === "SELL" && parsedVal < parsedAsk) {
            setStopLoss(parsedAsk)
            return
        }
        setStopLoss(parsedVal)
    }

    function handleTakeProfit(val: any) {
        const parsedAsk = parseFloat(symbols[index].Ask)
        const parsedBid = parseFloat(symbols[index].Bid)
        const parsedVal = parseFloat(val)

        if (mode === "BUY" && parsedVal < parsedBid) {
            setTakeProfit(parsedBid)
            return
        }
        if (mode === "SELL" && parsedVal > parsedAsk) {
            setTakeProfit(parsedAsk)
            return
        }
        setTakeProfit(parsedVal)
    }


    return <>
        <div className="absolute top-0 left-0 h-screen w-screen flex justify-center items-center z-[5000]">
            <div onClick={onClosed} className="absolute top-0 left-0 bg-base-200 opacity-40 w-full h-full">Close</div>

            <div className="modal-box relative z-[5001]">
                <h3 className="text-lg font-bold">{position.Symbol} ({position.Position})</h3>
                <div className="my-2"></div>
                <div className="flex items-center justify-between font-bold">
                    <p>Alış</p>
                    <PriceText value={symbols[index].Bid} />
                </div>
                <div className="flex items-center justify-between font-bold">
                    <p>Satış</p>
                    <PriceText value={symbols[index].Ask} />
                </div>

                <div className="my-2 mt-4"></div>
                <div className="flex flex-col gap-2">
                    <Collapse title="Zarar Durdur" onChecked={onChecked("stopLoss")} onUnchecked={() => setStopLoss(0.0)} defaultValue={parseFloat(position.PriceSL) === 0 ? false : true}>
                        <NumberInput defaultValue={parseFloat(stopLoss)} digits={parseInt(position.Digits)} min={0} onChange={(val) => {
                            if (val) {
                                handleSetStopLoss(val)
                            }
                        }} />
                    </Collapse>
                    <Collapse title="Kar Al" onChecked={onChecked("takeProfit")} onUnchecked={() => setTakeProfit(0.0)} defaultValue={parseFloat(position.PriceTP) === 0 ? false : true}>
                        <NumberInput defaultValue={parseFloat(takeProfit)} digits={parseInt(position.Digits)} min={0} onChange={(val) => {
                            if (val) {
                                handleTakeProfit(val)
                            }
                        }} />
                    </Collapse>
                </div>
                <div className="my-2 mt-4"></div>
                <button className="btn-primary btn w-full" onClick={handleEditPosition}>Düzenle</button>
                <div className="my-2 mt-4"></div>
                <button
                    className={`btn ${parseFloat(position.Profit) < 0 ? "btn-error" : "btn-success"} text-primary-content w-full`}
                    onClick={handleClosePosition}>
                    #{position.Position} numaralı {mode === "BUY" ? "AL" : "SAT"} {(parseInt(position.Volume) / 10000).toFixed(2)} pozisyonunu {position.Profit}$ {`(Profit: ${position.oldProfit}$ Swap: ${position.Swap}$)`} {parseFloat(position.Profit) > 0 ? "Kar" : "Zarar"} ile kapat
                </button>

            </div>
        </div>
    </>
}