import { CHART_TIMES, Symbol } from '@/types'
import { debounce, downloadImageFromDataUri, symbolGraphData } from '@/utils'
import { CandleTooltipCustomCallbackData, CandleType, KLineData } from 'klinecharts'

import { Chart, init, dispose, LineType } from 'klinecharts'
import { useEffect, useRef, useState } from 'react'
import ChartSymbolStats from './ui/ChartSymbolStats'

let chart: Chart;
let selectedOverlayOutside: number | string;

export default function ({ selectedSymbol }: { selectedSymbol: Symbol }) {

    const chartContainer = useRef<null | HTMLDivElement>(null)
    const [selectedOverlay, setSelectedOverlay] = useState<string>("")
    const [loading, setLoading] = useState(false)
    const [chartSymbolData, setChartSymbolData] = useState<undefined | CandleTooltipCustomCallbackData>(undefined)

    useEffect(() => {


        function resizeFunction() {
            if (!chart) return


            chart.resize()
        }

        function listenKeydown(func: any, func2: any, func3: any) {

            return (event: any) => {
                // Check if the pressed key is the Delete key
                if (event.key === 'Delete' || event.keyCode === 46) {
                    // Your code to handle the Delete key press goes here
                    func()
                }

                if (event.ctrlKey && event.altKey && (event.key === 'D' || event.keyCode === 68)) {
                    // Your code to handle the Alt + L key combination goes here
                    func2()
                }

                if (event.ctrlKey && event.altKey && (event.key === 'S' || event.keyCode === 83)) {
                    func3()
                }
            }

        }

        const debouncedFunction = debounce(resizeFunction, 200)

        window.addEventListener("resize", debouncedFunction)

        document.addEventListener('keydown', listenKeydown(removeOverlay, createLineOverLay, takeScreenShot));


        if (chartContainer.current) {
            chart = init(chartContainer.current, {
                styles: {
                    crosshair: {
                        vertical: {
                            text: {
                                family: "Poppins"
                            }
                        },
                        horizontal: {
                            text: {
                                family: "Poppins"
                            }
                        }
                    },
                    candle: {
                        priceMark: {
                            high: {
                                textFamily: "Poppins"
                            },
                            low: {
                                textFamily: "Poppins"
                            },
                            last: {
                                text: {
                                    family: "Poppins"
                                }
                            }
                        },
                        type: CandleType.Area,
                        tooltip: {
                            custom: (data) => {
                                setChartSymbolData(data)
                                return []
                            }

                        },
                    },
                    yAxis: {
                        axisLine: {
                            show: false
                        },
                        tickText: {
                            family: "Poppins"
                        }
                    },
                    xAxis: {
                        axisLine: {
                            show: false
                        },
                        tickText: {
                            family: "Poppins"
                        }
                    },
                    grid: {
                        horizontal: {
                            show: false,
                            color: "var(--fallback-b1,oklch(var(--b1)/var(--tw-border-opacity)))",
                            style: LineType.Solid
                        },
                        vertical: {
                            show: false,
                            color: "var(--fallback-b1,oklch(var(--b1)/var(--tw-border-opacity)))",
                            style: LineType.Solid

                        }
                    },


                }
            }
            ) as Chart

            setLoading(true)
            symbolGraphData({ symbolName: selectedSymbol.Symbol, time: "MINUTE1" }).then(([data, err]) => {
                setLoading(false)

                if (err) {
                    return
                }
                chart.applyNewData(data as KLineData[])
            })

        }

        return () => {

            dispose(chart)
            window.removeEventListener("resize", debouncedFunction)
            document.removeEventListener('keydown', listenKeydown(() => { }, () => { }, () => { }));

        }

    }, [selectedSymbol])


    function createLineOverLay() {
        if (chart) {
            const id = chart.createOverlay({
                name: "straightLine",
                onClick: (e) => {
                    setSelectedOverlay(e.overlay.id)
                    selectedOverlayOutside = e.overlay.id as string

                    return true
                }
            })

            setSelectedOverlay(id as string)
            selectedOverlayOutside = id as string
        }
    }

    function removeOverlay() {
        if (chart) {
            chart.removeOverlay(selectedOverlay as string)
            chart.removeOverlay(selectedOverlayOutside as string)
        }
    }

    function takeScreenShot() {
        if (chart) {
            const url = chart.getConvertPictureUrl(true)
            downloadImageFromDataUri(url, "chart_" + Date.now() + ".png")
        }
    }

    function onTimeChange(val: CHART_TIMES) {
        setLoading(true)

        symbolGraphData({ symbolName: selectedSymbol.Symbol, time: val }).then(([data, err]) => {
            setLoading(false)

            if (err) {
                return
            }
            chart.clearData()
            chart.applyNewData(data as KLineData[])
        })
    }


    return <div className=' md:h-[80vh] h-[600px] lg:h-full bg-base-100 overflow-hidden text-white border-base relative'>
        {loading && <div className='z-50 w-full h-full flex items-center justify-center absolute top-0 left-0 bg-base-100'><span className="loading loading-dots loading-md"></span></div>}
        <div className='flex gap-3 items-center absolute top-[5%] left-[5%]'>
            <div className='flex items-center gap-2 text-base-content'>
                <img className="h-6" src={`https://cdn.fxcrm.me/parite/${selectedSymbol.Symbol.toLowerCase()}.png`} />
                <span className='lg:text-lg font-bold text-3xl'>{selectedSymbol.Symbol}</span>
            </div>
            {chartSymbolData && <ChartSymbolStats chartSymbolData={chartSymbolData} />}
        </div>
        <div className='flex items-center w-full bg-base-100 gap-2 p-2 '>
            <div className='flex-1'></div>
            <button className='btn btn-sm h-full bg-base-100 hidden lg:flex items-center' onClick={createLineOverLay}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                </svg>
                <kbd className="kbd kbd-xs hidden lg:block">CTRL + ALT + D</kbd>
            </button>
            <button className='btn btn-sm h-full bg-base-100 hidden lg:flex items-center' onClick={removeOverlay}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>
                <kbd className="kbd kbd-xs hidden lg:block">DELETE</kbd>

            </button>
            <button className='btn lg:btn-sm h-full bg-base-100 btn-xs' onClick={takeScreenShot}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                </svg>

                <kbd className="kbd kbd-xs hidden lg:block">CTRL + ALT + S</kbd>

            </button>
            <select className="select select-bordered lg:select-sm w-full lg:max-w-[200px] max-w-[100px] select-xs text-base-content" defaultValue={"MINUTE1"} onChange={e => onTimeChange(e.target.value as CHART_TIMES)}>
                <option disabled selected>Zaman Dilimi</option>
                <option value={"MINUTE1"}>1 Dakika</option>
                <option value={"MINUTE5"}>5 Dakika</option>
                <option value={"MINUTE30"}>30 Dakika</option>
                <option value={"HOUR1"}>1 Saat</option>
                <option value={"HOUR6"}>6 Saat</option>
                <option value={"HOUR12"}>12 Saat</option>
                <option value={"DAY1"}>1 Gün</option>
            </select>
        </div>
        <div className='lg:p-10 p-0 lg:h-full h-[90%] relative'>

            <div ref={chartContainer} className='w-full h-full '></div>
        </div>
    </div>
}