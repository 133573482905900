import ReactDOM from 'react-dom/client'
import './index.css'


import { RouterProvider, createBrowserRouter, } from 'react-router-dom';
import Login from './pages/Login.tsx';
import authLoader from './lib/loaders/authLoader.tsx';
import Register from './pages/Register.tsx';
import AuthLayout from './layouts/authLayout.tsx';
import PanelLayout from './layouts/panelLayout.tsx';
import panelLoader from './lib/loaders/panelLoader.tsx';
import Panel from './pages/Panel.tsx';
import Profile from './components/Profile.tsx';
import ProfileLayout from './components/ProfileLayout.tsx';
import Kimlik from './components/Kimlik.tsx';
import PasswordChange from './components/PasswordChange.tsx';
import Wallet from './components/Wallet.tsx';
import Withdraw from './components/Withdraw.tsx';
import Traders from './components/Traders.tsx';
import Robots from './components/Robots.tsx';
import Blocked from './components/Blocked.tsx';
import Bulletins from './components/Bulletins.tsx';

import EconomicCalendar from './components/EconomicCalendar.tsx';
import calendarLoader from '@/loaders/calendarLoader.tsx';
import homeLoader from '@/loaders/homeLoader.tsx';
import Home from './components/Home.tsx';
import TraderPage from './components/TraderPage.tsx';
import traderPageLoader from '@/loaders/traderPageLoader.tsx';
import DepositHistory from './components/DepositHistory.tsx';
import depositHistoryLoader from '@/loaders/depositHistoryLoader.tsx';
import WithdrawHistory from './components/WithdrawHistory.tsx';
import withdrawHistoryLoader from '@/loaders/withdrawHistoryLoader.tsx';
import Suggestions from './components/Suggestions.tsx';
import suggestionsLoader from '@/loaders/suggestionsLoader.tsx';
import Nprogress from "nprogress";
import RootLayout from './layouts/rootLayout.tsx';
import "nprogress/nprogress.css";
import { getAllQueryParams } from '@/utils/functions.ts';

import ErrorBoundaryContainer from './components/errors/ErrorBoundaryContainer.tsx';
import Error404 from './components/errors/Error404.tsx';
import App from './components/App.tsx';
export * from "@/utils/events.ts";
import * as Sentry from "@sentry/react";
import settings from '@/utils/settings.ts';
import constants from '@/constants.ts';


if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: "https://032f14784b979a6e26b5a5dbf619e460@o4506954766090240.ingest.us.sentry.io/4506954772381696",
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

}


export const progress = Nprogress.configure({
  parent: "#root",
  showSpinner: false,
});



const router = createBrowserRouter(
  [{
    element: <RootLayout />,
    children: [
      {
        element: <AuthLayout />,
        loader: authLoader,
        children: [
          {
            path: "login",
            element: <Login />,
            loader: (fn) => {
              progress.done()
              return fn
            }
          },
          {
            path: "register",
            element: <Register />,
            loader: (fn) => {
              progress.done()
              return fn
            }
          }
        ]
      },
      {
        element: <PanelLayout />,
        loader: panelLoader,
        children: [
          {
            path: "/",
            loader: homeLoader,
            element: <Home />,

          },
          {
            path: "panel",
            element: <Panel />,
            loader: (fn) => {
              const params = getAllQueryParams(fn.request.url)
              progress.start()
              progress.done()
              return params
            }
          },
          {
            path: "traders",
            element: <Traders />,
            loader: (fn) => {
              progress.start()
              progress.done()
              return fn
            }
          },
          {
            path: "traders/:traderId",
            loader: traderPageLoader,
            element: <TraderPage />
          },

          {
            path: "robots",
            element: <Robots />,
            loader: (fn) => {
              progress.done()
              return fn
            }
          },
          {
            "path": "bulletins",
            element: <Bulletins />,
            loader: (fn) => {
              progress.done()
              return fn
            }
          },
          {
            path: "calendar",
            element: <EconomicCalendar />,
            loader: calendarLoader
          },
          {
            path: "suggestions",
            element: <Suggestions />,
            loader: suggestionsLoader,
          },
          {
            element: <ProfileLayout />,
            errorElement: <ErrorBoundaryContainer />,
            children: [
              {
                "path": "profile",
                element: <Profile />,
                loader: (fn) => {
                  progress.done()
                  return fn
                }
              },
              {
                "path": "kimlik",
                element: <Kimlik />,
                loader: (fn) => {
                  progress.done()
                  return fn
                }
              },
              {
                "path": "password",
                element: <PasswordChange />,
                loader: (fn) => {
                  progress.done()
                  return fn
                }
              },
              {
                "path": "wallet",
                element: <Wallet />,
                loader: (fn) => {
                  progress.done()


                  return fn
                }
              },
              // {
              //   "path": "deposit",
              //   element: <Deposit />,
              //   loader: (fn) => {
              //     progress.done()
              //     if (settings && !settings.data?.isDepositEnabled) {
              //       throw { status: 403 };
              //     }

              //     return fn
              //   }
              // },
              {
                "path": "withdraw",
                element: <Withdraw />,
                loader: (fn) => {
                  progress.done()
                  if (settings && !settings.data?.isWithdrawEnabled) {
                    throw { status: 403 };
                  }
                  return fn
                }
              },
              {
                "path": "blocked",
                element: <Blocked />,
                loader: (fn) => {
                  progress.done()
                  return fn
                }
              },
              {
                "path": "deposit-history",
                element: <DepositHistory />,
                loader: depositHistoryLoader
              },
              {
                path: "withdraw-history",
                element: <WithdrawHistory />,
                loader: withdrawHistoryLoader,
              }

            ],
          }
        ]
      },
      {
        path: "*",
        element: <Error404 />
      }
    ]
  },

  ]
);


(function () {
  const themeValue = localStorage.getItem("theme")
  if (themeValue) {
    document.documentElement.setAttribute("data-theme", themeValue)
  } else {
    localStorage.setItem("theme", constants.DEFAULT_THEME)
    document.documentElement.setAttribute("data-theme", constants.DEFAULT_THEME)
  }
})()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <App>
    <RouterProvider router={router} />
  </App>
)
