
class UserFinancesManager {
    constructor(public profit: number) { }


    setUserProfit(newProfit: number) {
        this.profit = newProfit
    }


}


export const userFinancesManager = new UserFinancesManager(0)
