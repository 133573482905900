import { UserAtom } from "@/atoms"
import { useAtom } from "jotai"
import PNLText from "../../components/PNLText"

export default function ({ stats, orders, history }: { stats: number, orders: number, history: number }) {

    const [user] = useAtom(UserAtom)

    return <div className="stats shadow">

        <div className="stat place-items-start">
            <div className="stat-title text-primary">Kar</div>
            <div className="stat-value lg:text-4xl text-2xl"><PNLText value={user?.Profit as string + "$"} /> </div>
        </div>

        <div className="stat place-items-start">
            <div className="stat-title text-primary">Açık Pozisyonlar</div>
            <div className="stat-value lg:text-4xl text-2xl">{stats}</div>
        </div>

        <div className="stat place-items-start">
            <div className="stat-title text-primary">Emirler</div>
            <div className="stat-value lg:text-4xl text-2xl">{orders}</div>
        </div>

        <div className="stat place-items-start">
            <div className="stat-title text-primary">Kapatılan İşlem</div>
            <div className="stat-value lg:text-4xl text-2xl">{history}</div>
        </div>
    </div>
}