import { SymbolData } from "@/types"
import { useRef } from "react"
import { emitEvent, eventConstants } from "../../main"
import KeyValuePair from "./KeyValuePair"

export default function ({ symbolInformation }: { symbolInformation: SymbolData }) {

    const drawerRef = useRef<HTMLInputElement | null>(null)


    return <div className="drawer drawer-end fixed z-[9999]">
        <input ref={drawerRef} id="symbolInfoDrawer" type="checkbox" className="drawer-toggle" defaultChecked onChange={e => {
            if (!e.target.checked) {
                emitEvent(eventConstants.CLOSE_SYMBOL_INFORMATION, undefined)
            }

        }} />
        <div className="drawer-content">
        </div>
        <div className="drawer-side" >

            <label htmlFor="symbolInfoDrawer" aria-label="close sidebar" className="drawer-overlay"></label>
            <ul className="menu p-4 min-h-full bg-base-100 text-base-content max-w-[500px] w-full">
                {/* Sidebar content here */}
                <div className="flex justify-start item-center">
                    <button className="btn btn-sm bg-base-100 btn-circle" onClick={() => {
                        emitEvent(eventConstants.CLOSE_SYMBOL_INFORMATION, undefined)
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </button>
                </div>


                <div className="bg-base-100 flex flex-col gap-2 rounded-lg p-4">
                    <span className="font-semibold text-2xl mb-2">Özellikler</span>
                    <KeyValuePair title={"Açıklama"} value={symbolInformation.Description} />
                    <KeyValuePair title={"Baz"} value={symbolInformation.CurrencyBase} />
                    <KeyValuePair title={"Karşıt"} value={symbolInformation.CurrencyProfit} />
                    <KeyValuePair title={"Kontrat Büyüklüğü"} value={parseFloat(symbolInformation.ContractSize).toFixed(2)} />
                    <KeyValuePair title={"Digit"} value={symbolInformation.Digits} />

                    <span className="font-semibold text-2xl my-2">Swap</span>
                    <KeyValuePair title={"Pazartesi"} value={parseFloat(symbolInformation.SwapRateMonday).toFixed(2)} />
                    <KeyValuePair title={"Salı"} value={parseFloat(symbolInformation.SwapRateTuesday).toFixed(2)} />
                    <KeyValuePair title={"Çarşamba"} value={parseFloat(symbolInformation.SwapRateWednesday).toFixed(2)} />
                    <KeyValuePair title={"Perşembe"} value={parseFloat(symbolInformation.SwapRateThursday).toFixed(2)} />
                    <KeyValuePair title={"Cuma"} value={parseFloat(symbolInformation.SwapRateFriday).toFixed(2)} />


                    <span className="font-semibold text-2xl my-2">Oturumlar</span>
                    <KeyValuePair title={"Pazar"} value={symbolInformation.SessionsQuotesExplained["Sunday"]} />
                    <KeyValuePair title={"Pazartesi"} value={symbolInformation.SessionsQuotesExplained["Monday"]} />
                    <KeyValuePair title={"Salı"} value={symbolInformation.SessionsQuotesExplained["Tuesday"]} />
                    <KeyValuePair title={"Çarşamba"} value={symbolInformation.SessionsQuotesExplained["Wednesday"]} />
                    <KeyValuePair title={"Perşembe"} value={symbolInformation.SessionsQuotesExplained["Thursday"]} />
                    <KeyValuePair title={"Cuma"} value={symbolInformation.SessionsQuotesExplained["Friday"]} />
                    <KeyValuePair title={"Cumartesi"} value={symbolInformation.SessionsQuotesExplained["Saturday"]} />

                </div>

            </ul>
        </div>
    </div >

}