import { PositionHistoryItem } from "@/types"
import { useRef } from "react"
import { emitEvent, eventConstants } from "../../main"
import KeyValuePair from "./KeyValuePair"
import { formatDateTime } from "@/utils"

export default function ({ positionHistoryDetail }: { positionHistoryDetail: PositionHistoryItem }) {

    const drawerRef = useRef<HTMLInputElement | null>(null)

    const profit = (parseFloat(positionHistoryDetail.Profit) + parseFloat(positionHistoryDetail.Storage))

    return <div className="drawer drawer-end fixed z-[9999]">
        <input ref={drawerRef} id="symbolInfoDrawer" type="checkbox" className="drawer-toggle" defaultChecked onChange={e => {
            if (!e.target.checked) {
                emitEvent(eventConstants.CLOSE_POSITION_HISTORY_DETAIL, undefined)
            }

        }} />
        <div className="drawer-content">
        </div>
        <div className="drawer-side" >

            <label htmlFor="symbolInfoDrawer" aria-label="close sidebar" className="drawer-overlay"></label>
            <ul className="menu p-4 min-h-full bg-base-100 text-base-content max-w-[500px] w-full">
                {/* Sidebar content here */}
                <div className="flex justify-start item-center">
                    <button className="btn btn-sm bg-base-100 btn-circle" onClick={() => {
                        emitEvent(eventConstants.CLOSE_POSITION_HISTORY_DETAIL, undefined)
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </button>
                </div>


                <div className="bg-base-100 flex flex-col gap-2 rounded-lg p-4">
                    <span className="font-semibold text-2xl mb-2">İşlem Detayı</span>

                    <KeyValuePair title={"ID"} value={positionHistoryDetail.PositionID} />
                    <KeyValuePair title={"Sembol"} value={positionHistoryDetail.Symbol} />
                    <KeyValuePair title={"İşlem Tipi"} value={(positionHistoryDetail.Action === "1" ? "AL " : "SAT ") + (parseInt(positionHistoryDetail.Volume) / 10000).toFixed(2)} />
                    <KeyValuePair title={"Profit"} valueClass={profit > 0 ? "text-success" : "text-error"} value={profit.toFixed(2)} />
                    <KeyValuePair title={"Giriş Fiyatı"} value={parseFloat(positionHistoryDetail.PricePosition).toFixed(2)} />
                    <KeyValuePair title={"Son Fiyatı"} value={parseFloat(positionHistoryDetail.Price).toFixed(2)} />
                    <KeyValuePair title={"Swap"} value={parseFloat(positionHistoryDetail.Storage).toFixed(2)} />
                    <KeyValuePair title={"Kapanış Zamanı"} value={formatDateTime(new Date(parseInt(positionHistoryDetail.Time) * 1000))} />

                </div>

            </ul>
        </div>
    </div >

}