import { Pagination, Trader, TraderList } from "@/types"
import { blockTrader, createUserTraderProfile, followTrader, getStarRating, getTopTrapders, getTraderList, reportTraderRequest, unfollowTraderRequest } from "@/utils"
import { FormEvent, useEffect, useState } from "react"
import FollowModal from "./FollowModal"
import { emitEvent, eventConstants } from "../main"
import EditFollowModal from "./EditFollowModal"
import usePrompt from "@/hooks/usePrompt"
import ReportModal from "./ReportModal"

import TraderFilters from "./TraderFilters"
import { useAtomValue } from "jotai"
import { UserAtom } from "@/atoms"
import CreateTraderProfileModal from "./CreateTraderProfileModal"
import CustomLink from "./CustomLink"
import useWindowSize from "@/hooks/useWindowSize"
// import MobileBottomDrawer from "./mobile/MobileBottomDrawer"
import MobileTradeFilters from "./mobile/MobileTradeFilters"
import TraderListItem from "./ui/TraderListItem"

let idToBlock: null | string = null;
export default function () {

    const user = useAtomValue(UserAtom)
    const [topTraders, setTopTraders] = useState<TraderList>([])
    const [traders, setTraders] = useState<TraderList>([])
    const [tradersPagination, setTradersPagination] = useState<Pagination | null>(null)
    const [loadMore, setLoadMore] = useState(false)
    const [selectedTrader, setSelectedTrader] = useState<Trader | null>(null)
    const [editTrader, setEditTrader] = useState<Trader | null>(null)
    const [reportTrader, setReportTrader] = useState<Trader | null>(null)
    const [filters, setFilters] = useState<null | { search: string, sort: string, filter: string }>(null)
    const [createTraderProfile, setCreateTraderProfile] = useState(false)
    const isMobile = useWindowSize(700)



    const { prompt: BlockTraderPrompt, openPrompt: openBlockTraderPrompt } = usePrompt({
        description: "Bu Traderi engellemek istediğinize emin misiniz?", onAccept: async () => {

            if (!idToBlock) return

            const [_, err] = await blockTrader(idToBlock)

            if (err) {
                emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Kullanıcı engellenirken bir hata oluştu!" })

                return
            }

            emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Kullanıcı Başarıyla Engellendi!" })
            setTraders(prev => {

                return [...prev.filter(item => item._id !== idToBlock)]
            })
        }
    })

    useEffect(() => {
        getTopTrapders().then(([data, err]) => {
            if (err) {
                return
            }

            setTopTraders(data)
        })

        getTraderList().then(([data, err]) => {
            if (err) {
                return
            }

            setTradersPagination({ ...data.pagination })
            setTraders(data.traders)
        })
    }, [])



    function handleLoadMore() {
        setLoadMore(true)
        if (!tradersPagination || tradersPagination.currentPage + 1 > Math.ceil(tradersPagination.totalCount / tradersPagination.perPage)) {
            setLoadMore(false)

            return
        }

        getTraderList({ page: tradersPagination.currentPage + 1, ...filters }).then(([data, err]) => {
            setLoadMore(false)
            if (err) {
                return
            }

            setTradersPagination({ ...data.pagination })
            setTraders(prev => [...prev, ...data.traders])
        })
    }


    function isMaxPage(pag: Pagination | null) {
        if (!pag) return
        return pag.currentPage + 1 > Math.ceil(pag.totalCount / pag.perPage)
    }

    function handleOpenFollowModal(item: Trader) {
        if (item.isFollowing) {
            setEditTrader(item)
        } else {
            setSelectedTrader(item)
        }
    }

    function updateUserFollow(traderId: string, status: boolean) {

        setTraders(prev => {
            const index = prev.findIndex(item => item._id === traderId)

            prev[index].isFollowing = status

            return [...prev]
        })

        setTopTraders(prev => {
            const index = prev.findIndex(item => item._id === traderId)

            prev[index].isFollowing = status

            return [...prev]
        })
    }

    async function handleFollowRequest(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const followData = new FormData(e.target as HTMLFormElement)

        const options = followData.getAll("option") as string[]
        const lotPercentage = followData.get("lotPercentage") as string
        const traderId = followData.get("traderId") as string

        const [_, err] = await followTrader({ options: options.join(","), lotPercentage: parseFloat(lotPercentage), traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Trader başarıyla takip edildi!" })
        setSelectedTrader(null)
        setEditTrader(null)
        updateUserFollow(traderId, true)

    }

    async function handleUnfollow(traderId: string) {
        const [_, err] = await unfollowTraderRequest({ traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Trader başarıyla takipten çıkıldı!" })
        setEditTrader(null)
        updateUserFollow(traderId, false)

    }

    function handleOpenBlockTraderPrompt(traderId: string) {
        openBlockTraderPrompt()
        idToBlock = traderId
    }

    function handleOpenReportModal(item: Trader) {
        setReportTrader(item)
    }

    async function handleReportRequest(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const followData = new FormData(e.target as HTMLFormElement)

        const reason = followData.get("reason") as string
        const message = followData.get("message") as string
        const traderId = followData.get("traderId") as string

        const [_, err] = await reportTraderRequest({ reason: reason, message: message, traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Bu kullanıcı daha önce şikayet edildi!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Trader başarıyla şikayet edildi!" })
        setReportTrader(null)
    }

    async function goToTrader() {
        emitEvent(eventConstants.NAVIGATE_TRADER_PROFILE, "")
    }


    async function handleCreateTraderProfile(e: FormEvent<HTMLFormElement>) {

        e.preventDefault()
        const traderData = new FormData(e.target as HTMLFormElement)

        const [_, err] = await createUserTraderProfile(traderData)

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Trader profiliniz yaratılırken hata oluştu!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Trader profiliniz başarıyla yaratıldı!" })
        emitEvent(eventConstants.RE_FETCH_USER_INFO, {})
        setCreateTraderProfile(false)
    }

    return <>

        {BlockTraderPrompt()}
        {reportTrader && <ReportModal trader={reportTrader} onSubmit={handleReportRequest} onClose={() => setReportTrader(null)} />}
        {editTrader && <EditFollowModal onUnfollow={handleUnfollow} trader={editTrader} onClose={() => setEditTrader(null)} onSubmit={handleFollowRequest} />}
        {selectedTrader && <FollowModal trader={selectedTrader} onClose={() => setSelectedTrader(null)} onSubmit={handleFollowRequest} />}
        {createTraderProfile && <CreateTraderProfileModal onClose={() => setCreateTraderProfile(false)} onSubmit={handleCreateTraderProfile} />}
        <div className="flex flex-col p-3 lg:p-6">
            <div className="flex justify-between items-center">
                <h1 className="font-bold lg:text-4xl md:text-2xl text-xl">Traderlar</h1>
                {user?.isTrader ? <button className="btn btn-primary btn-sm lg:btn-md  max-lg:btn-circle" onClick={goToTrader}>

                    <span className="hidden lg:inline-block">Trader Profilim</span>

                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 lg:hidden">
                        <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                    </svg>

                </button> : <button className="btn btn-primary" onClick={() => setCreateTraderProfile(true)}>Trader Ol</button>}

            </div>

            <div className="flex gap-4">
                <div className="flex-1 lg:p-4 rounded-lg gap-4">

                    {isMobile ? <MobileTradeFilters onFilter={((list, filters) => {
                        setTraders([...list.traders])
                        setTradersPagination({ ...list.pagination })
                        setFilters({ ...filters })
                    })} /> : <TraderFilters onFilter={((list, filters) => {
                        setTraders([...list.traders])
                        setTradersPagination({ ...list.pagination })
                        setFilters({ ...filters })
                    })} />}

                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full">

                        {traders.map(item => {

                            return <TraderListItem key={item._id} item={item} onBlock={handleOpenBlockTraderPrompt} onFollow={handleOpenFollowModal} onReport={handleOpenReportModal} />
                        })}
                    </div>

                    {isMaxPage(tradersPagination) ? null : <div className="flex justify-center mt-16">
                        <button onClick={handleLoadMore} className="btn">
                            {loadMore ? <span className="loading loading-spinner loading-md"></span> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
                            </svg>}
                            DAHA FAZLA YÜKLE</button>
                    </div>}


                </div>


                {!isMobile && <div className="flex flex-col w-[400px] p-4 rounded-lg gap-4">
                    <h1 className="font-bold text-xl">Top Traderlar</h1>

                    <div className="flex flex-col gap-4">

                        {topTraders.map(item => {

                            return <div className="content-container p-4 rounded-lg flex items-center gap-4" key={item._id}>
                                <div className="avatar">
                                    <div className="w-12 rounded-full">
                                        <img src={item.photo} />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 pr-4">
                                    <div className="flex items-center gap-2">
                                        <CustomLink to={"/traders/" + item._id} className="font-semibold hover:text-primary hover:underline text-sm max-w-[130px]">{item.name}</CustomLink>
                                        {item.isVerified && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-info">
                                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                        </svg>}


                                    </div>

                                    <div className="flex gap-2">
                                        <div className="flex text-primary items-center gap-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 fill-primary">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                            </svg>
                                            <p className="text-sm text-base-content">{getStarRating(item)}</p>
                                        </div>
                                        <div className="flex items-center gap-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 fill-base-content">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                            </svg>

                                            <p className="text-sm text-base-content">{item.followers}</p>
                                        </div>
                                    </div>


                                </div>
                                { }
                                <div className="flex-1 flex items-center justify-end">
                                    {item.isFollowing ? <button className="btn btn-sm btn-secondary" onClick={() => handleOpenFollowModal(item)}>Takipten Çık</button> : <button onClick={() => handleOpenFollowModal(item)} className="btn btn-sm btn-primary">Takip Et</button>}
                                </div>
                            </div>
                        })}


                    </div>
                </div>}

            </div>
        </div>
    </>
}