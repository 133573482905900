import { FormEvent, useRef, useState } from "react"
import constants from "@/constants"
import { loginEmail } from "@/utils"
import CustomLink from "./CustomLink"


export default function () {

    const [error, setError] = useState<String | null>(null)
    const formRef = useRef<HTMLFormElement | null>(null)
    async function handleLogin(e: FormEvent<HTMLFormElement>) {
        setError(null)
        e.preventDefault()
        const data = new FormData(e.target as HTMLFormElement)
        const username = data.get("username") as string
        const password = data.get("password") as string

        const [res, err] = await loginEmail(username, password);

        if (err) {
            setError("Giriş bilgilerinizi kontrol edip tekrar deneyiniz!")

        }

        if (formRef.current) {
            formRef.current.reset()
        }

        if (res) {
            localStorage.setItem(constants.AUTH_STORAGE_KEY, res.token)
            window.location.replace("/")
        }
    }

    return <form ref={formRef} onSubmit={handleLogin} className="card-body md:p-10 p-6 gap-3">
        <input name="username" className="input input-bordered input-sm md:input-md" placeholder="E-posta" required />
        <input name="password" type="password" className="input input-bordered input-sm md:input-md" placeholder="Şifre" required />
        {error && <div className="flex flex-col mt-3">

            <div className="flex items-center gap-4 text-error">
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span className="text-sm">{error}</span>
            </div>
        </div>}
        <div className="card-actions mt-4">
            <button className="btn btn-primary btn-xs md:btn-md w-full rounded-md">Giriş yap</button>
        </div>
        <div className="divider text-xs">Veya</div>
        <div className="flex justify-center">
            <CustomLink to={"/register"} className="btn md:btn-md btn-xs w-full btn-secondary rounded-md">Kayıt olun</CustomLink>

        </div>

    </form>
}