import { getCalendar } from "@/utils/calendar";
import { LoaderFunctionArgs } from "react-router-dom";
import { progress } from "../../main";


export default function ({ }: LoaderFunctionArgs) {


    return new Promise(async (res, _) => {
        progress.start()

        const [data] = await getCalendar()

        progress.done()
        return res(data ?? null)
    })
}