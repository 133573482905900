import { UserAtom } from "@/atoms";
import { useAtomValue } from "jotai";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import CustomLink from "./CustomLink";
import PNLText from "./PNLText";
import useBoundStore from "./stores/store";

export default function ProfileLayout() {

    const user = useAtomValue(UserAtom)
    const path = useLocation()
    const userSettings = useBoundStore(state => state.settings)


    return <div className="flex flex-col h-full flex-1 items-center lg:gap-4">
        <div className="flex flex-col lg:flex-row lg:gap-4 lg:py-4 w-full max-w-[1200px]">
            <div className="stats">
                <div className="stat">
                    <div className="stat-title lg:text-md text-base">İsim</div>
                    <div className="stat-value  lg:text-4xl text-2xl">{user?.name}</div>
                </div>
            </div>

            <div className="stats">
                <div className="stat">
                    <div className="stat-title  lg:text-md text-base">Kar</div>
                    <div className="stat-value lg:text-4xl text-2xl"><PNLText value={user?.Profit as string + "$"} /></div>
                </div>
            </div>
        </div>

        <div className="flex gap-4  max-w-[1200px] w-full  flex-1 py-4 lg:flex-row flex-col lg:px-0 px-4">
            <div className="flex lg:max-w-xs w-full flex-col p-2 bg-base-100 rounded-lg content-container">
                <p className="text-xs px-4 pt-4 opacity-50 mb-2 ">Account</p>

                <MenuItem link="/profile" active={path.pathname} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                    Profile
                </MenuItem>

                <MenuItem link="/kimlik" active={path.pathname} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                    </svg>
                    Kimlik
                </MenuItem>


                <MenuItem link="/password" active={path.pathname} >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                    Şifre Değiştir
                </MenuItem>


                {/* {userSettings?.isDepositEnabled && <>
                    <p className="text-xs px-4 pt-4 opacity-50 mb-2">Deposit</p>
                    <MenuItem link="/deposit" active={path.pathname}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                        </svg>
                        Deposit
                    </MenuItem>
                </>} */}


                {userSettings?.isWithdrawEnabled && <>
                    <p className="text-xs px-4 pt-4 opacity-50 mb-2">Withdraw</p>
                    <MenuItem link="/withdraw" active={path.pathname}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                        </svg>
                        Withdraw
                    </MenuItem>
                </>
                }
                <p className="text-xs px-4 pt-4 opacity-50 mb-2">Geçmiş</p>
                <MenuItem link="/deposit-history" active={path.pathname}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>

                    Para Yatırıma Geçmişi
                </MenuItem>
                <MenuItem link="/withdraw-history" active={path.pathname}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                    </svg>


                    Para Çekme Geçmişi
                </MenuItem>

                <p className="text-xs px-4 pt-4 opacity-50 mb-2">Actions</p>
                <MenuItem link="/wallet" active={path.pathname}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                    </svg>

                    Wallet
                </MenuItem>

                <MenuItem link="/blocked" active={path.pathname}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                    </svg>


                    Blocked
                </MenuItem>


                <MenuItem link="/logout" active={path.pathname}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg>
                    Logout
                </MenuItem>
            </div>
            <div className="flex flex-col flex-1 p-6 bg-base-100 rounded-lg">
                <Outlet />
            </div>
        </div>
    </div>
}


function MenuItem({ children, link, active }: { children: React.ReactNode, link: string, active: string }) {

    const classOf = link === active ? "p-4 text-sm py-2 hover:bg-base-100 text-primary rounded-lg flex items-center gap-2" : "p-4 text-sm py-2 hover:bg-base-100 rounded-lg flex items-center gap-2"

    return <CustomLink to={link} className={classOf}>
        {children}
    </CustomLink>

}