import React, { useEffect, useState } from "react";
import { eventConstants, onEvent } from "../main";
import CreateBasketDrawer from "./CreateBasketDrawer";



export const DRAWER_TYPES = {
    "CREATE_BASKET": "CREATE_BASKET"
}

export default function () {

    const [drawer, setDrawer] = useState<React.ReactNode | null>(null)


    const openDrawer = (drawerId: string) => {

        switch (drawerId) {
            case "CREATE_BASKET":
                setDrawer(<CreateBasketDrawer />)
                break;
            default:
                break;
        }
    }

    const closeDrawer = () => {
        setDrawer(null)
    }

    useEffect(() => {

        const openDrawerEvent = onEvent(eventConstants.OPEN_DRAWER, (drawerId: string) => {
            openDrawer(drawerId)
        })


        const closeDrawerEvent = onEvent(eventConstants.CLOSE_DRAWER, () => {
            closeDrawer()
        })

        return () => {
            openDrawerEvent.unsubscribe()
            closeDrawerEvent.unsubscribe()
        }

    }, [])


    return <>
        {drawer}
    </>
}