import { CalendarEvent, Result } from "@/types";
import maxApi from "./maxapi";


export async function getCalendar(): Promise<Result<any, Error>> {

    try {
        const res = await maxApi.get("calendar/list")

        const data: CalendarEvent[] = res.data.data

        return [data, null]

    } catch (error) {
        return [null, new Error()]
    }

}