import React, { useRef, useState } from "react";


export default function Collapse({ children, title, onChecked, onUnchecked, defaultValue }: { children: React.ReactNode, defaultValue?: boolean, title: string, onChecked?: () => void, onUnchecked?: () => void }) {

    const checkboxRef = useRef<null | HTMLInputElement>(null)
    const [isChecked, setIsChecked] = useState(defaultValue)

    function handleChecboxClick() {
        if (!checkboxRef.current) return

        checkboxRef.current.click()

        if (checkboxRef.current.checked) {

            if (onChecked) {
                setIsChecked(true)
                onChecked()
            }
        } else {
            if (onUnchecked) {
                setIsChecked(false)
                onUnchecked()
            }
        }

    }

    return <div className="flex flex-col">
        <div onClick={handleChecboxClick} className="flex items-center font-medium content-container p-2 rounded cursor-pointer">
            <p className="flex-1 select-none">{title}</p>
            <input onClick={handleChecboxClick} ref={checkboxRef} type="checkbox" defaultChecked={isChecked} className="checkbox checkbox-sm checkbox-primary" />
        </div>
        {(checkboxRef.current && checkboxRef.current.checked) || isChecked ? <div className="mt-2">
            {children}
        </div> : null}

    </div>
}