import { SymbolsAtom, SymbolCategoriesAtom, SymbolCategoryMapAtom, MarginsAtom, SymbolChartAtom, UserAtom, FavouritesAtom, AppSettingsAtom, UserPositionCountAtom, UserSpreadsAtom, symbolsManagerInstance } from "@/atoms"
import constants from "@/constants"
import { AppSettings, Position, PositionList, SymbolResult } from "@/types"
import { checkAuth, getUser, getSymbols, getMarginInitials, getSymbolChart, getFavouriteList, getAppSettings, getUserSettings, getUserPositionList, getUserSpreads, getUserToken } from "@/utils"
import settings from "@/utils/settings"
import { useSetAtom } from "jotai"
import { useEffect, useState } from "react"
import { Socket, io } from "socket.io-client"
import useBoundStore from "./stores/store"

export let socketInstance: null | Socket = null;

function LoadingScreen({ done }: { done: () => void }) {

    const zusSetPositions = useBoundStore((state) => state.setPositions)
    const zusSetUserSettings = useBoundStore((state) => state.setSettings)

    const [progressVal, setProgress] = useState(0)
    const [isFail, _] = useState(false)
    const [loadingText, setLoadingText] = useState("")

    const setSymbols = useSetAtom(SymbolsAtom)
    const setSymbolCategories = useSetAtom(SymbolCategoriesAtom)
    const setSymbolCategoryMap = useSetAtom(SymbolCategoryMapAtom)
    const setMargins = useSetAtom(MarginsAtom)
    const setSymbolCharts = useSetAtom(SymbolChartAtom)
    const setUser = useSetAtom(UserAtom)
    const setFavourites = useSetAtom(FavouritesAtom)
    const setAppSettings = useSetAtom(AppSettingsAtom)
    const setPositionCount = useSetAtom(UserPositionCountAtom)
    const setSpreads = useSetAtom(UserSpreadsAtom)

    const loadApp = async () => {
        setLoadingText("Checking Authentication...")
        const [_, authErr] = await checkAuth();

        if (authErr) {
            fail()
            return
        }

        increaseProgress(25)

        setLoadingText("Getting User...")
        const [userInfo, userInfoError] = await getUser()


        if (!userInfoError) {
            localStorage.setItem("user", JSON.stringify(userInfo))
            setUser(userInfo)
        } else {
            fail()
            return
        }

        increaseProgress(35)

        setLoadingText("Getting App Data...")
        const [symbolsPromise, marginsPromise, symbolsChartPromise, favouritesPromise, appSettingsPromise, userSettingsPromise, userPositionListPromise, userSpreadsPromise] = await Promise.allSettled([getSymbols(), getMarginInitials(), getSymbolChart(), getFavouriteList(), getAppSettings(), getUserSettings(), getUserPositionList(), getUserSpreads()])

        if (symbolsPromise.status === "fulfilled") {
            const data = symbolsPromise.value[0] as SymbolResult
            symbolsManagerInstance.setArray(data.symbols)
            setSymbols(data.symbols)
            setSymbolCategories(data.categories)
            setSymbolCategoryMap(data.symbolCategoryMap)

        } else {
            fail()
            return
        }


        increaseProgress(45)

        if (marginsPromise.status === "fulfilled") {
            setMargins(marginsPromise.value[0])
        } else {
            fail()
            return
        }

        increaseProgress(65)



        if (symbolsChartPromise.status === "fulfilled") {
            setSymbolCharts(symbolsChartPromise.value[0])

        } else {
            fail()
            return
        }

        increaseProgress(75)


        if (favouritesPromise.status === "fulfilled") {
            setFavourites(favouritesPromise.value[0])

        } else {

            fail()
            return
        }

        increaseProgress(85)


        if (appSettingsPromise.status === "fulfilled") {
            setAppSettings(appSettingsPromise.value[0])
        } else {

            fail()
            return
        }


        if (userPositionListPromise.status === "fulfilled") {
            setPositionCount((userPositionListPromise.value[0] as PositionList).length)
            zusSetPositions(userPositionListPromise.value[0] as Position[])
        } else {

            fail()
            return
        }


        if (userSpreadsPromise.status === "fulfilled") {
            const data = userSpreadsPromise.value[0]
            setSpreads(data)

        } else {
            fail()
            return
        }


        if (userSettingsPromise.status === "fulfilled") {
            const userSettings = userSettingsPromise.value[0]
            const appSettings = appSettingsPromise.value[0]
            if (appSettings && userSettings) {
                const userSettingsNew: Record<string, any> = appSettings
                const currentUserSettings: Record<string, any> = userSettings
                Object.keys(userSettings).forEach(key => {
                    userSettingsNew[key] = currentUserSettings[key]
                })

                settings.setData(userSettingsNew as AppSettings)
                zusSetUserSettings(userSettingsNew as AppSettings)
            } else {
                console.log("Error setting up User settings", userSettings, appSettings)
            }

        } else {
            fail()
            return
        }


        setLoadingText("Opening live data socket...")
        socketInstance = io(constants.SOCKET_URL, {
            transports: ['websocket'],
            upgrade: false,
            query: {
                Authorization: getUserToken(),
                env: constants.SOCKET_ENV
            },
        })

        socketInstance.open()

        socketInstance.on("connect", () => {
            console.log("SUCCESSFULLY CONNECTED")
        })

        socketInstance.on("disconnect", () => {
            console.log("Disconnected")
        })

        socketInstance.on("error", () => {
            fail()
        })

        increaseProgress(100)

        setTimeout(() => {
            done()
        }, 500)
    }

    useEffect(() => {
        loadApp()
    }, [])

    function fail() {
        done()
    }

    function increaseProgress(amount: number) {
        setProgress(amount)
    }


    return <>

        {isFail ? <div className="w-screen h-screen flex items-center justify-center">
            <div className="card max-w-[600px] w-full bg-neutral rounded shadow-lg">
                <div className="card-body">
                    <h2 className="card-title font-bold">Hata!</h2>
                    <p>Gerekli verileri yüklerken bir hata oluştu! Lütfen tekrar deneyiniz</p>
                </div>
            </div>
        </div> : <div className="w-screen h-screen flex items-center justify-center">
            <div className="text-center">
                <div className="flex justify-center">
                    <img src="/logo.png" className="w-40" />
                </div>
                <div className="mt-6"></div>
                <progress className="progress progress-primary w-56 transition-all" value={progressVal} max="100"></progress>
                <div className="mt-6"></div>
                <p className="text-gray-500 text-sm">{loadingText}</p>
            </div>
        </div>}
    </>
}


export default LoadingScreen