
import BasketDrawer from "./BasketDrawer"
import { useEffect, useState } from "react"
import { Basket, BasketWithComplete } from "@/types"
import { eventConstants, onEvent } from "../main"
import { getBasketList } from "@/utils"
import SelectedBasketDrawer from "./SelectedBasketDrawer"

export default function () {
    const [basket, setBasket] = useState<Basket[] | null>(null)
    const [selectedBasket, setSelectedBasket] = useState<BasketWithComplete | null>(null)
    const [selectedBasketTraderId, setSelectedBasketTraderId] = useState<string | null>(null)

    useEffect(() => {
        const onOpenBasketEvent = onEvent(eventConstants.OPEN_BASKET, (basketId: string) => {
            getBasketList(basketId).then(([data, err]) => {

                if (err) {
                    return
                }
                setBasket(data)
            })

        })

        const onOpenBasketWithSelectedEvent = onEvent(eventConstants.OPEN_BASKET_WITH_SELECTED, ({ traderId, selectedBasketId }: { traderId: string, selectedBasketId: string }) => {
            getBasketList(traderId).then(([data, err]) => {

                if (err) {
                    return
                }

                const basketToSelect = data.find(item => item._id === selectedBasketId);


                if (basketToSelect) {
                    setSelectedBasket({ ...basketToSelect } as BasketWithComplete)
                    setSelectedBasketTraderId(traderId)
                }

            })
        })


        const closeBasketDrawer = onEvent(eventConstants.CLOSE_BASKET_DRAWER, () => {
            setSelectedBasket(null)
            setBasket(null)
        })


        return () => {
            onOpenBasketEvent.unsubscribe()
            onOpenBasketWithSelectedEvent.unsubscribe()
            closeBasketDrawer.unsubscribe()
        }
    }, [basket, selectedBasket])

    return <>
        {basket && <BasketDrawer basketList={basket} defaultBasket={selectedBasket} />}
        {selectedBasket && selectedBasketTraderId ? <SelectedBasketDrawer selectedBasketDefault={selectedBasket} traderId={selectedBasketTraderId} /> : null}
    </>
}