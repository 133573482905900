import { ReactNode } from "react";

export default function ({ children, outsideClicked, visible = true }: { children: ReactNode, outsideClicked: () => void, visible?: true | false }) {


    return <div className={"fixed w-screen h-screen flex-col justify-end top-0 left-0 z-[999999] " + (visible ? "flex" : "hidden")}>
        <div onClick={outsideClicked} className="bg-base-300 opacity-70 h-full w-full absolute top-0 left-0 z-[999997]"></div>

        <div className="max-h-[90vh] overflow-y-auto bg-base-100 rounded-tl-2xl rounded-tr-2xl p-4 relative z-[999998]">
            {children}
        </div>
    </div>
}