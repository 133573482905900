import { SelectedSymbolAtom, SymbolsAtom, UserAtom } from "@/atoms"
import { useAtomValue, useSetAtom } from "jotai"
import { Link, useLoaderData, useNavigate } from "react-router-dom"
import { Banner, Story, Suggestion, SymbolList, TraderList } from "@/types"
import { limitCharacters } from "@/utils"
import useVerticalDrag from "@/hooks/useVerticalDrag"
import { useRef } from "react"
import HomeSuggestionList from "./HomeSuggestionList"
import Stories from "./Stories"
import CustomLink from "./CustomLink"
import PriceText from "./PriceText"
import HomeBanners from "./ui/HomeBanners"
import useBoundStore from "./stores/store"

export default function () {
    const { traders, positions, suggestions, stories, banners } = useLoaderData() as { traders: TraderList, positions: string[], suggestions: Suggestion[], stories: Story[], banners: Banner[] }
    const userSettings = useBoundStore((state) => state.settings)
    const user = useAtomValue(UserAtom)
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { handleMouseDown, handleMouseLeave, handleMouseMove, handleMouseUp } = useVerticalDrag({ element: containerRef.current })
    const symbolList = useAtomValue(SymbolsAtom) as SymbolList
    const navigate = useNavigate()
    const setSelectedSymbol = useSetAtom(SelectedSymbolAtom)

    function getSymbolIndex(position: string): number | null {
        const foundSymbol = symbolList?.findIndex(symbol => symbol.Symbol === position)

        return foundSymbol ? foundSymbol : null
    }

    return <div className="flex flex-col p-6 items-center">
        <div className="container">
            <h1 className="text-2xl">Hoşgeldin, <span className="text-primary">{user?.name}</span></h1>

            <div className="lg:mt-10 mt-0"></div>
            {userSettings?.isStoryEnabled && <Stories stories={stories} />}

            <div className="mb-4"></div>

            {banners !== null && banners.length > 0 ? <HomeBanners banners={banners} /> : null}
            <div className="mb-16"></div>



            <div className="flex flex-col gap-4 lg:mt-16 mb-6">
                <div className="flex justify-between items-center">
                    <h1 className="lg:text-xl text-md font-semibold">Öne Çıkan Traderlar</h1>
                    <CustomLink to={"/traders"} className="btn btn-sm rounded-sm  border btn-primary ">Tüm Traderlar</CustomLink>
                </div>

                <div ref={containerRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} className="mt-3 carousel carousel-center w-full p-4 space-x-4 rounded-box snap-none scroll-auto">

                    {traders?.map(trader => {

                        return <CustomLink key={trader._id} to={"traders/" + trader._id} className="flex-col flex carousel-item select-none">
                            <div className="flex flex-1 flex-col items-center gap-3 w-28 justify-between">
                                <div className="avatar">
                                    <div className="w-16 rounded-full pointer-events-none">
                                        <img src={trader.photo} />
                                    </div>
                                </div>
                                <span className="font-bold text-center text-sm"> {limitCharacters(trader.name, 20)}</span>
                                <span className="text-success">{trader.lastRoi}%</span>
                            </div>
                        </CustomLink>
                    })}

                </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-10">
                {/* <div className="flex flex-col flex-1">
                    {news ? <NewsCarouselHero news={news} /> : <div className="flex flex-1 items-center justify-center font-semibold ">
                        <ErrorMessage>  Haberler Yüklenemedi </ErrorMessage>
                    </div>}

                </div> */}

                <div className="flex flex-col gap-4 flex-1">
                    <div className="flex justify-between">
                        <h1 className=" font-semibold lg:text-lg text-md">En İyi İşlemler</h1>
                        <CustomLink to={"/panel"} className="btn btn-sm rounded-sm  border btn-primary ">Tüm İşlemler</CustomLink>

                    </div>
                    {positions?.map(symbolName => {
                        const index = getSymbolIndex(symbolName)
                        if (!index) return <></>

                        const symbol = symbolList[index]
                        return <Link onClick={(e) => {
                            e.preventDefault()
                            setSelectedSymbol(null)
                            navigate("/panel?symbol=" + symbol.Name)
                        }} to={"/panel?symbol=" + symbol.Name} className="flex flex-col gap-4 border border-base-100 bg-base-200 rounded-lg p-4 home-symbol-success">

                            <div className="flex items-center">
                                <div className="flex flex-1 gap-4 items-center">
                                    <img className="h-6" src={`https://cdn.fxcrm.me/parite/${symbol.Symbol.toLowerCase()}.png`} />
                                    <span className="font-bold lg:text-md text-sm">{symbol.Name}</span>
                                </div>

                                <div className="flex gap-4">
                                    <div className="flex flex-col gap-2 items-end lg:w-28 w-16">
                                        <span className="text-xs">AL</span>
                                        <span className="font-bold lg:text-md text-sm"><PriceText value={symbol?.Bid} /></span>
                                    </div>
                                    <div className="flex flex-col gap-2 items-end lg:w-28  w-16">
                                        <span className="text-xs">SAT</span>
                                        <span className="font-bold lg:text-md text-sm"><PriceText value={symbol?.Ask} /></span>
                                    </div>
                                </div>
                            </div>

                        </Link>
                    })}
                </div>

                {suggestions && <div className="flex flex-col lg:w-96 w-full">
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="lg:text-lg text-md font-semibold">İşlem Önerileri ({suggestions.length})</h1>
                        <CustomLink to={"/suggestions"} className="btn btn-sm rounded-sm  border btn-primary ">Tüm Önerİler</CustomLink>
                    </div>

                    <HomeSuggestionList suggestions={suggestions} />
                </div>}

            </div>

        </div>
    </div >
}