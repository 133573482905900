import { Comment, CommentsResult, Trader } from "@/types"
import { getTraderComments, postComment, reportCommentRequest } from "@/utils"
import { FormEvent, useState } from "react"
import { emitEvent, eventConstants } from "../../main"
import MobileReportCommentModal from "./MobileReportCommentModal"

export default function ({ trader, traderComments }: { trader: Trader, traderComments: CommentsResult }) {

    const [commentMode, setCommentMode] = useState(false)
    const [comments, setComments] = useState(traderComments.entries)
    const [selectedComment, setSelectedComment] = useState<Comment | null>(null)


    async function refreshComments(traderId: string) {

        const [traderComments, traderCommentsError] = await getTraderComments({ page: 1, traderId })

        if (traderCommentsError) {
            return []
        }

        return traderComments.entries
    }

    async function handleCommentSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const formData = new FormData(e.target as HTMLFormElement)

        const stars = formData.get("stars") as string
        const message = formData.get("message") as string
        const hideName = formData.get("hideName") as string
        const traderId = formData.get("traderId") as string

        const [data, err] = await postComment({ message: message, star: parseInt(stars), hideName: hideName === null ? false : true, traderId: traderId })

        if (err) {
            console.log(err)
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Yorum paylaşırken hata oluştu!" })
            return false
        }

        console.log(data)
        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Yorum başarıyla paylaşıldı!" })
        const res = await refreshComments(traderId)
        setComments([...res])
        setCommentMode(false)
    }


    async function handleReportComment(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const followData = new FormData(e.target as HTMLFormElement)

        const reason = followData.get("reason") as string
        const message = followData.get("message") as string
        const commentId = followData.get("commentId") as string

        const [_, err] = await reportCommentRequest({ reason: reason, message: message, commentId: commentId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Bu yorum daha önce şikayet edildi!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Yorum başarıyla şikayet edildi!" })
        setSelectedComment(null)
    }

    return <>
        {selectedComment && <MobileReportCommentModal comment={selectedComment} onSubmit={handleReportComment} onClose={() => setSelectedComment(null)} />}
        <div className="lg:hidden flex flex-col gap-3 rounded-lg">
            <div className="flex justify-between items-center">
                <h1 className="font-semibold">Yorumlar</h1>

                {!trader.owner && <button className={commentMode ? "btn btn-sm btn-primary" : "btn btn-sm"} onClick={() => setCommentMode(!commentMode)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                    </svg>

                    Yorum Yap</button>}

            </div>
            {commentMode && <form onSubmit={handleCommentSubmit} className="flex flex-col gap-4 my-4 content-container p-4 rounded-lg">
                <input type="hidden" name="traderId" value={trader._id} readOnly />
                <div className="rating rating-sm">
                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={1} required />
                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={2} required />
                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={3} required />
                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={4} required />
                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={5} required defaultChecked />
                </div>
                <textarea className="textarea textarea-bordered" name="message" placeholder="Bir mesaj bırak" required></textarea>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text">Adımı Sakla</span>
                        <input type="checkbox" name="hideName" defaultChecked={false} className="checkbox checkbox-primary checkbox-sm" />
                    </label>
                </div>
                <button className="btn btn-primary btn-sm">Paylaş</button>
            </form>}

            {comments.map(comment => {

                return <div key={comment._id} className=" flex flex-col gap-2 content-container p-4 rounded-lg">
                    <div className="flex items-center justify-between">
                        <div className="flex gap-2 items-center">
                            <span className="text-xs">{comment.follower.tamad}</span>
                            <div className="rating rating-xs pointer-events-none">

                                {[1, 2, 3, 4, 5].map(ratingTrader => {
                                    return <input type="radio" name="rating-5" className="mask mask-star-2 bg-orange-400" readOnly checked={comment.star >= ratingTrader} />
                                })}
                            </div>
                        </div>

                        <button className="hover:text-error" onClick={() => setSelectedComment(comment)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>
                        </button>
                    </div>
                    <span className="text-sm">{comment.message.length > 0 ? comment.message : "-"}</span>
                </div>
            })}
        </div>
    </>
}