import { useState } from "react";

export default function ({ element }: { element: HTMLElement | null }) {

    const [isDragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);


    const handleMouseDown = (e: React.MouseEvent) => {
        setDragging(true);
        setStartX(e.clientX);
        setScrollLeft(element!.scrollLeft);
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;

        const deltaX = e.clientX - startX;
        if (element) {
            element!.scrollLeft = scrollLeft - (deltaX * 2);
        }
    };

    const handleMouseLeave = () => {
        setDragging(false)
    }


    return { handleMouseDown, handleMouseMove, handleMouseUp, handleMouseLeave }
}