import { getAnalytics, logEvent } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { eventConstants, onEvent } from "./events";
import http from "./http";

const firbaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyD17hIkkXoaz5g_CKa5SgTfx-_KYpDsJ1w",
    authDomain: "weebtr-fb7ca.firebaseapp.com",
    projectId: "weebtr-fb7ca",
    storageBucket: "weebtr-fb7ca.appspot.com",
    messagingSenderId: "50626152833",
    appId: "1:50626152833:web:4aa531a066cefd30e6c080",
    measurementId: "G-3HNSV9KKS8"
}

const app = initializeApp(firbaseConfig)
const analytics = getAnalytics(app)
export const messaging = getMessaging(app)

// console.log(app, analytics, "INIT FIREBASE AND ANALYTICS")


export function LogToEvent(eventName: string, eventParams?: { [key: string]: any } | undefined) {
    return logEvent(analytics, eventName, eventParams)
}

export function requestPermission(): Promise<string> {

    return new Promise((res, rej) => {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                getToken(messaging).then((tokenVal) => {
                    res(tokenVal)


                }).catch(err => {
                    console.log("MESSAGING ERROR", err)
                    rej()
                })
            } else {
                rej()
            }
        })
    })
}


onEvent(eventConstants.SET_FIREBASE_TOKEN, async (token: string) => {

    const defaultToken = localStorage.getItem("fcmToken")

    // Token exists and is the same
    if (defaultToken && defaultToken === token) {
        http.post("user/fcmToken", {
            "type": "add",
            "fcmToken": token
        }).then(res => {
            console.log(res.data)

        })

        return
    }

    // Token Doesnt Exist
    if (!defaultToken) {
        http.post("user/fcmToken", {
            "type": "add",
            "fcmToken": token
        }).then(res => {
            console.log(res.data)
            localStorage.setItem("fcmToken", token)
        })

        return
    }

    // Token Exists but not the same
    if (defaultToken && defaultToken !== token) {
        await http.post("user/fcmToken", {
            "type": "delete",
            "fcmToken": defaultToken
        }).then(res => {
            console.log(res.data)
        })

        await http.post("user/fcmToken", {
            "type": "add",
            "fcmToken": token
        }).then(res => {
            localStorage.setItem("fcmToken", token)
            console.log(res.data)
        })

        return
    }

})


onMessage(messaging, (payload) => {
    console.log(payload)
})