import { NotificationCountAtom } from "@/atoms";
import { getUnreadNotificationCount } from "@/utils";
import { useAtom } from "jotai";
import { useEffect } from "react";


export default function () {

    const [count, setCount] = useAtom(NotificationCountAtom)

    useEffect(() => {
        getUnreadNotificationCount().then(([data, _]) => {

            if (data) {
                setCount(data.status)
            }

        })
    }, [])



    return count
}