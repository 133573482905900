import { Trader } from "@/types";
import { FormEvent, useState } from "react";
import RangeInput from "../RangeInput";
import MobileBottomDrawer from "./MobileBottomDrawer";

export default function ({ trader, onClose, onSubmit }: { trader: Trader, onClose: () => void, onSubmit: (event: FormEvent<HTMLFormElement>) => void }) {

    const [lotValue, setLotValue] = useState(0.0)

    return <MobileBottomDrawer outsideClicked={onClose} visible={true}>

        <form onSubmit={onSubmit}>
            <input type="hidden" name="traderId" value={trader._id} readOnly />
            <h3 className="font-bold text-lg">Pozisyon</h3>
            <div className="flex flex-col gap-2 mt-4">
                <div className="form-control p-2 bg-base-300 rounded-xl">
                    <label className="label cursor-pointer">
                        <span className="label-text">Pozisyon Aç</span>
                        <input type="checkbox" className="checkbox checkbox-primary" name="option" value={"POSITION_OPEN"} />
                    </label>
                </div>
                <div className="form-control p-2 bg-base-300 rounded-xl">

                    <label className="label cursor-pointer">
                        <span className="label-text">Pozisyon Düzenle</span>
                        <input type="checkbox" className="checkbox checkbox-primary" name="option" value={"POSITION_EDIT"} />
                    </label>
                </div>
                <div className="form-control p-2 bg-base-300 rounded-xl">

                    <label className="label cursor-pointer">
                        <span className="label-text">Pozisyon Kapa</span>
                        <input type="checkbox" className="checkbox checkbox-primary" name="option" value={"POSITION_CLOSE"} />
                    </label>
                </div>
            </div>

            <div className="mt-4"></div>
            <h3 className="font-bold text-lg">Emir</h3>
            <div className="flex flex-col gap-2 mt-4">
                <div className="form-control p-2 bg-base-300 rounded-xl">
                    <label className="label cursor-pointer">
                        <span className="label-text">Emir Aç</span>
                        <input type="checkbox" className="checkbox checkbox-primary" name="option" value={"ORDER_OPEN"} />
                    </label>
                </div>
                <div className="form-control p-2 bg-base-300 rounded-xl">
                    <label className="label cursor-pointer">
                        <span className="label-text">Emir Kapa</span>
                        <input type="checkbox" className="checkbox checkbox-primary" name="option" value={"ORDER_CLOSE"} />
                    </label>
                </div>
            </div>



            <div className="mt-4"></div>
            <h3 className="font-bold text-lg">Pozisyon Boyutu</h3>

            <div className="flex flex-col p-4 gap-1 rounded-xl bg-base-300 my-4">
                <div className="flex items-center justify-evenly">
                    <div onClick={() => setLotValue(25)} className="btn btn-primary btn-sm rounded-full text-primary-content">25%</div>
                    <div onClick={() => setLotValue(50)} className="btn btn-primary btn-sm rounded-full text-primary-content">50%</div>
                    <div onClick={() => setLotValue(75)} className="btn btn-primary btn-sm rounded-full text-primary-content">75%</div>
                    <div onClick={() => setLotValue(100)} className="btn btn-primary btn-sm rounded-full text-primary-content">100%</div>
                </div>

                <div className="mt-3 mb-3">
                    <RangeInput min={0} max={100.00} value={lotValue} onChange={e => setLotValue(parseFloat(e.target.value))} step={1} name="lotPercentage" />
                </div>

                <h4 className="text-center font-bold text-primary">{lotValue}%</h4>
            </div>

            <div className="flex mt-2">
                <button className="btn w-full btn-primary">Takip Et</button>
            </div>
        </form>
    </MobileBottomDrawer>


}