import Error from "../error";
import { NotificationList, Result, SystemNotification } from "../types";
import http from "./http";


export async function getUserNotifications(opt = { page: 1 }): Promise<Result<NotificationList, Error>> {
    try {
        const res = await http.get("notifications/get", { params: { page: opt.page } })
        const data: NotificationList = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting user notifications", { cause: err })]
    }
}


export async function getUnreadNotificationCount(): Promise<Result<{ message: string, status: number }, Error>> {
    try {
        const res = await http.get("notifications/count")
        const data: { message: string, status: number } = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting user notifications count", { cause: err })]
    }
}


export async function getSystemNotifications(opt = { page: 1 }): Promise<Result<SystemNotification[], Error>> {
    try {
        const res = await http.get("notifications/system_get", { params: { page: opt.page } })
        const data: SystemNotification[] = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting user notifications", { cause: err })]
    }
}


export async function deleteNotifications(): Promise<Result<any, Error>> {

    try {
        const res = await http.post("notifications/all")

        return [res.data, null]
    } catch (error) {
        return [null, new Error("Error while deleting notifications")]
    }
}


export async function readNotification(id: string): Promise<Result<any, Error>> {

    try {
        const res = await http.post("notifications/status", { id: id })
        const data: any = res.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while deleting notifications")]
    }
}