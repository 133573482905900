import usePrompt from "@/hooks/usePrompt"
import { Suggestion } from "@/types"
import { acceptSuggestion, rejectSuggestion, suggestionProposal } from "@/utils/suggestions"
import { FormEvent, useState } from "react"
import { useLoaderData } from "react-router-dom"
import { emitEvent, eventConstants } from "../main"
import ProposalModal from "./ProposalModal"
import useWindowSize from "@/hooks/useWindowSize"
import MobileProposalModal from "./mobile/MobileProposalModal"

export default function () {


    const { suggestions } = useLoaderData() as { suggestions: Suggestion[] }
    const [suggestionsCopy, setSuggestionsCopy] = useState(suggestions)
    const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion | null>(null)
    const [proposalModalOpen, setProposalModalOpen] = useState(false)
    const isMobile = useWindowSize(700)

    const { prompt, openPrompt } = usePrompt({ onAccept: handleRecommendationReject, description: "Bu işlem önerisini kaldırmak itediğinize emin misiniz?" })


    function openRejectPrompt(suggestion: Suggestion) {
        setSelectedSuggestion(suggestion)
        openPrompt()
    }

    async function handleRecommendationReject() {
        if (!selectedSuggestion) return

        const [_, err] = await rejectSuggestion(selectedSuggestion._id)

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Öneri kaldırılırken hata oluştu!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Öneri kaldırıldı!" })

        setSuggestionsCopy(prev => [...prev.filter(item => item._id !== selectedSuggestion._id)])
    }


    async function handleSuggestionAccept(suggestion: Suggestion) {

        const [_, err] = await acceptSuggestion(suggestion._id)

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Öneriyi kabul ederken hata oluştu!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Pozisyon açıldı!" })

        setSuggestionsCopy(prev => [...prev.filter(item => item._id !== suggestion._id)])
    }


    async function handleSuggestionProposal(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const formData = new FormData(e.target as HTMLFormElement)

        const categories = formData.getAll("categories") as string[]

        const [_, err] = await suggestionProposal(categories.join(","))

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Öneri talep ederken hata oluştu!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Öneri talebi başarıyal gerçekleşti!" })

    }
    return <>

        <div className="flex flex-col p-6 items-center">
            <div className="container">

                <div className="flex justify-between items-center">
                    <h1 className="font-bold lg:text-4xl md:text-2xl text-lg">İşlem Önerileri ({suggestionsCopy.length})</h1>
                    <button onClick={() => setProposalModalOpen(true)} className="btn btn-sm md:btn-md btn-primary">Yeni İşlem Önerisi</button>
                </div>

                <div className="flex flex-col gap-2 mt-10">
                    {proposalModalOpen && !isMobile ? <ProposalModal onClose={() => { setProposalModalOpen(false) }} onSubmit={handleSuggestionProposal} /> : null}
                    {proposalModalOpen && isMobile ? <MobileProposalModal onClose={() => { setProposalModalOpen(false) }} onSubmit={handleSuggestionProposal} /> : null}
                    {prompt()}
                    {suggestionsCopy.map(suggestion => {
                        return <div key={suggestion._id} className="mb-2 flex flex-col gap-4 content-container rounded-lg p-4">

                            <div className="flex gap-4 items-center">
                                <div className="flex gap-4 items-center">
                                    <img className="h-6" src={`https://cdn.fxcrm.me/parite/${suggestion.symbol.toLowerCase()}.png`} />
                                </div>
                                <div className="flex flex-col items-start flex-1">
                                    <span className="text-sm font-bold">{suggestion.symbol}</span>
                                    <span className="text-xs opacity-40">Recommended By: {suggestion.recommender}</span>
                                    <span className="text-xs opacity-40">Volume: {suggestion.volume}</span>
                                </div>
                                <div className="flex items-center gap-3 justify-end">
                                    <button onClick={() => handleSuggestionAccept(suggestion)} className={"btn btn-sm text-xs text-primary-content font-bold " + (suggestion.type === "BUY" ? "btn-success" : "btn-error")}>{suggestion.type === "BUY" ? "AL" : "SAT"}</button>
                                    <button onClick={() => openRejectPrompt(suggestion)} className="text-error">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                    })}
                </div>

            </div>

        </div>

    </>


}