import { Basket, BasketItemWithComplete, BasketWithComplete } from "@/types";
import { FormEvent, useRef, useState } from "react";
import BasketRequestModal from "./BasketRequestModal";
import { basketCompleteItem, basketRequest, getSingleBasket, openPositionOrOrder } from "@/utils";
import { emitEvent, eventConstants } from "../main";
import RangeInput from "./RangeInput";
import MobileBasketRequestModal from "./mobile/MobileBasketRequestModal";
import useWindowSize from "@/hooks/useWindowSize";

export default function ({ basketList, defaultBasket = null }: { basketList: Basket[], defaultBasket: null | BasketWithComplete }) {

    const [openRequestModal, setRequestModel] = useState(false)
    const [selectedBasket, setSelectedBasket] = useState<BasketWithComplete | null>(defaultBasket)
    const [selectedBasketCompletedItems, setSelectedBasketCompletedItems] = useState(0)
    const [checkedBasketItems, setCheckedBasketItems] = useState<BasketItemWithComplete[]>([])
    const checkboxRef = useRef<null | HTMLInputElement>(null)
    const isMobile = useWindowSize(700)


    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const formData = new FormData(e.target as HTMLFormElement)

        const categories = formData.getAll("categories") as string[]

        const [_, err] = await basketRequest(categories.join(","))

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Öneri talep ederken hata oluştu!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Öneri talebi başarıyal gerçekleşti!" })
        setRequestModel(false)
    }

    async function selectBasket(basket: Basket) {

        getSingleBasket(basket._id).then(([data, err]) => {

            if (err) {
                return
            }

            setSelectedBasketCompletedItems(prev => {
                prev = 0

                data.positionBasketItems.forEach(item => {
                    if (item.isCompleted) {
                        prev = prev + 1
                    }
                })

                return prev
            })

            setSelectedBasket(data)


        })

    }


    function isChecked(item: BasketItemWithComplete) {
        const found = checkedBasketItems.find(target => target._id === item._id)

        return found ? true : false
    }


    function checkItem(basketItem: BasketItemWithComplete) {

        setCheckedBasketItems(prev => {
            const found = prev.find(target => target._id === basketItem._id);


            if (!found) {
                return [...prev, basketItem]
            }

            if (found) {
                return [...prev.filter(target => target._id !== basketItem._id)]
            }

            return [...prev]
        })

    }



    async function completeBasket() {
        if (checkedBasketItems.length > 0) {
            openSelectedItems()
            return
        }

        openAllItems()
    }

    function openSelectedItems() {
        if (!selectedBasket) return
        let incompleteItems = checkedBasketItems
        let ogItems = selectedBasket.positionBasketItems

        incompleteItems.forEach(item => {

            if (item.isCompleted) return

            openPositionOrOrder({ lot: item.volume, Symbol: item.symbol, Type: item.type === "SELL" ? 1 : 0, digit: 1, sl: 0.0, emir: 0.0, tp: 0.0 }).then(([data, err]) => {

                if (err) {
                    emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
                    return
                }

                const index = ogItems.findIndex(target => target._id === item._id)

                ogItems[index].isCompleted = true


                setSelectedBasketCompletedItems(prev => {
                    prev = 0

                    ogItems.forEach(item => {
                        if (item.isCompleted) {
                            prev = prev + 1
                        }
                    })

                    return prev
                })

                setSelectedBasket(prev => {
                    if (!prev) return null

                    return { ...prev, positionBasketItems: [...ogItems] }
                })

                checkItem(item)
                basketCompleteItem(item._id, data.ID).then(([_, err]) => {
                    if (err) {
                        emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
                        return
                    }
                })

                emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Pozisyon başarıyla açıldı." })
            })
        })
    }


    function openAllItems() {

        if (!selectedBasket) return
        let incompleteItems = selectedBasket.positionBasketItems

        incompleteItems.forEach(item => {

            if (item.isCompleted) return

            openPositionOrOrder({ lot: item.volume, Symbol: item.symbol, Type: item.type === "SELL" ? 1 : 0, digit: 1, sl: 0.0, emir: 0.0, tp: 0.0 }).then(([_, err]) => {
                if (err) {
                    emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
                    return
                }

                const index = incompleteItems.findIndex(target => target._id === item._id)

                incompleteItems[index].isCompleted = true


                setSelectedBasketCompletedItems(prev => {
                    prev = 0

                    incompleteItems.forEach(item => {
                        if (item.isCompleted) {
                            prev = prev + 1
                        }
                    })

                    return prev
                })

                setSelectedBasket(prev => {
                    if (!prev) return null

                    return { ...prev, positionBasketItems: [...incompleteItems] }
                })

                emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Pozisyon başarıyla açıldı." })
            })
        })
    }

    function clearState() {
        setSelectedBasket(null)
        setCheckedBasketItems([])
        setRequestModel(false)
        setSelectedBasketCompletedItems(0)
        emitEvent(eventConstants.CLOSE_BASKET_DRAWER, "")
    }

    return <>
        {openRequestModal && !isMobile ? <BasketRequestModal onSubmit={handleSubmit} onClose={() => setRequestModel(false)} /> : null}
        {openRequestModal && isMobile ? <MobileBasketRequestModal onSubmit={handleSubmit} onClose={() => setRequestModel(false)} /> : null}

        <div>

            <div className="drawer drawer-end w- relative z-50">
                <input ref={checkboxRef} id="BasketDrawer" type="checkbox" defaultChecked onChange={(e) => {
                    if (!e.target.checked) {
                        clearState()
                    }
                }} className="drawer-toggle" />
                <div className="drawer-content">
                    {/* Page content here */}
                </div>
                <div className="drawer-side">
                    <label htmlFor="BasketDrawer" aria-label="close sidebar" className="drawer-overlay"></label>
                    <ul className="menu p-4 lg:w-96 w-full min-h-full bg-base-200 text-base-content">

                        {!selectedBasket && <>
                            <div className="mb-10"></div>
                            <div className="flex items-center justify-between">
                                <span className="text-4xl font-bold">Sepetiniz</span>
                                <button className="btn btn-circle " onClick={() => clearState()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            <div className="mb-10"></div>
                            {/* Sidebar content here */}
                            <div className="flex flex-col gap-3">
                                <p className="p-4 bg-base-100 rounded-lg">
                                    Pozisyon sepeti, uzmanların belirli bir stratejiye göre oluşturdukları pozisyonları içerir. Yatırımcılar, sepetteki pozisyonları tek bir işlemde açabilirler. Dilerseniz uzmanınız size özel bir Sepet oluşturabilir, bunun için hemen Pozisyon Sepeti İste butonuna tıklayınız.
                                </p>
                                <div className="mt-1"></div>
                                <button onClick={() => setRequestModel(true)} className="btn btn-primary w-full text-sm btn-sm">Pozisyon Sepeti İste</button>
                            </div>
                            <div className="mb-6"></div>
                            <div className="flex flex-col gap-3">
                                {basketList.map(basket => {
                                    return <div onClick={() => selectBasket(basket)} className="bg-base-100 p-4 rounded-lg cursor-pointer">
                                        <div className="flex justify-between items-center">
                                            <div className="flex flex-col gap-1">
                                                <span className="text-lg">{basket.name}</span>
                                                <span className="text-xs"> Sepetteki Pozisyon Sayısı: {basket.positionBasketItems.length}</span>
                                            </div>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                            </svg>

                                        </div>
                                    </div>
                                })}
                            </div>
                        </>}


                        {selectedBasket && <>

                            <div className="mb-10"></div>
                            <div className="flex items-center gap-3">
                                <button onClick={() => {
                                    setSelectedBasket(null)
                                    setCheckedBasketItems([])
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                    </svg>
                                </button>

                                <span className="text-4xl font-bold">{selectedBasket.name}</span>
                            </div>

                            <div className="mb-10"></div>
                            {/* Sidebar content here */}
                            <div className="flex flex-col gap-3">
                                <div className="p-4 bg-base-100 rounded-lg">
                                    <p> Pozisyon sepeti, uzmanların belirli bir stratejiye göre oluşturdukları pozisyonları içerir. Yatırımcılar, sepetteki pozisyonları tek bir işlemde açabilirler.</p>
                                    <div className="mt-4"></div>
                                    <p className="text-lg font-semibold">İlerleme Durumu</p>
                                    <div className="mt-3"></div>
                                    <RangeInput value={selectedBasketCompletedItems} min={0} max={selectedBasket.positionBasketItems.length} readOnly />
                                    <div className="mt-3"></div>
                                    <div className="text-center">
                                        <p className="text-md font-semibold">{selectedBasketCompletedItems}/{selectedBasket.positionBasketItems.length}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="mb-6"></div>
                            <div className="flex flex-col flex-1 gap-3">
                                {selectedBasket.positionBasketItems.map(item => {
                                    return <div className="relative bg-base-100 p-4 rounded-lg cursor-pointer" onClick={() => {
                                        if (item.isCompleted) return
                                        checkItem(item)
                                    }}>
                                        {item.isCompleted && <div className="absolute top-0 left-0 w-full h-full p-4 flex justify-center items-center text-success z-10 bg-base-200 bg-opacity-75">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                                                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                            </svg>
                                        </div>}
                                        <div className="flex items-center gap-4">
                                            {isChecked(item) ? <div className="text-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7">
                                                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                                </svg>
                                            </div> : <div className="w-7 h-7 rounded-full border border-base-200 bg-base-100"></div>}

                                            <div className="w-10 h-10">
                                                <img src={`https://cdn.fxcrm.me/parite/${item.symbol.toLowerCase()}.png`} />
                                            </div>
                                            <div className="flex flex-col gap-1 flex-1">
                                                <span className="text-lg font-semibold">{item.symbol}</span>
                                                <span className="text-xs"> {item.volume} lot</span>
                                            </div>

                                            {item.type === "SELL" ? <button className="btn btn-sm btn-error">Sat</button> : <button className="btn btn-sm btn-success">Al</button>}

                                        </div>
                                    </div>
                                })}

                                <div className="flex flex-col justify-end flex-1">
                                    <div className="flex flex-col gap-3 bg-base-100 p-4 rounded-lg">
                                        <p>
                                            Sepetteki tüm pozisyonları açmak için Sepeti Aç butonuna tıklayınız. Sepetteki bazı pozisyonları açmak istiyorsanız pozisyonları seçip Pozisyonları Aç butonuna tıklayınız.
                                        </p>
                                        <div className="flex gap-3">
                                            <button className="btn btn-primary flex-1" onClick={completeBasket}>Sepeti Aç</button>
                                            <button className="btn flex-1">Reddet</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </ul>
                </div>
            </div>
        </div>
    </>
}