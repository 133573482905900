import { UserAtom } from "@/atoms"
import useExchangeRate from "@/hooks/useExchangeRate"
import { formatToTurkishIBAN, getBankNameFromIban, setWithdrawRequest } from "@/utils"
import { useAtomValue } from "jotai"
import { FormEvent, useState } from "react"
import { emitEvent, eventConstants } from "../main"

type Currency = {
    id: number,
    currency: string
}

const currencies: Currency[] = [
    {
        id: 1,
        currency: "TL",
    },
    {
        id: 2,
        currency: "USD",
    }
]


export default function () {
    const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(null)

    const renderCurrencySelection = () => {

        if (selectedCurrency) return null

        return currencies.map(item => {
            return <button key={item.id} onClick={() => setSelectedCurrency(item)} className="btn max-h-[30%] h-full flex-item bg-base-100 flex flex-col gap-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                </svg>
                <p className="leading-5">
                    {item.currency}
                </p>
            </button>
        })
    }


    const renderDepositForm = () => {
        if (!selectedCurrency) return null

        return <WithdrawForm selectedCurrency={selectedCurrency} />
    }

    return <div className="flex flex-1 gap-3 flex-wrap">
        {renderCurrencySelection()}
        {renderDepositForm()}
    </div>
}



function WithdrawForm({ selectedCurrency }: { selectedCurrency: Currency }) {

    const { exchangeRate } = useExchangeRate()
    const [value, setValue] = useState("0")
    const [valueWithExchange, setValueWithExchange] = useState(0)
    const [ibanMask, setIbanMask] = useState("")
    const [ibanValue, setIbanValue] = useState("")
    const [bankNameValue, setBankName] = useState("")

    const user = useAtomValue(UserAtom)
    const isTL = selectedCurrency.currency === "TL"

    function handleAmountChange(val: string) {

        const filtered = val.replace(/[^0-9]/g, '');

        setValue(filtered)

        if (exchangeRate && isTL) {
            setValueWithExchange(parseFloat(val) / parseFloat(exchangeRate))
        }
    }


    function handleIbanInput(val: string) {
        if (val.length === 0) {
            setIbanMask("")
            setIbanValue("")
            return
        }
        const formattedValue = formatToTurkishIBAN(val.toUpperCase())
        if (formattedValue) {

            const cleanValue = formattedValue?.replaceAll(" ", "")

            setIbanMask(formattedValue)
            setIbanValue(cleanValue)
            const bankName = getBankNameFromIban(cleanValue)
            setBankName(bankName)
        }
    }


    async function createWithdrawRequest(e: FormEvent<HTMLFormElement>) {

        e.preventDefault()

        const fromData = new FormData(e.target as HTMLFormElement)

        const amount = fromData.get("amount") as string


        const [_, err] = await setWithdrawRequest({ iban: ibanValue, doviz: selectedCurrency.currency, amount: parseFloat(amount) })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Para çekme isteğiniz başarılı bir şekilde yapıldı!" })
        handleAmountChange("0")
    }

    return <form onSubmit={createWithdrawRequest} className="flex flex-col items-start prose max-w-none  gap-4">
        <div className="join rounded-lg">
            <div className="flex  content-container p-4 join-item">
                <p className="text-xs">Minimum Çekim Tutarı: <b>100 USD</b></p>
            </div>
            <div className="join-item  px-4 flex items-center content-container">
                {exchangeRate && <p className="text-xs font-bold">{parseFloat(exchangeRate).toFixed(2)} TL</p>}

            </div>
        </div>

        <div className="flex flex-col gap-2">

            <span className="opacity-70 flex-1">Buyer</span>
            <input name="buyer" className="input input-bordered join-item appearance-none" type="text" placeholder="Tutar" value={user?.name} onChange={(e) => handleAmountChange(e.target.value)} readOnly />
        </div>
        <div className="flex flex-col gap-2">
            <span className="opacity-70 flex-1">İBAN</span>
            <input name="iban" className="input input-bordered join-item appearance-none" value={ibanValue} type="hidden" />
            <div className="join">
                <input className="input input-bordered join-item appearance-none" type="text" placeholder="TR" value={ibanMask} onChange={(e) => handleIbanInput(e.target.value)} />
                {bankNameValue.length > 0 && <span className="btn bg-base-300 join-item cursor-default border-base-300 pointer-events-none">{bankNameValue}</span>}
            </div>
        </div>


        <div className="flex flex-col gap-2">
            <span className="opacity-70 flex-1">Tutar</span>
            <div className="join">
                <input name="amount" className="input input-bordered join-item appearance-none" type="text" placeholder="Tutar" defaultValue={0.0} value={value} onChange={(e) => handleAmountChange(e.target.value)} />
                <span className="btn bg-base-300 join-item cursor-default border-base-300 pointer-events-none">{selectedCurrency.currency}</span>
            </div>
            {isTL && <div className="flex items-center justify-between">
                <p className="text-xs">Dolar Karşılığı:</p>
                <p className="text-xs">${valueWithExchange.toFixed(2)} USD</p>

            </div>}
        </div>


        <div className="mt-4">
            <button className="btn btn-sm btn-primary text-primary-content">Para çekme isteği gönder</button>
        </div>

    </form>

}