import { MarginsAtom, SymbolsAtom, UserAtom, UserSpreadsAtom, symbolsManagerInstance } from "@/atoms";
import { BidData, PNLData } from "@/types";
import { positionsManager } from "@/utils/PositionsManager";
import { userFinancesManager } from "@/utils/userFinancesManger";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { Outlet, } from "react-router-dom";
import { socketInstance } from "../components/LoadingScreen";
import { emitEvent, eventConstants, onEvent } from "../main";
import constants from "@/constants";
import positionStore from "../components/stores/store";

export default function () {


    const setSymbols = useSetAtom(SymbolsAtom)
    const setUser = useSetAtom(UserAtom)
    const margins = useAtomValue(MarginsAtom)
    const user = useAtomValue(UserAtom)
    const spreads = useAtomValue(UserSpreadsAtom)

    const zusPositions = positionStore((state) => state.positions)

    function handlePnlData(data: PNLData[]) {
        console.log("UPDATING PNL")
        emitEvent(eventConstants.PNL_UPDATED, data)
    }

    function handleBidData(data: BidData) {

        if (!spreads) return

        const newList = symbolsManagerInstance.getArray()

        const itemIndex = newList.findIndex(item => item.Symbol === data.symbol)

        if (itemIndex === -1) return

        if (!newList) return

        const calculatedSpread = symbolsManagerInstance.calculateSpreadDiff(data, spreads.group_spreads, spreads.group_symbols, newList[itemIndex])

        if (!calculatedSpread) return

        const item = newList[itemIndex]

        const ask = parseFloat(item.Ask) + calculatedSpread.askDifference
        const bid = parseFloat(item.Bid) + calculatedSpread.bidDifference

        // console.log(item.Ask, item.Bid, ask, bid, calculatedSpread.askDifference, calculatedSpread.bidDifference)
        item.Ask = ask.toFixed(data.digits)
        item.Bid = bid.toFixed(data.digits)

        item.Digits = data.digits
        item.Time = Date.now()
        newList[itemIndex] = item

        symbolsManagerInstance.setArray(newList)
    }


    useEffect(() => {

        const logoutEvent = onEvent(eventConstants.LOGOUT, () => {
            localStorage.removeItem(constants.AUTH_STORAGE_KEY)
            window.location.replace("/")
        })

        const pnlUpdatedEvent = onEvent(eventConstants.PNL_UPDATED, (dataset: PNLData[]) => {

            if (!margins || !user || zusPositions.length === 0) return

            let total = 0.0

            dataset.map(blob => {

                const res = positionsManager.calculatePNL(blob, margins, parseFloat(user.MarginLeverage))

                if (!res) return

                total += res.profit

                positionsManager.updatePosition(res.currentPositionIndex, blob, res.profit, res.rate, res.swap, res.oldProfit)
            })

            emitEvent(eventConstants.POSITION_PNL_UPDATED, positionsManager.positions)
            console.log("SET USER PROFIT", total)
            userFinancesManager.setUserProfit(total)
        })

        return () => {
            pnlUpdatedEvent.unsubscribe()
            logoutEvent.unsubscribe()
        }
    }, [])



    useEffect(() => {

        socketInstance?.on("pnlData", handlePnlData)
        socketInstance?.on("bid", handleBidData)

        const interval = setInterval(() => {
            setSymbols([...symbolsManagerInstance.getArray()])
            setUser(prev => {
                if (!prev) return prev;
                prev.Profit = userFinancesManager.profit.toLocaleString()
                return { ...prev }
            })
        }, 1500)


        return () => {
            if (socketInstance) {
                console.log("UNSUBBED")
                socketInstance.off("pnlData", handlePnlData)
                socketInstance.off("bid", handleBidData)
                clearInterval(interval)
                socketInstance.close()
            }
        }

    }, [])

    return <>
        <Outlet />
    </>
}