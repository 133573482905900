import axios from "axios";
import constants from "@/constants";

const http = axios.create({
    baseURL: constants.API_URL
})


http.interceptors.request.use(config => {

    const trader_auth = localStorage.getItem(constants.AUTH_STORAGE_KEY)

    if (trader_auth) {
        config.headers.Authorization = trader_auth
    }


    return config
})

http.interceptors.response.use(res => {

    if (!res.data.status && res.data.code && res.data.code === 101) {
        localStorage.removeItem(constants.AUTH_STORAGE_KEY)
        // window.location.replace("/login")
    }

    return res
})
export default http