import React, { useState, useEffect } from 'react';

interface NumberInputForRangeProps {
    min?: number;
    max?: number;
    onChange: (value: number | null) => void;
    externalValue?: string;
}

const NumberInputForRange: React.FC<NumberInputForRangeProps> = ({ min = 0.01, max = 10000000, onChange, externalValue }) => {
    const [value, setValue] = useState<string>(externalValue ? externalValue.toString() : '');


    useEffect(() => {
        setValue(externalValue ? externalValue.toString() : '')
    }, [externalValue])


    const increment = () => {
        const numericValue = parseInt(value, 10);
        if (!isNaN(numericValue) && numericValue < max) {
            setValue((numericValue + 1).toString());
            onChange(numericValue + 1);
        }
    };

    const decrement = () => {
        const numericValue = parseInt(value, 10);
        if (!isNaN(numericValue) && numericValue > min) {
            const newValue = numericValue - 1;
            if (newValue < min) {
                setValue(min.toString());
                onChange(min);
            } else {
                setValue(newValue.toString());
                onChange(newValue);
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        if (newValue === '') {
            setValue('');
            // onChange(null); // Emit null when the input is completely emptied
        } else {
            const numericValue = parseFloat(newValue);
            if (!isNaN(numericValue)) {
                if (numericValue > max) {
                    // If the entered value is greater than the maximum, set it to the maximum
                    setValue(max.toString());
                    onChange(max);
                } else {
                    setValue(newValue);
                    onChange(numericValue);
                }
            }
        }
    };

    return (
        <div className="content-container flex rounded-lg p-2 items-center">
            <button className="btn btn-sm font-bold content-container hover:bg-primary hover:text-primary-content flex items-center px-2" onClick={decrement}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
                </svg>
            </button>
            <input
                type="text"
                value={value}
                onChange={handleChange}
                style={{ outline: "none", appearance: "textfield", MozAppearance: "textfield", WebkitAppearance: "textfield" }} className="input input-sm w-full flex-1 bg-base-100 text-center font-bold appearance-none"
            />
            <button className="btn btn-sm font-bold content-container hover:bg-primary px-2 hover:text-primary-content flex items-center" onClick={increment}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                </svg>
            </button>
        </div>
    );
};

export default NumberInputForRange;
