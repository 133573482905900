import { useState } from "react"
import BankWithdrawWizard from "./BankWithdrawWizard"
import CryptoWithdrawWizard from "./CryptoWithdrawWizard"


type StepType = "BANK" | "CRYPTO" | null
export default function () {

    const [step, setStep] = useState<StepType>(null)

    function changeStep(step: StepType) {
        setStep(step)
    }

    function renderStep(currentStep: StepType) {
        if (currentStep === "BANK") {
            return <BankWithdrawWizard />
        }

        if (currentStep === "CRYPTO") {
            return <CryptoWithdrawWizard />
        }


        return <></>
    }

    return <div className="flex flex-col flex-1 prose max-w-none  gap-4">
        <h1 className="text-xl">Withdraw</h1>
        <hr />

        {step === null && <>
            <p className="text-sm">
                Para çekmek için tercih ettiğiniz yöntemi seçiniz.
            </p>
            <div className="flex flex-1 gap-3 flex-wrap">
                <button onClick={() => changeStep("BANK")} className="btn max-h-[30%] h-full flex-item bg-base-100 flex flex-col gap-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                    </svg>
                    <p className="leading-5">
                        Banka Yolu İle Para Çek
                    </p>
                </button>

                <button onClick={() => changeStep("CRYPTO")} className="btn max-h-[30%] h-full flex-item bg-base-100 flex flex-col gap-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                    </svg>
                    <p className="leading-5">
                        Kripto Yolu İle Para Çek
                    </p>
                </button>
            </div>
        </>
        }

        {renderStep(step)}
    </div>
}