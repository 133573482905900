import Error from "@/error";
import { Result, Story } from "@/types";
import http from "./http";

export async function getStories(): Promise<Result<Story[], Error>> {

    try {
        const res = await http.get("story/list")
        const data: Story[] = res.data.data

        return [data, null]

    } catch (error) {

        return [null, new Error("Error while retrieving stories")]

    }

}

export async function storyRead(storyId: string): Promise<Result<Story[], Error>> {

    try {
        const res = await http.post("story/read", { storyId })
        const data: Story[] = res.data.data

        return [data, null]

    } catch (error) {

        return [null, new Error("Error while retrieving stories")]

    }

}