import { useEffect, useState } from "react";
import { eventConstants, onEvent } from "../main";
import { Alert } from "@/types";


let alertsOutside: Alert[] = []

export default function () {

    const [alerts, setAlerts] = useState<Alert[]>([])

    useEffect(() => {
        const alertEventSuccess = onEvent(eventConstants.ALERT_SUCCESS, (data) => {
            alertsOutside = [{
                id: Math.random() * 10000,
                type: "SUCCESS",
                message: data.message
            }, ...alertsOutside]

            setAlerts([...alertsOutside])
        })

        const alertEventFail = onEvent(eventConstants.ALERT_FAIL, (data) => {


            alertsOutside = [{
                id: Math.random() * 10000,
                type: "FAIL",
                message: data.message
            }, ...alertsOutside]

            setAlerts([...alertsOutside])
        })


        return () => {
            alertEventSuccess.unsubscribe()
            alertEventFail.unsubscribe()
        }

    }, [alerts])

    function handleAlertDestroy(target: Alert) {
        alertsOutside = alertsOutside.filter(item => item.id !== target.id)

        setAlerts([...alertsOutside])
    }


    return <div className="w-screen h-screen flex flex-col items-end justify-end fixed top-0 left-0 gap-3 pointer-events-none z-[9999] p-10">
        {alerts.map(item => {
            return <AlertItem key={item.id} data={item} destroy={() => handleAlertDestroy(item)} />
        })}
    </div>
}



function AlertItem({ data, destroy }: { data: Alert, destroy: () => void }) {

    const alertType = {
        "SUCCESS": "alert-success",
        "FAIL": "alert-error",
        "INFO": "alert-info"
    }

    useEffect(() => {

        setTimeout(() => {
            if (destroy) {
                destroy()
            }
        }, 3000)
    }, [])

    return <div className={`alert ${alertType[data.type]} text-primary-content max-w-md`}>
        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
        <span>{data.message}</span>
    </div>


}