import { FormEvent, useRef, useState } from "react"

import ForwardedInputComponent from "../components/ForwardedInputComponent"
import ForwardedSelectComponent from "../components/ForwardedSelectComponent"
import PhoneInputWithCountrySelect from "react-phone-number-input"
import { loginEmail, register } from "../lib/utils/authentication"
import CustomLink from "../components/CustomLink"
import { LogToEvent, getUser } from "@/utils"
import { useNavigate } from "react-router-dom"
import constants from "@/constants"
import Logo from "../components/ui/Logo"

export default function () {

    const [phone, setPhone] = useState("")
    const [country, setCountry] = useState("TR")
    const formRef = useRef<HTMLFormElement | null>(null)
    const navigate = useNavigate()
    const [error, setError] = useState<null | string>(null)


    async function handleRegister(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        setError(null)
        const data = new FormData(e.target as HTMLFormElement)

        const name = data.get("name") as string
        const email = data.get("email") as string
        const phone = data.get("phone") as string
        const password = data.get("password") as string
        const reference = data.get("reference") as string
        const country = data.get("country") as string

        const [, err] = await register({ mail: email, pass: password, name: name, ulke: country, telefon: phone, ref: reference });

        if (err) {
            setError(err.message)
            return
        }

        const [loginRes, _] = await loginEmail(email, password);

        if (formRef.current) {
            formRef.current.reset()
        }
        getUser().then(([data, err]) => {
            if (err) {
                return
            }
            LogToEvent('register_success', { "musteriId": data.musteriId.toString() })
        })

        if (loginRes) {
            localStorage.setItem(constants.AUTH_STORAGE_KEY, loginRes.token)
            navigate("/")
        }

    }

    return <div className="flex flex-col w-full items-center p-4">
        <Logo />
        <h1 className="md:text-2xl text-xl font-bold mt-6">Kayıt</h1>
        <div className="mb-10"></div>
        <div className="card  max-w-lg w-full shadow-xl content-container overflow-hidden">
            <form ref={formRef} onSubmit={handleRegister} className="card-body md:p-10 p-6 gap-3">
                <input name="name" placeholder="İsim Soyisim" className="input input-bordered input-sm md:input-md" required />
                <input name="email" placeholder="E-posta" className="input input-bordered input-sm md:input-md" required />
                <PhoneInputWithCountrySelect onCountryChange={(val) => setCountry(val ? val : "")} defaultCountry="TR" international className="join" inputComponent={ForwardedInputComponent} countrySelectComponent={ForwardedSelectComponent} onChange={(e) => setPhone(e ? e.toString() : "")} />
                <input name="phone" className="input input-bordered input-sm md:input-md" value={phone} hidden onChange={() => { }} />
                <input name="country" className="input input-bordered input-sm md:input-md" value={country} hidden onChange={() => { }} />
                <input name="password" placeholder="Şifre" className="input input-bordered input-sm md:input-md" required />
                <input name="reference" placeholder="Referans Kodu" className="input input-bordered input-sm md:input-md" required />

                {error && <div className="flex flex-col mt-3">

                    <div className="alert alert-error">
                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                        <span>{error}</span>
                    </div>
                </div>}
                <div className="card-actions mt-4">
                    <button className="btn btn-primary w-full btn-xs md:btn-md">Kayıt Ol</button>
                </div>
                <div className="divider">Veya</div>
                <div className="flex justify-center">
                    <CustomLink to={"/login"} className="btn  w-full btn-secondary rounded-md btn-xs md:btn-md">Giriş yap</CustomLink>

                </div>
            </form>


        </div>

    </div>
}