import { FormEvent } from "react";

export default function ({ submit }: { submit: (data: { file: File, belge: string }) => void }) {

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const data = new FormData(e.target as HTMLFormElement)

        const file = data.get("file") as File
        const belge = data.get("belge") as string

        submit({
            belge: belge,
            file: file
        })

    }

    return <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-10">
        <div className="flex flex-col gap-2 ">
            <span className="opacity-70 flex-1 text-sm">Belge Türü</span>
            <select name="belge" className="select select-bordered select-sm w-full max-w-xs">
                <option disabled selected>Belge Türü</option>
                <option value={"1"}>Nüfus Cüzdanı</option>
                <option value={"2"}>Pasaport</option>
                <option value={"3"}>İkametgah</option>
                <option value={"5"}>Diğer</option>
            </select>
        </div>

        <div className="flex flex-col gap-2 ">
            <span className="opacity-70 flex-1 text-sm">Belge</span>
            <input type="file" name="file" className="file-input file-input-bordered file-input-sm w-full max-w-xs" />
        </div>

        <div className="mt-4">
            <button type="submit" className="btn btn-success btn-sm text-sm text-primary-content">Gönder</button>
        </div>
    </form>
}