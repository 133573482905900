import { useState } from "react";
import LoginFormEmail from "../components/LoginFormEmail";
import LoginFormPhone from "../components/LoginFormPhone";
import Logo from "../components/ui/Logo";


export default function () {

    // const isAuth = useLoaderData() as Boolean;
    const [tab, setTab] = useState(0)
    // if (isAuth) return null

    return <div className="flex flex-col w-full items-center px-4">
        <Logo />
        <h1 className="md:text-2xl text-xl font-bold mt-6">Giriş</h1>
        <div className="mb-10"></div>
        <div className="card bg-base-100 max-w-lg w-full shadow-xl content-container overflow-hidden">
            <div className="flex md:text-sm text-xs text-center">
                <a onClick={() => setTab(0)} className={`flex items-center justify-center border-b p-4 ${tab === 0 ? "text-primary border-b border-primary bg-base-100" : "hover:bg-base-100 cursor-pointer border-secondary border-opacity-5 "} flex-1`}>Telefon Numarası</a>
                <a onClick={() => setTab(1)} className={`flex items-center justify-center border-b p-4 ${tab === 1 ? "text-primary border-b border-primary bg-base-100" : "hover:bg-base-100 cursor-pointer border-secondary border-opacity-5"} flex-1`}>Email</a>
            </div>
            {tab === 0 && <LoginFormPhone />}
            {tab === 1 && <LoginFormEmail />}
        </div>
    </div>
}