import { AppSettings, CreateBasketItem, Position, UpdatedPosition } from "@/types";
import { positionsManager } from "@/utils/PositionsManager";
import { StateCreator, create } from "zustand";

interface PositionSlice {
    positions: Position[],
    updatedPositions: UpdatedPosition[]
    setPositions: (items: Position[]) => void,
    setUpdatedPositions: (items: UpdatedPosition[]) => void,
    deletePosition: (id: string) => void,
    deleteUpdatedPosition: (id: string) => void,
    positionsCounts: number
}

interface CreateBasketDrawerSlice {
    basketName: string,
    basketItems: CreateBasketItem[],
    addBasketItem: (item: CreateBasketItem) => void,
    deleteBasketItem: (id: string) => void
    setBasketName: (name: string) => void
}

interface UserSettingsSlice {
    settings: AppSettings | undefined,
    setSettings: (newSettings: AppSettings) => void
}


const createPositionSlice: StateCreator<
    PositionSlice,
    [],
    [],
    PositionSlice
> = (set) => ({
    positions: [],
    updatedPositions: [],
    setUpdatedPositions: (newPositions: UpdatedPosition[]) => set(() => ({ updatedPositions: [...newPositions] })),
    setPositions: (newPositions: Position[]) => set(() => {
        positionsManager.setPositions([...newPositions])
        return { positions: [...newPositions] }
    }
    ),
    deletePosition: (id: string) => set((state) => {
        const newPositions = [...state.positions.filter(item => item.Position !== id)]
        positionsManager.setPositions([...newPositions])
        return { ...state, positions: newPositions }
    }),
    deleteUpdatedPosition: (id: string) => set((state) => {
        return { ...state, updatedPositions: [...state.updatedPositions.filter(item => item.Position !== id)] }
    }),
    positionsCounts: 0
})


const createBasketDrawerSlice: StateCreator<CreateBasketDrawerSlice, [], [], CreateBasketDrawerSlice> = (set) => ({
    basketName: "",
    basketItems: [],
    addBasketItem: (item: CreateBasketItem) => set((state) => ({
        basketItems: [...state.basketItems, item]
    })),
    deleteBasketItem: (id: string) => set((state) => ({
        basketItems: state.basketItems.filter(item => item.id !== id)
    })),
    setBasketName: (name: string) => set(() => ({ basketName: name }))
})


const createUserSettingsSlice: StateCreator<UserSettingsSlice, [], [], UserSettingsSlice> = (set) => ({
    settings: undefined,
    setSettings: (newSettings: AppSettings) => set(() => ({
        settings: newSettings
    }))
})


const useBoundStore = create<PositionSlice & CreateBasketDrawerSlice & UserSettingsSlice>()((...a) => ({
    ...createPositionSlice(...a),
    ...createBasketDrawerSlice(...a),
    ...createUserSettingsSlice(...a)
}))


export default useBoundStore

