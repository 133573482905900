import { Trader } from "@/types";
import { FormEvent, useState } from "react";

export default function ({ trader, onClose, onSubmit }: { trader: Trader, onClose: () => void, onSubmit: (event: FormEvent<HTMLFormElement>) => void }) {

    const [message, setMessage] = useState("")
    return <>
        {/* Put this part before </body> tag */}
        <input type="checkbox" id="my_modal_6" className="modal-toggle" checked />
        <div className="modal">
            <div className="modal-box">
                <form onSubmit={onSubmit}>
                    <input type="hidden" name="traderId" value={trader._id} readOnly />
                    <div className="flex flex-col gap-2 mt-4">
                        <div className="form-control p-2 bg-base-100 rounded-xl">
                            <label className="label cursor-pointer">
                                <span className="label-text">Spam</span>
                                <input type="radio" className="radio checked:radio-primary" name="reason" required value={"SPAM"} />
                            </label>
                        </div>
                        <div className="form-control p-2 bg-base-100 rounded-xl">
                            <label className="label cursor-pointer">
                                <span className="label-text">Fraud</span>
                                <input type="radio" className="radio checked:radio-primary" name="reason" required value={"FRAUD"} />
                            </label>
                        </div>
                        <div className="form-control p-2 bg-base-100 rounded-xl">
                            <label className="label cursor-pointer">
                                <span className="label-text">Violence</span>
                                <input type="radio" className="radio checked:radio-primary" name="reason" required value={"VIOLENCE"} />
                            </label>
                        </div>
                        <div className="form-control p-2 bg-base-100 rounded-xl">
                            <label className="label cursor-pointer">
                                <span className="label-text">Inappropriate</span>
                                <input type="radio" className="radio checked:radio-primary" name="reason" required value={"INAPPROPRIATE"} />
                            </label>
                        </div>
                        <div className="form-control p-2 bg-base-100 rounded-xl">
                            <label className="label cursor-pointer">
                                <span className="label-text">Fake</span>
                                <input type="radio" className="radio checked:radio-primary" name="reason" required value={"FAKE"} />
                            </label>
                        </div>
                        <div className="form-control p-2 bg-base-100 rounded-xl">
                            <label className="label cursor-pointer">
                                <span className="label-text">Other</span>
                                <input type="radio" className="radio checked:radio-primary" name="reason" required value={"OTHER"} />
                            </label>
                        </div>
                    </div>
                    <div className="mt-4"></div>
                    <textarea name="message" className="textarea textarea-bordered w-full bg-base-100" maxLength={300} rows={4} placeholder="Mesaj" onChange={e => setMessage(e.target.value)} required></textarea>
                    <div className="flex justify-end items-center mt-2 text-sm">{message.length}/300</div>
                    <div className="mt-4"></div>
                    <div className="flex mt-2">
                        <button className="btn w-full btn-primary">Şikayet Et</button>
                    </div>
                </form>
            </div>
            <label className="modal-backdrop" htmlFor="my_modal_6" onClick={onClose}>Close</label>
        </div>
    </>
}