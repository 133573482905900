import { PositionHistoryItem } from "@/types"
import ClientPaginatedList from "./ClientPaginatedList"
import { emitEvent, eventConstants } from "../main"


export default function ({ positionHistory }: { positionHistory: PositionHistoryItem[] }) {



    return <div className="flex flex-col gap-4 w-full bg-base-100 rounded-lg  p-0 lg:p-4 ">
        <h2 className="lg:text-xl text-lg">Pozisyon Geçmişi</h2>
        <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">

            <ClientPaginatedList<PositionHistoryItem> items={positionHistory} paginationLength={10} length={positionHistory.length} listComponent={({ item }) => {
                const mode = item.Action === "1" ? "AL" : "SAT"

                return <div onClick={() => {
                    emitEvent(eventConstants.SHOW_POSITION_HISTORY_DETAIL, item)
                }} key={item.PositionID + (Math.random() * 10000)} className="content-container hover:bg-base-200 p-4 rounded-lg flex cursor-pointer">

                    <div className="flex flex-col flex-1 gap-2">
                        <div className="flex gap-3">
                            <span className="font-semibold">{item.Symbol} </span>
                            <span className={"font-semibold " + (mode === "AL" ? "text-success" : "text-error")}>
                                {mode}  {(parseInt(item.Volume) / 10000).toFixed(2)}
                            </span>
                        </div>

                        <div className="flex gap-2 items-center">
                            <span>{parseFloat(item.PricePosition).toLocaleString()}</span>
                            <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                            </span>
                            <span>{parseFloat(item.Price).toLocaleString()}</span>
                        </div>
                    </div>
                    <div className={"flex items-center font-semibold text-lg " + ((parseFloat(item.Profit) + parseFloat(item.Storage)) > 0 ? "text-success" : "text-error")}>
                        {(parseFloat(item.Profit) + parseFloat(item.Storage)).toFixed(2)}$
                    </div>
                </div>
            }} />


        </div>
    </div>
}