import { MarginInitialRecords, PNLData, Position, UpdatedPosition } from "@/types";

class PositionsManager {

    positions: UpdatedPosition[]
    PNLprices: PNLData | null

    constructor() {
        this.PNLprices = null
        this.positions = []
    }

    setPNLPrices(newPNLPrices: PNLData) {
        this.PNLprices = newPNLPrices
    }

    setPositions(newPositions: Position[]) {

        this.positions = newPositions.map(pos => {

            return { ...pos, Swap: 0, oldProfit: 0 }
        })
    }


    updatePosition(positionIndex: number, pnlData: PNLData, profit: number, rate: number, swap: number, oldProfit: number) {

        const position = this.positions[positionIndex]

        position.PriceCurrent = pnlData.priceCurrent.toFixed(parseInt(position.Digits))
        position.PriceOpen = pnlData.price
        position.Profit = profit.toFixed(2)
        position.RateProfit = rate.toString()
        position.Swap = swap
        position.oldProfit = oldProfit
        this.positions[positionIndex] = position

    }

    // Get position 
    calculatePNL(pnlData: PNLData, initial_margins: MarginInitialRecords, marginLeverage: number) {

        const marginLeverageFinal = Number(marginLeverage) <= 0.0 ? 200 : Number(marginLeverage);

        const currentPositionIndex = this.positions.findIndex(position => position.Position === pnlData.id)

        if (currentPositionIndex === -1) {
            return
        }

        const currentPosition = this.positions[currentPositionIndex]

        const symbol = currentPosition?.Symbol

        const entryPrice = parseFloat(currentPosition?.PriceOpen as string)

        const marginInitial = initial_margins[symbol];

        if (!marginInitial) {
            console.log("Margin initial not found", symbol)
            return undefined
        }


        const marginInitialData = { contract_size: marginInitial.contractSize, buy: marginInitial.buy, sell: marginInitial.sell }

        const lot = Number(currentPosition.Volume)

        const contractSize = marginInitialData.contract_size

        const marginSize = currentPosition.Action === "0" ? marginInitialData.buy : marginInitialData.sell

        const oneLotMarginResult = (entryPrice * contractSize) / (marginLeverageFinal / marginSize);

        const userLotMarginResult = oneLotMarginResult * lot;

        const profit = pnlData.profit + pnlData.swap

        const rate = profit / (userLotMarginResult / 100.0);

        return { rate: rate, profit: profit, swap: pnlData.swap, oldProfit: pnlData.profit, currentPositionIndex };
    }
}


export default PositionsManager

export const positionsManager = new PositionsManager()
