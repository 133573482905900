import { atom } from "jotai"
import { AppSettings, BidData, FavouritesList, MarginInitialRecords, NotificationList, OrderList, Position, PositionHistoryItem, Spreads, Symbol, SymbolCategory, SymbolCategoryMap, SymbolChartItem, SymbolList, UpdatedPosition, User } from "./types"

export const SymbolsAtom = atom<null | SymbolList>(null)
export const SymbolCategoriesAtom = atom<null | SymbolCategory[]>(null)
export const SymbolCategoryMapAtom = atom<null | SymbolCategoryMap>(null)
export const MarginsAtom = atom<MarginInitialRecords | null>(null)
export const SymbolChartAtom = atom<SymbolChartItem[] | null>(null)
export const UserAtom = atom<User | null>(null)
export const FavouritesAtom = atom<FavouritesList | null>(null)
export const SelectedSymbolAtom = atom<Symbol | null>(null)
export const UserPositionCountAtom = atom<number>(0)
export const UserPositionHistoryCountAtom = atom<number>(0)

export const UserPositionsAtom = atom<Position[]>([])
export const UserUpdatedPositionsAtom = atom<UpdatedPosition[]>([])
export const UserOrdersAtom = atom<OrderList>([])
export const UserPositionHistoryAtom = atom<PositionHistoryItem[]>([])

export const UserOrderCountAtom = atom<number>(0)
export const NotificationsAtom = atom<NotificationList>([])
export const NotificationCountAtom = atom<number>(0)
export const AppSettingsAtom = atom<AppSettings | null>(null)
export const UserSpreadsAtom = atom<Spreads | null>(null)


class SymbolsManager {
    symbolsArray: SymbolList = []

    constructor() { }

    setArray(items: SymbolList) {
        this.symbolsArray = items
    }

    getArray() {
        return this.symbolsArray
    }


    calculateSpreadDiff(
        bid: BidData,
        spreads: Record<string, {
            name: string;
            Diff: string;
            Balance: string;
        }>,
        symbols: Record<string, string>,
        foundSymbol: Symbol) {

        const getDiff = (num: number, digit: number, type: "ask" | "bid") => {

            const newDigit = digit;
            const newNum = num;

            const isAsk = type === "ask"
            const positiveOrNegative = isAsk ? 1 : -1

            if (newNum % 2 == 0) {
                return (positiveOrNegative) * Math.pow(10.0, -newDigit) * ((newNum) / 2.0)
            }


            return (positiveOrNegative) * (Math.pow(10.0, -newDigit) * (isAsk ? Math.floor(newNum / 2.0) : Math.ceil(newNum) / 2.0))
        }

        const symbolGroup = symbols[bid.symbol];
        if (!symbolGroup) return null

        const spread = spreads[symbolGroup];
        if (spread === undefined) return

        let diff = Number(spread.Diff)


        if (diff == null) return

        if (isNaN(diff) || !isFinite(diff)) {
            diff = 0
        }
        const askDifference = getDiff(diff, foundSymbol.Digits, "ask");
        const bidDifference = getDiff(diff, foundSymbol.Digits, "bid");


        return { askDifference, bidDifference }
    }
}

export const symbolsManagerInstance = new SymbolsManager()
