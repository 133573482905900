import { UserAtom } from "@/atoms"
import { User } from "@/types"
import { EditUserProfile, formatDateToDDMMYYYY } from "@/utils"
import { SetStateAction, useAtom } from "jotai"
import { FormEvent } from "react"
import { emitEvent, eventConstants } from "../main"


export default function Profile() {

    const [user, setUser] = useAtom(UserAtom) as [User, SetStateAction<any>]
    const titles: Record<string, any> = {
        "mail": "Email",
        "tel": "Telefon",
        "sehir": "Şehir",
        "meslek": "Meslek",
        "birthday": "Doğum Tarihi",
        "referenceCode": "Referans Kodu",
        "referenceLink": "Referans Linki",
        "ulke": "Ülke",
        "gelir": "Yıllık Gelir"
    }

    async function handleEditForm(e: FormEvent<HTMLFormElement>) {

        e.preventDefault()
        const data = new FormData(e.target as HTMLFormElement)

        // "bday" : "01.01.1970",
        // "ulke" : "türkiye",
        // "sehir" : "şehir",
        // "meslek" : "test",
        //     "YillikGelir" : "0$-5000$"

        const bday = data.get("bday") as string
        const ulke = data.get("ulke") as string
        const sehir = data.get("sehir") as string
        const meslek = data.get("meslek") as string
        const yillik_gelir = data.get("yillik_gelir") as string


        const [editProfileData, err] = await EditUserProfile({ bday, ulke, sehir, meslek, YillikGelir: yillik_gelir })

        if (err) {

            emitEvent(eventConstants.ALERT_FAIL, {
                type: "FAIL",
                message: err.message
            })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, {
            type: "SUCCESS",
            message: "Profil bilgileriniz güncellendi!"
        })

        setUser(editProfileData)

    }
    return <>
        <form onSubmit={handleEditForm} className="flex flex-col prose max-w-none  gap-4">

            <h1 className="text-xl">Profil</h1>
            <hr />
            <div className="flex flex-col ">
                <span className="opacity-70 flex-1">{titles["mail"]}</span>
                <p>{user.mail}</p>
            </div>

            <div className="flex flex-col ">
                <span className="opacity-70 flex-1">{titles["tel"]}</span>
                <p>{user.tel}</p>
            </div>

            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1">{titles["ulke"]}</span>
                <input type="text" placeholder="Type here" name="ulke" defaultValue={user.ulke} className="input input-bordered input-sm w-full max-w-xs" />
            </div>


            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1">{titles["sehir"]}</span>
                <input type="text" placeholder="Type here" name="sehir" defaultValue={user.sehir} className="input input-bordered input-sm w-full max-w-xs" />
            </div>

            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1">{titles["meslek"]}</span>
                <input type="text" placeholder="Type here" name="meslek" defaultValue={user.meslek} className="input input-bordered input-sm w-full max-w-xs" />

            </div>

            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1">{titles["birthday"]}</span>
                <input type="date" placeholder="Type here" name="bday" defaultValue={formatDateToDDMMYYYY(user.birthday)} className="input input-bordered input-sm w-full max-w-xs" />

            </div>

            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1">{titles["gelir"]}</span>
                <input type="text" placeholder="Type here" name="yillik_gelir" defaultValue={user.YillikGelir} className="input input-bordered input-sm w-full max-w-xs" />
            </div>
            <div className="mt-4">
                <button className="btn btn-sm btn-success text-primary-content">Update</button>
            </div>
        </form>

        <form className="flex flex-col prose max-w-none  gap-4">


            <h1 className="text-xl mt-10">Referans</h1>
            <hr />

            <div className="flex flex-col">
                <span className="opacity-70 flex-1">{titles["referenceCode"]}</span>
                {/* <input type="text" placeholder="Type here" name="ref" defaultValue={user.referenceCode} className="input input-bordered input-sm w-full max-w-xs" /> */}
                <p>{user.referenceCode}</p>
            </div>
            <div className="flex flex-col ">
                <span className="opacity-70 flex-1">{titles["referenceLink"]}</span>
                <p>{user.referenceLink}</p>
            </div>
            {/* <div className="mt-4">
                <button className="btn btn-sm btn-success text-success-content">Update</button>
            </div> */}
        </form>
    </>
}