import { FavouritesList, FunctionCall, MarginInitialRecords, PNLData, Position, PositionHistoryItem, Symbol, SymbolList, Trader } from "@/types"
import { formatDistanceStrict } from "date-fns"
import { tr } from "date-fns/locale"


export function isFavourite(favourites: FavouritesList | null, name: string): boolean {
    if (favourites?.includes(name)) {
        return true
    }
    return false
}


export function getPriceOf(symbols: SymbolList, name: string): Symbol {
    const res = symbols.find(item => item.Symbol === name)
    return res as Symbol
}


export function debounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number
): (...args: Parameters<T>) => void {
    let timeoutId: number;

    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
        const context = this;

        clearTimeout(timeoutId);

        timeoutId = setTimeout(function () {
            func.apply(context, args);
        }, delay);
    };
}


export function downloadImageFromDataUri(dataUri: string, filename: string = "image.png"): void {
    const base64String = dataUri.split(',')[1];

    try {
        const binaryData = atob(base64String);
        const uint8Array = new Uint8Array(binaryData.length);

        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: "image/png" }); // You can change the MIME type as needed

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = filename;

        link.style.display = "none"; // Hide the link
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link); // Remove the link from the DOM
        URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error:", error);
    }
}


export function formatDateToDDMMYYYY(inputDate: Date | string): string {
    const date = new Date(inputDate);

    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}



export function formatToTurkishIBAN(inputString: string): string | null {
    // Remove all non-alphanumeric characters
    const cleanInput: any = inputString.replace(/[^A-Za-z0-9]/g, '');

    // Define the IBAN structure for Turkey (TR)
    const trIBANLength = 26;

    // Extract the country code from the input
    if (cleanInput.length <= trIBANLength || cleanInput === '') {
        // Add spaces to match the Turkish IBAN format
        const formattedIBAN = cleanInput.match(/.{1,4}/g).join(' ');
        return formattedIBAN;
    }


    return null
}


export function groupItemsByDate<T>(items: T[]): { key: string; items: T[] }[] {
    const groupedItems = new Map<string, T[]>();

    items.forEach((item: any) => {
        // Format the date as a string to use it as the key
        const theDate = new Date(item.create_date)
        const dateKey = theDate.toLocaleDateString('tr', { year: 'numeric', month: 'long' });

        if (groupedItems.has(dateKey)) {
            // If the key already exists, push the item into the array
            groupedItems.get(dateKey)!.push(item);
        } else {
            // If the key doesn't exist, create a new array with the item
            groupedItems.set(dateKey, [item]);
        }
    });

    // Convert the Map to the desired format
    const result: { key: string; items: any[] }[] = [];
    groupedItems.forEach((value, key) => {
        result.push({ key, items: value });
    });

    return result;
}


export function hoursAgo(date: string) {

    const theDate = new Date(date)

    return formatDistanceStrict(theDate, new Date(), { locale: tr, addSuffix: true })
}


export function isElementInViewport(element: HTMLElement) {
    var rect = element.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function getDaysInMonth(year: number, month: number): Array<{ dayNumber: number, date: Date, dayName: string, isPreviousMonth: boolean }> {
    const daysArray: Array<{ dayNumber: number, date: Date, dayName: string, isPreviousMonth: boolean }> = [];

    // Create a date object for the first day of the month
    const firstDay = new Date(year, month, 1);

    // Find the day of the week for the first day of the month (0 is Sunday, 1 is Monday, and so on)
    const firstDayOfWeek = firstDay.getDay();

    // Calculate the number of days to include from the previous month
    const daysToIncludeFromPreviousMonth = (firstDayOfWeek + 6) % 7;

    // Calculate the date for the first day to display
    firstDay.setDate(firstDay.getDate() - daysToIncludeFromPreviousMonth);

    // Get the last day of the month
    const lastDay = new Date(year, month + 1, 0);

    // Loop through each day of the month and populate the array
    while (firstDay <= lastDay) {
        const currentDate = new Date(firstDay);
        const dayName = currentDate.toLocaleString('tr', { weekday: 'short' });

        const isPreviousMonth = firstDay.getMonth() !== month;

        daysArray.push({
            dayNumber: firstDay.getDate(),
            date: currentDate,
            dayName: dayName,
            isPreviousMonth: isPreviousMonth,
        });

        firstDay.setDate(firstDay.getDate() + 1);
    }

    return daysArray;
}


export function filterDatesByDayMonthYear(dates: Date[], targetDate: Date): Date[] {
    return dates.filter((date) => {
        return (
            date.getDate() === targetDate.getDate() &&
            date.getMonth() === targetDate.getMonth() &&
            date.getFullYear() === targetDate.getFullYear()
        );
    });
}


export function extractTimeFromDate(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
}


export function convert3LetterTo2Letter(shortcode: string): string | undefined {
    const countryCodes: { [key: string]: string } = {
        'AFG': 'AF',
        'ALA': 'AX',
        'ALB': 'AL',
        'DZA': 'DZ',
        'ASM': 'AS',
        'AND': 'AD',
        'AGO': 'AO',
        'AIA': 'AI',
        'ATA': 'AQ',
        'ATG': 'AG',
        'ARG': 'AR',
        'ARM': 'AM',
        'ABW': 'AW',
        'AUS': 'AU',
        'AUT': 'AT',
        'AZE': 'AZ',
        'BHS': 'BS',
        'BHR': 'BH',
        'BGD': 'BD',
        'BRB': 'BB',
        'BLR': 'BY',
        'BEL': 'BE',
        'BLZ': 'BZ',
        'BEN': 'BJ',
        'BMU': 'BM',
        'BTN': 'BT',
        'BOL': 'BO',
        'BES': 'BQ',
        'BIH': 'BA',
        'BWA': 'BW',
        'BVT': 'BV',
        'BRA': 'BR',
        'IOT': 'IO',
        'BRN': 'BN',
        'BGR': 'BG',
        'BFA': 'BF',
        'BDI': 'BI',
        'KHM': 'KH',
        'CMR': 'CM',
        'CAN': 'CA',
        'CPV': 'CV',
        'CYM': 'KY',
        'CAF': 'CF',
        'TCD': 'TD',
        'CHL': 'CL',
        'CHN': 'CN',
        'CXR': 'CX',
        'CCK': 'CC',
        'COL': 'CO',
        'COM': 'KM',
        'COG': 'CG',
        'COD': 'CD',
        'COK': 'CK',
        'CRI': 'CR',
        'HRV': 'HR',
        'CUB': 'CU',
        'CUW': 'CW',
        'CYP': 'CY',
        'CZE': 'CZ',
        'DNK': 'DK',
        'DJI': 'DJ',
        'DMA': 'DM',
        'DOM': 'DO',
        'ECU': 'EC',
        'EGY': 'EG',
        'SLV': 'SV',
        'GNQ': 'GQ',
        'ERI': 'ER',
        'EST': 'EE',
        'ETH': 'ET',
        'FLK': 'FK',
        'FRO': 'FO',
        'FJI': 'FJ',
        'FIN': 'FI',
        'FRA': 'FR',
        'GUF': 'GF',
        'PYF': 'PF',
        'ATF': 'TF',
        'GAB': 'GA',
        'GMB': 'GM',
        'GEO': 'GE',
        'DEU': 'DE',
        'GHA': 'GH',
        'GIB': 'GI',
        'GRC': 'GR',
        'GRL': 'GL',
        'GRD': 'GD',
        'GLP': 'GP',
        'GUM': 'GU',
        'GTM': 'GT',
        'GGY': 'GG',
        'GIN': 'GN',
        'GNB': 'GW',
        'GUY': 'GY',
        'HTI': 'HT',
        'HMD': 'HM',
        'VAT': 'VA',
        'HND': 'HN',
        'HKG': 'HK',
        'HUN': 'HU',
        'ISL': 'IS',
        'IND': 'IN',
        'IDN': 'ID',
        'IRN': 'IR',
        'IRQ': 'IQ',
        'IRL': 'IE',
        'IMN': 'IM',
        'ISR': 'IL',
        'ITA': 'IT',
        'JAM': 'JM',
        'JPN': 'JP',
        'JEY': 'JE',
        'JOR': 'JO',
        'KAZ': 'KZ',
        'KEN': 'KE',
        'KIR': 'KI',
        'PRK': 'KP',
        'KOR': 'KR',
        'KWT': 'KW',
        'KGZ': 'KG',
        'LAO': 'LA',
        'LVA': 'LV',
        'LBN': 'LB',
        'LSO': 'LS',
        'LBR': 'LR',
        'LBY': 'LY',
        'LIE': 'LI',
        'LTU': 'LT',
        'LUX': 'LU',
        'MAC': 'MO',
        'MKD': 'MK',
        'MDG': 'MG',
        'MWI': 'MW',
        'MYS': 'MY',
        'MDV': 'MV',
        'MLI': 'ML',
        'MLT': 'MT',
        'MHL': 'MH',
        'MTQ': 'MQ',
        'MRT': 'MR',
        'MUS': 'MU',
        'MYT': 'YT',
        'MEX': 'MX',
        'FSM': 'FM',
        'MDA': 'MD',
        'MCO': 'MC',
        'MNG': 'MN',
        'MNE': 'ME',
        'MSR': 'MS',
        'MAR': 'MA',
        'MOZ': 'MZ',
        'MMR': 'MM',
        'NAM': 'NA',
        'NRU': 'NR',
        'NPL': 'NP',
        'NLD': 'NL',
        'NCL': 'NC',
        'NZL': 'NZ',
        'NIC': 'NI',
        'NER': 'NE',
        'NGA': 'NG',
        'NIU': 'NU',
        'NFK': 'NF',
        'MNP': 'MP',
        'NOR': 'NO',
        'OMN': 'OM',
        'PAK': 'PK',
        'PLW': 'PW',
        'PSE': 'PS',
        'PAN': 'PA',
        'PNG': 'PG',
        'PRY': 'PY',
        'PER': 'PE',
        'PHL': 'PH',
        'PCN': 'PN',
        'POL': 'PL',
        'PRT': 'PT',
        'PRI': 'PR',
        'QAT': 'QA',
        'REU': 'RE',
        'ROU': 'RO',
        'RUS': 'RU',
        'RWA': 'RW',
        'BLM': 'BL',
        'SHN': 'SH',
        'KNA': 'KN',
        'LCA': 'LC',
        'MAF': 'MF',
        'SPM': 'PM',
        'VCT': 'VC',
        'WSM': 'WS',
        'SMR': 'SM',
        'STP': 'ST',
        'SAU': 'SA',
        'SEN': 'SN',
        'SRB': 'RS',
        'SYC': 'SC',
        'SLE': 'SL',
        'SGP': 'SG',
        'SXM': 'SX',
        'SVK': 'SK',
        'SVN': 'SI',
        'SLB': 'SB',
        'SOM': 'SO',
        'ZAF': 'ZA',
        'SGS': 'GS',
        'SSD': 'SS',
        'ESP': 'ES',
        'LKA': 'LK',
        'SDN': 'SD',
        'SUR': 'SR',
        'SJM': 'SJ',
        'SWZ': 'SZ',
        'SWE': 'SE',
        'CHE': 'CH',
        'SYR': 'SY',
        'TWN': 'TW',
        'TJK': 'TJ',
        'TZA': 'TZ',
        'THA': 'TH',
        'TLS': 'TL',
        'TGO': 'TG',
        'TKL': 'TK',
        'TON': 'TO',
        'TTO': 'TT',
        'TUN': 'TN',
        'TUR': 'TR',
        'TKM': 'TM',
        'TCA': 'TC',
        'TUV': 'TV',
        'UGA': 'UG',
        'UKR': 'UA',
        'ARE': 'AE',
        'GBR': 'GB',
        'USA': 'US',
        'UMI': 'UM',
        'URY': 'UY',
        'UZB': 'UZ',
        'VUT': 'VU',
        'VEN': 'VE',
        'VNM': 'VN',
        'VGB': 'VG',
        'VIR': 'VI',
        'WLF': 'WF',
        'ESH': 'EH',
        'YEM': 'YE',
        'ZMB': 'ZM',
        'ZWE': 'ZW',
        // Add more mappings as needed
    };

    // Look up the 2-letter code in the mapping
    const result = countryCodes[shortcode];

    return result;
}


export function convertCurrencyToCountry(currencyCode: string): string | undefined {
    const currencyToCountry: { [key: string]: string } = {
        'USD': 'US',  // United States
        'EUR': 'DE',  // Germany (Euro)
        'GBP': 'GB',  // United Kingdom
        'JPY': 'JP',  // Japan
        'CAD': 'CA',  // Canada
        'AUD': 'AU',  // Australia
        'CHF': 'CH',  // Switzerland
        'CNY': 'CN',  // China
        'SEK': 'SE',  // Sweden
        'NOK': 'NO',  // Norway
        'DKK': 'DK',  // Denmark
        'NZD': 'NZ',  // New Zealand
        'SGD': 'SG',  // Singapore
        'HKD': 'HK',  // Hong Kong
        'KRW': 'KR',  // South Korea
        'ZAR': 'ZA',  // South Africa
        'INR': 'IN',  // India
        'BRL': 'BR',  // Brazil
        'RUB': 'RU',  // Russia
        'MXN': 'MX',  // Mexico
        'AED': 'AE',  // United Arab Emirates
        'SAR': 'SA',  // Saudi Arabia
        'QAR': 'QA',  // Qatar
        'THB': 'TH',  // Thailand
        'MYR': 'MY',  // Malaysia
        'IDR': 'ID',  // Indonesia
        'PHP': 'PH',  // Philippines
        'HUF': 'HU',
        "TRY": "TR"
        // Add more mappings as needed
    };

    // Look up the 2-letter country code in the mapping
    const result = currencyToCountry[currencyCode];

    return result;
}


export function limitCharacters(text: string, limit: number): string {
    if (text.length <= limit) {
        return text;
    } else {
        return text.slice(0, limit) + '...';
    }
}

export function formatDateTime(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1.
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export function getQueryParam(key: string): string | null {
    const params = new URLSearchParams(window.location.search);
    return params.get(key);
}


export function getAllQueryParams(url: string): { [key: string]: string } {
    const params = new URLSearchParams(url.split("?")[1]);
    const queryParams: { [key: string]: string } = {};

    params.forEach((value, key) => {
        queryParams[key] = value;
    });

    return queryParams;
}

export function calculateTraderPNL(positions: Position[], data: PNLData, initial_margins: MarginInitialRecords, marginLeverage: number) {

    const marginLeverageFinal = Number(marginLeverage) <= 0.0 ? 200 : Number(marginLeverage);

    const currentPositionIndex = positions.findIndex(position => position.Position === data.id)

    if (currentPositionIndex === -1) {
        return
    }

    const currentPosition = positions[currentPositionIndex]

    const symbol = currentPosition?.Symbol

    const entryPrice = data.priceCurrent

    const marginInitial = initial_margins[symbol];

    if (!marginInitial) {
        console.log("Margin initial not found", symbol)
        return undefined
    }

    const marginInitialData = { contract_size: marginInitial.contractSize, buy: marginInitial.buy, sell: marginInitial.sell }

    const lot = Number(currentPosition.Volume)

    const contractSize = marginInitialData.contract_size

    const marginSize = currentPosition.Action === "1" ? marginInitialData.buy : marginInitialData.sell

    const oneLotMarginResult = (entryPrice * contractSize) / (marginLeverageFinal / marginSize);

    const userLotMarginResult = oneLotMarginResult * lot;

    const profit = data.profit

    const rate = profit / (userLotMarginResult / 100.0);

    return { rate: rate, profit: profit, swap: data.swap, oldProfit: data.profit, currentPositionIndex };
}

export function calculateTraderHistoryPNL(positions: PositionHistoryItem[], data: PNLData, initial_margins: MarginInitialRecords, marginLeverage: number) {

    const marginLeverageFinal = Number(marginLeverage) <= 0.0 ? 200 : Number(marginLeverage);

    const currentPositionIndex = positions.findIndex(position => position.PositionID === data.id)

    if (currentPositionIndex === -1) {
        return
    }

    const currentPosition = positions[currentPositionIndex]

    const symbol = currentPosition?.Symbol

    const entryPrice = data.priceCurrent

    const marginInitial = initial_margins[symbol];

    if (!marginInitial) {
        console.log("Margin initial not found", symbol)
        return undefined
    }

    const marginInitialData = { contract_size: marginInitial.contractSize, buy: marginInitial.buy, sell: marginInitial.sell }

    const lot = Number(currentPosition.Volume)

    const contractSize = marginInitialData.contract_size

    const marginSize = currentPosition.Action === "1" ? marginInitialData.buy : marginInitialData.sell

    const oneLotMarginResult = (entryPrice * contractSize) / (marginLeverageFinal / marginSize);

    const userLotMarginResult = oneLotMarginResult * lot;

    const profit = data.profit

    const rate = profit / (userLotMarginResult / 100.0);

    return { rate: rate, profit: profit, swap: data.swap, oldProfit: data.profit, currentPositionIndex };
}

export async function executeFunctionCalls(calls: FunctionCall[]): Promise<any[]> {
    const promises = calls.map(([func, ...args]) => func(...args));
    return Promise.all(promises);
}


export function getStarRating(item: Trader) {
    const calc = item.star / item.starCount

    if (isNaN(calc)) {
        return "0.0"
    }

    return (calc).toFixed(1)
}