import { FormEvent, useState } from "react"
import { emitEvent, eventConstants } from "../main"
import { createCryptoAddress } from "@/utils"
import QRCodeComponent from "./QRCodeComponent"

export default function () {

    const [loading, setLoading] = useState(false)
    const [address, setAddress] = useState<null | string>(null)

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        setLoading(true)
        const data = new FormData(e.target as HTMLFormElement)

        const network = data.get("network") as string
        const coin = data.get("coin") as string


        const [res, err] = await createCryptoAddress({ network, coin })
        setLoading(false)


        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        setAddress(res)
        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Adres başarıyla oluşturuldu!" })
    }

    return <div className="flex flex-col prose max-w-none  gap-4">
        <h1 className="text-xl">Cüzdan Adresi Yarat</h1>
        <hr />

        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1 text-sm">Ağ</span>
                <select name="network" className="select select-bordered select-sm w-full max-w-xs" required>
                    <option disabled selected>Ağ Seçin</option>
                    <option value={"trc20"}>TRC</option>

                </select>
            </div>

            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1 text-sm">Coin</span>
                <select name="coin" className="select select-bordered select-sm w-full max-w-xs" required>
                    <option disabled selected>Coin Seçin</option>
                    <option value={"usdt"}>USDT</option>

                </select>
            </div>
            <div>
                {address && <QRCodeComponent value={address} />}
                {address && <input type="text" defaultValue={address} className="mt-4 input input-bordered input-sm w-full max-w-xs" readOnly />}
            </div>
            <div className="mt-4">
                <button type="submit" className="btn btn-success btn-sm text-sm text-primary-content">
                    Yarat
                    {loading && <span className="loading loading-spinner"></span>}
                </button>
            </div>
        </form>

    </div>
}