import { NotificationCountAtom, NotificationsAtom } from "@/atoms"
import useNotificationCounter from "@/hooks/useNotificationCounter"
import usePrompt from "@/hooks/usePrompt"
import { Notification, SystemNotification } from "@/types"
import { deleteNotifications, formatDateToDDMMYYYY, getSystemNotifications, getUserNotifications, readNotification } from "@/utils"
import { useAtom, useSetAtom } from "jotai"
import { useEffect, useState } from "react"


export default function () {

    const [tab, setTab] = useState(0)
    const [userNotifications, setUserNotifications] = useAtom(NotificationsAtom)
    const { prompt, openPrompt } = usePrompt({ onAccept: handleDeleteNotifications, description: "Bildirimleri Silmek istediginden emin misin?" })
    const notificationCount = useNotificationCounter()

    const [systemNotifications, setSystemNotifications] = useState<SystemNotification[]>([])
    const setNotificationCount = useSetAtom(NotificationCountAtom)
    const setNotifcation = useSetAtom(NotificationsAtom)

    useEffect(() => {
        getUserNotifications().then(([data, err]) => {

            if (err) {
                return
            }

            setUserNotifications(data)
        })
    }, [])


    function handleDeleteNotifications() {
        deleteNotifications().then(() => {
            setNotifcation([])
        })
    }


    function active(tab: number, current: number) {
        if (tab === current) {
            return "tab tab-bordered tab-active text-primary flex-1"
        }

        return "tab tab-bordered flex-1"
    }

    async function handleReadNotification(item: Notification) {

        if (!item.read) {
            readNotification(item._id).then(([_, err]) => {
                if (err) {
                    return
                }

                setUserNotifications(prev => {
                    const index = prev.findIndex(notification => notification._id === item._id)
                    prev[index].read = true
                    return [...prev]
                })

                setNotificationCount(prev => prev - 1)
            })
        }
    }

    function showTab(tab: number) {

        if (tab === 0) {
            return <div className="flex flex-col gap-2 max-h-[500px] h-full overflow-y-scroll">
                {userNotifications.map(item => {
                    return <div onClick={() => handleReadNotification(item)} className={`p-4 hover:border-primary cursor-pointer flex flex-col gap-2 ${item.read ? "bg-base-100" : "bg-base-200"} border border-base-300 border-opacity-60 rounded-lg`} key={item._id}>
                        <p className="font-semibold">{item.title}</p>
                        <p className="text-xs">{item.description}</p>
                        <div className="flex flex-col items-end w-full ">
                            <p className="text-xs">{formatDateToDDMMYYYY(item.createdDate)}</p>
                        </div>
                    </div>
                })}

                {userNotifications.length === 0 && <div className="flex items-center justify-center h-full py-52">Bildirim Yok</div>}
            </div>
        }

        return <div className="flex flex-col gap-2 max-h-[500px] overflow-y-scroll">
            {systemNotifications.map(item => {
                return <div className="p-4 hover:border-primary cursor-pointer flex flex-col gap-2 bg-base-100 border border-base-300 border-opacity-60 rounded-lg" key={item._id}>
                    <p className="font-semibold">{item.Baslik}</p>
                    <p className="text-sm">{item.Text}</p>
                </div>
            })}

            {systemNotifications.length === 0 && <div className="flex items-center justify-center h-full py-52">Bildirim Yok</div>}

        </div>
    }


    function selectTab(tab: number) {

        if (tab === 0) {
            getUserNotifications().then(([data, err]) => {

                if (err) {
                    return
                }

                setUserNotifications(data)
            })
        } else {
            getSystemNotifications().then(([data, err]) => {

                if (err) {
                    return
                }

                setSystemNotifications(data)
            })
        }

        setTab(tab)
    }
    return <>
        {prompt()}

        <div className="dropdown dropdown-end dropdown-bottom">

            <label tabIndex={0} className="btn btn-circle bg-base-100 shadow-sm m-1">
                <div className="indicator">
                    {notificationCount !== null && <span className="indicator-item badge badge-sm badge-primary">{notificationCount > 99 ? "+99" : notificationCount}</span>}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                    </svg>
                </div>
            </label>
            <div tabIndex={0} className="dropdown-content z-[10] card card-compact w-80 shadow dropdown-content-container">
                <div className="card-body">
                    <div className="card-title text-sm flex justify-between items-center">
                        <p>Bildirimler</p>

                        <button onClick={() => openPrompt()} className="btn btn-circle bg-base-100 btn-sm shadow-sm hover:shadow-none hover:bg-error hover:text-primary-content">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                        </button>

                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="tabs w-full">
                            <a onClick={() => selectTab(0)} className={active(0, tab)}>Normal</a>
                            <a onClick={() => selectTab(1)} className={active(1, tab)}>Sistem</a>
                        </div>

                        {showTab(tab)}
                    </div>
                </div>
            </div>
        </div>
    </>
}