import { OrderList } from "@/types"



export default function ({ orders }: { orders: OrderList }) {


    return <div className="flex flex-col gap-4 w-full bg-base-300 rounded-lg  p-0 lg:p-4 ">
        <h2 className="lg:text-xl text-lg">Emirler</h2>
        <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <div className="max-h-[75vh]">
                <table className="table table-pin-rows ">
                    <thead>
                        <tr className="bg-base-300">
                            <th>Sembol</th>
                            <th>Emir Fiyatı</th>
                            <th>Fiyatı</th>
                            <th>İşlem</th>
                        </tr>
                    </thead>
                    <tbody className="h-[1px]">
                        {orders.map(item => {
                            return <tr key={item.Order}>
                                <td>{item.Symbol}</td>
                                <th>{item.PriceOrder}  </th>
                                <th>{item.PriceCurrent} </th>
                                <th>{item.Type === "2" ? "AL" : "SAT"}</th>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}