import { InputHTMLAttributes, useEffect, useRef } from "react";


function normalizeValue(originalValue: number, minValue: number, maxValue: number) {
    return ((originalValue - minValue) / (maxValue - minValue)) * 100;
}


export default function RangeInput({ ...props }: InputHTMLAttributes<HTMLInputElement>) {

    const rangeRef = useRef<null | HTMLInputElement>(null)

    useEffect(() => {

        if (props.value && parseFloat(props.value as string) < (props.min as number)) {
            props.value = (props.min as number).toString()
        }
        progressScript2(props.value as string)
    }, [props.value])

    function progressScript2(val: string) {

        if (!rangeRef.current) return
        const sliderValue = val;
        const normalizedValue = normalizeValue(parseFloat(sliderValue), props.min as number, props.max as number)
        rangeRef.current.style.background = `linear-gradient(to right, purple, ${normalizedValue}%, hsl(var(--b2)) ${normalizedValue}%)`;
    }

    return <input ref={rangeRef} type="range" {...props} className="range-input" />

}