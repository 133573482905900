import Error from "../error"
import { AppSettings, BonusHistoryItem, BulletinList, DepositHistoryItem, FavouritesList, IdentityStatus, Result, Spreads, User, WithdrawHistoryItem } from "../types";
import http from "./http"



export async function getUser(): Promise<Result<User, Error>> {

    try {
        const res = await http.get("user/info")

        if (!res.data.status) {
            throw new Error("Error while getting user info")
        }

        const data: User = res.data.data;

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting user info", { cause: err })]
    }
}

export async function getDepositHistory(): Promise<Result<DepositHistoryItem[], Error>> {

    try {
        const res = await http.get("acc/history/deposit?m=1")

        const data: DepositHistoryItem[] = res.data.data;

        return [data, null]

    } catch (err) {
        return [null, new Error("Error while getting deposit history", { cause: err })]
    }
}

export async function getWithdrawHistory(): Promise<Result<WithdrawHistoryItem[], Error>> {

    try {
        const res = await http.get("acc/history/withdraw?m=1")

        const data: WithdrawHistoryItem[] = res.data.data;

        return [data, null]

    } catch (err) {
        return [null, new Error("Error while getting withdraw history", { cause: err })]
    }
}

export async function getBonusHistory(): Promise<Result<BonusHistoryItem[], Error>> {

    try {
        const res = await http.get("user/credits")

        const data: BonusHistoryItem[] = res.data.data;

        return [data, null]

    } catch (err) {
        return [null, new Error("Error while getting bonus history", { cause: err })]
    }
}


export async function getIdentityStatus(): Promise<Result<IdentityStatus, Error>> {

    try {
        const res = await http.get("acc/IdentiyVerify")

        if (!res.data.status) {
            throw new Error("Error while getting identity status")
        }

        const data: IdentityStatus = res.data;

        return [data, null]

    } catch (err) {
        return [null, new Error("Error while getting identity status", { cause: err })]
    }
}


export async function getFavouriteList(): Promise<Result<FavouritesList, Error>> {

    try {
        const res = await http.get("user/FavList")

        if (!res.data.status) {
            throw new Error("Error while getting favourites list")
        }

        const data: FavouritesList = res.data.fav;

        return [data, null]

    } catch (err) {
        return [null, new Error("Error while getting identity status", { cause: err })]
    }

}


export async function getUserSpreads(): Promise<Result<Spreads, Error>> {

    try {
        const res = await http.get("user/spreads")

        const data: Spreads = {
            group_spreads: res.data.data.groupSpreads,
            group_symbols: res.data.data.groupSymbols
        };

        return [data, null]

    } catch (err) {
        return [null, new Error("Error while getting identity status", { cause: err })]
    }

}


export async function getUserBulletinList(): Promise<Result<BulletinList, Error>> {

    try {
        const res = await http.get("user/bulletin")

        const data: BulletinList = res.data.data

        return [data, null]

    } catch (err) {

        return [null, new Error("Error while getting bulletin list", { cause: err })]
    }
}



export async function EditUserProfile(opt: {
    bday: string,
    ulke: string,
    sehir: string,
    meslek: string,
    YillikGelir: string
}): Promise<Result<any, Error>> {


    try {
        const res = await http.post("user/edit", opt)
        const data: BulletinList = res.data.data
        return [data, null]

    } catch (err) {

        return [null, new Error("Error while getting bulletin list", { cause: err })]
    }

}

export async function createReference(code: string): Promise<Result<any, Error>> {

    try {
        const res = await http.post("user/edit", { reference: code })
        const data: any = res.data.data
        return [data, null]

    } catch (err) {

        return [null, new Error("Error while getting bulletin list", { cause: err })]
    }
}


export async function uploadIdentity(opt: { docType: string, file: File }): Promise<Result<any, Error>> {

    try {
        const formData = new FormData()
        formData.append("file", opt.file)

        const res = await http.post("acc/documentUpload/" + opt.docType, formData)
        const data: any = res.data.data
        return [data, null]

    } catch (err) {

        return [null, new Error("Error while getting bulletin list", { cause: err })]
    }

}

export async function changePassword(opt: { oldPassword: string, newPassword: string }): Promise<Result<any, Error>> {

    try {

        const res = await http.post("acc/passchange?m=1", { epass: opt.oldPassword, npass: opt.newPassword })
        const data: any = res.data.data
        return [data, null]

    } catch (err) {

        return [null, new Error("Error while getting bulletin list", { cause: err })]
    }

}

export async function createCryptoAddress(opt: { network: string, coin: string }): Promise<Result<string, Error>> {


    try {
        const res = await http.post("acc/adres", { ag: opt.network, coin: opt.coin })

        if (!res.data.status) {
            throw new Error("Error while getting favourites list")
        }

        const data: string = res.data.adres

        return [data, null]

    } catch (err) {
        return [null, new Error("Error while getting bulletin list", { cause: err })]

    }
}


export async function editUserTraderProfile(formData: FormData): Promise<Result<any, Error>> {
    try {

        const res = await http.post("trader/edit", formData, {
            headers: {
                "Content-Type": "multipart/form-data", // Set the correct content type for form data
            },
        })
        const data: any = res.data

        return [data, null]
    } catch (error) {
        return [null, new Error("Error while updating trader profile")]
    }
}

export async function createUserTraderProfile(formData: FormData): Promise<Result<any, Error>> {
    try {

        const res = await http.post("trader/apply", formData, {
            headers: {
                "Content-Type": "multipart/form-data", // Set the correct content type for form data
            },
        })
        const data: any = res.data

        return [data, null]
    } catch (error) {
        return [null, new Error("Error while creating trader profile")]
    }
}

export async function deleteUserTraderProfile(): Promise<Result<any, Error>> {
    try {
        const res = await http.post("trader/exit?m=1")
        const data: any = res.data.data
        return [data, null]
    } catch (error) {
        return [null, new Error("Error while deleting trader profile")]
    }
}

export async function getAppSettings(): Promise<Result<AppSettings, Error>> {

    try {
        const res = await http.get("api/app_settings")
        const data = res.data.data
        return [data, null]
    } catch (error) {
        return [null, new Error("Error while getting user settings")]

    }
}


export async function getUserSettings(): Promise<Result<AppSettings, Error>> {

    try {
        const res = await http.get("user/settings")
        const data = res.data.data
        return [data, null]
    } catch (error) {
        return [null, new Error("Error while getting user settings")]
    }
}