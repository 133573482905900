import { Banner } from "@/types";

// @ts-ignore
import Slider from "react-slick";

export default function ({ banners }: { banners: Banner[] }) {


    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false
    };

    return (
        <Slider {...settings}>
            {banners.map(item => {
                return <div className="overflow-hidden rounded-lg p-6 pb-0" key={item._id}>
                    <img className="rounded-lg" src={item.img} alt="" />
                </div>
            })}
        </Slider>
    );
}