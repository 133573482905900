import { TraderInfo } from "@/types";

export default function ({ traderInfo }: { traderInfo: TraderInfo }) {

    return <div className="lg:hidden flex flex-col gap-3 content-container rounded-lg p-4 ">
        <div className="flex justify-between items-center">
            <span className="text-sm">Toplam İşlem:</span>
            <span className="font-bold">{traderInfo.totalCount}</span>
        </div>
        <div className="flex justify-between items-center">
            <span className="text-sm">Karlı İşlem:</span>
            <span className="font-bold">{traderInfo.positiveCount}</span>
        </div>
        <div className="flex justify-between items-center">
            <span className="text-sm">Zararlı İşlem:</span>
            <span className="font-bold">{traderInfo.negativeCount}</span>
        </div>
        <div className="flex justify-between items-center">
            <span className="text-sm">Ortalama ROI:</span>
            <span className="font-bold">{traderInfo.roiAverage.toFixed(2)}%</span>
        </div>
    </div>
}