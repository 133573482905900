import { changePassword } from "@/utils"
import { FormEvent, useState } from "react"
import { emitEvent, eventConstants } from "../main"

export default function () {

    const [loading, setLoading] = useState(false)

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        setLoading(true)
        const data = new FormData(e.target as HTMLFormElement)

        const oldPassword = data.get("oldPassword") as string
        const newPassword = data.get("newPassword") as string
        const confirmPassword = data.get("confirmPassword")

        if (newPassword !== confirmPassword) {
            setLoading(false)
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Lütfen yeni şifrenizi doğru giriniz." })
            return
        }

        const [_, err] = await changePassword({ oldPassword, newPassword })
        setLoading(false)


        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }


        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Şifre başarıyla güncellendi" })
        window.location.reload()

    }

    return <div className="flex flex-col prose max-w-none  gap-4">
        <h1 className="text-xl">Şifre Değiştir</h1>
        <hr />
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">

            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1 text-sm">Eski Şifre</span>
                <input type="password" name="oldPassword" className="input input-bordered input-sm w-full max-w-xs" />
            </div>

            <hr className="opacity-10 mt-4" />
            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1 text-sm">Yeni Şifre</span>
                <input type="password" name="newPassword" className="input input-bordered input-sm w-full max-w-xs" />
            </div>
            <div className="flex flex-col gap-2 ">
                <span className="opacity-70 flex-1 text-sm">Yeni Şifre Tekrar</span>
                <input type="password" name="confirmPassword" className="input input-bordered input-sm w-full max-w-xs" />
            </div>

            <div className="mt-4">
                <button type="submit" className="btn btn-success btn-sm text-sm text-primary-content">
                    Değiştir
                    {loading && <span className="loading loading-spinner"></span>}
                </button>
            </div>
        </form>
    </div>
}