import { Position } from "@/types";
import ClientPaginatedList from "./ClientPaginatedList";

export default function ({ positions }: { positions: Position[] }) {


    return <>

        <ClientPaginatedList<Position> items={positions} length={positions.length} listComponent={({ item }) => {
            return <div key={item.Position} className="content-container p-4 rounded-lg flex">

                <div className="flex flex-col flex-1 gap-2">
                    <div className="flex gap-3">
                        <span className="font-semibold">{item.Symbol} </span>
                        <span className={"font-semibold " + (item.Action === "0" ? "text-success" : "text-error")}>
                            {item.Action === "0" ? "BUY" : "SELL"}  {(parseInt(item.Volume) / 10000).toFixed(2)}
                        </span>
                    </div>

                    <div className="flex gap-2 items-center">
                        <span>{parseFloat(item.PriceOpen).toLocaleString()}</span>
                        <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                        </span>
                        <span>{parseFloat(item.PriceCurrent).toLocaleString()}</span>
                    </div>
                </div>
                <div className={"flex items-center font-semibold text-lg " + (parseFloat(item.Profit) > 0 ? "text-success" : "text-error")}>
                    {(parseFloat(item.RateProfit) * 10000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                </div>
            </div>
        }} />
    </>
}