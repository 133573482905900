import { FavouritesAtom, SelectedSymbolAtom, SymbolCategoriesAtom, SymbolsAtom } from "@/atoms"
import { Symbol, SymbolCategory, SymbolList } from "@/types"
import { getPriceOf, isFavourite, setFavouriteSymbol, unsetFavouriteSymbol } from "@/utils"
import { useAtom, useAtomValue } from "jotai"
import { FormEvent, useState } from "react"
import { emitEvent, eventConstants } from "../main"
import SymbolListItem from "./ui/SymbolListItem"

function findSymbolsOfCategory(symbols: SymbolList, category: SymbolCategory) {
    const newArr: SymbolList = []
    symbols.forEach(item => {
        if (item.categories[0] === category._id)
            newArr.push(item)

    })
    return newArr
}

export default function () {

    const symbolCategories = useAtomValue(SymbolCategoriesAtom)
    const symbols = useAtomValue(SymbolsAtom)
    const [favourites, setFavouritesAtom] = useAtom(FavouritesAtom)
    const [filters, setFilters] = useState<{ category: null | SymbolCategory, search: null | string, favourite: boolean }>({ category: null, search: null, favourite: false })
    const [visibleSymbols, setVisibleSymbols] = useState<SymbolList>(symbols as SymbolList)
    const selectedSymbol = useAtomValue(SelectedSymbolAtom)


    function selectCategory(category: SymbolCategory) {
        if (filters.category?.name === category.name) {
            return filter(filters.search, null, filters.favourite)
        }

        filter(filters.search, category, filters.favourite)
    }

    function searchSymbols(e: FormEvent) {
        e.preventDefault()

        const formData = new FormData(e.target as HTMLFormElement)
        const term = formData.get("term")

        filter(term as string, filters.category, filters.favourite)
    }

    function searchFavourites() {
        if (filters.favourite) {
            filter(filters.search, filters.category, false)
        } else {
            filter(filters.search, filters.category, true)
        }
    }

    function filter(search: string | null, category: SymbolCategory | null, favourite: boolean) {

        let ogList: SymbolList = symbols as SymbolList

        if (category) {
            ogList = findSymbolsOfCategory(ogList as SymbolList, category)
        }

        if (favourite) {
            ogList = ogList.filter(item => favourites?.includes(item.Symbol))
        }

        ogList = ogList.filter(item => {
            return item.displayName.includes(search ? search.toUpperCase() : "")
        })

        setVisibleSymbols(ogList)
        setFilters({ search: search, category: category, favourite: favourite })
    }

    async function setFavourite(symbol: Symbol) {

        const [res, err] = await setFavouriteSymbol(symbol);

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })

            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Sembol favorilere eklendi!" })
        setFavouritesAtom(res)
    }

    async function unsetFavourite(symbol: Symbol) {
        const [res, err] = await unsetFavouriteSymbol(symbol);

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Sembol favorilerden kaldırıldı!" })
        setFavouritesAtom(res)
    }




    return <div className="flex flex-col lg:flex-1 lg:h-auto h-[100vh] gap-4 w-full content-container rounded-sm p-4 lg:max-w-[450px] ">
        <div className="flex gap-2 w-full">
            <form onSubmit={searchSymbols} className="join items-center rounded flex-1 content-container">
                <input type="text" name="term" placeholder="Sembol Ara" style={{ outline: "none" }} className="input input-bordered w-full join-item border-none" />
                <button className="join-item rounded h-full items-center flex bg-base-100 px-2 hover:text-primary cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </button>
            </form>
            <button onClick={searchFavourites} className={`h-full items-center flex bg-base-100 px-2 hover:text-primary cursor-pointer rounded ${filters.favourite ? "text-primary" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>
            </button>
        </div>

        <div className="flex items-center flex-wrap gap-1">
            {symbolCategories?.map(item => {
                return <span key={item._id} className={`badge p-3 hover:bg-primary hover:text-primary-content cursor-pointer ${filters.category?.name === item.name ? "bg-primary text-primary-content" : ""}`} onClick={() => selectCategory(item)}>{item.name}</span>
            })}
        </div>
        <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <div className="h-1">
                <table className="table table-pin-rows ">
                    <thead>
                        <tr className={"bg-base-100"}>
                            <th className="lg:px-4 px-0">Name</th>
                            <th className="lg:px-4 px-0">Alış</th>
                            <th className="lg:px-4 px-0">Satış</th>
                            <th className="lg:px-4 px-0"></th>
                        </tr>
                    </thead>
                    <tbody className="h-[1px]">
                        {visibleSymbols.map(item => {

                            return <SymbolListItem key={item.Symbol}
                                item={item}
                                selectedSymbol={selectedSymbol}
                                isFavourite={isFavourite(favourites, item.Symbol)}
                                askPrice={getPriceOf(symbols as SymbolList, item.Symbol).Ask}
                                bidPrice={getPriceOf(symbols as SymbolList, item.Symbol).Bid}
                                onSetFavourite={(item) => setFavourite(item)}
                                onUnsetFavourite={(item) => unsetFavourite(item)}
                            />
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="flex">
            <button className="btn btn-success w-full text-primary-content" onClick={() => emitEvent(eventConstants.SET_SYMBOL_SETTINGS, selectedSymbol?.Symbol)}>Al/Sat</button>
        </div>
    </div>
}