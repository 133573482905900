import { getWithdrawHistory } from "@/utils";
import { LoaderFunctionArgs } from "react-router-dom";
import { progress } from "../../main";


export default function ({ }: LoaderFunctionArgs) {

    return new Promise(async (res, _) => {
        progress.start()
        const [data, err] = await getWithdrawHistory()

        if (err) {
            return res(err)
        }

        progress.done()

        return res({ history: data })
    })
}