import { useSetAtom } from "jotai";
import { useEffect, useLayoutEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { UserAtom } from "../lib/atoms";
import { getUser } from "@/utils";
import PanelHeader from "../components/PanelHeader";
import Alerts from "../components/Alerts";
import { eventConstants, onEvent } from "../main";
import NotificationPermissionComponent from "../components/NotificationPermissionComponent";

import ModalsContainer from "../components/ModalsContainer";
import BasketDrawerContainer from "../components/BasketDrawerContainer";
import DrawerController from "../components/DrawerController";



export default function () {

    const setUser = useSetAtom(UserAtom)
    const panelRef = useRef<HTMLDivElement | null>(null)
    const { pathname } = useLocation()

    useEffect(() => {

        const userProfileEvent = onEvent(eventConstants.RE_FETCH_POSITIONS, async () => {

            const [userInfo, userInfoError] = await getUser()

            if (!userInfoError) {
                localStorage.setItem("user", JSON.stringify(userInfo))
                setUser(userInfo)
            } else {
                return
            }

        })

        return () => {
            userProfileEvent.unsubscribe()
        }

    }, [])

    useLayoutEffect(() => {
        if (panelRef.current) {
            panelRef.current.scrollTo(0, 0)
        }
    }, [pathname])


    return <>
        <PanelHeader />
        <Alerts />
        <BasketDrawerContainer />
        <DrawerController />
        <ModalsContainer />
        <div ref={panelRef} className="w-screen bg-base-100 panel-container-height overflow-y-auto">
            <Outlet />
        </div>
        <NotificationPermissionComponent />
    </>
}




