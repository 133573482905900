import { getIdentityStatus, uploadIdentity } from "@/utils"
import { useEffect, useState } from "react"
import IdentityForm from "./IdentityForm"

type Status = "SUCCESS" | "WARNING" | "ERROR"
export default function () {

    const [loading, setLoading] = useState(true)
    const [_, setStatus] = useState(false)
    const [verified, setVerified] = useState<Status>("ERROR")

    useEffect(() => {
        getIdentityStatus().then(([data, err]) => {
            setLoading(false)
            if (err) {
                return
            }

            setStatus(data.status)

            if (data.KimlikVerify === 1) {
                setVerified("SUCCESS")
            }

            if (data.KimlikVerify === 2) {
                setVerified("WARNING")
            }

            if (data.KimlikVerify !== 1 && data.KimlikVerify !== 2) {
                setVerified("ERROR")
            }

        })
    }, [])

    function renderText(status: Status) {

        if (status === "SUCCESS") {
            return <div className="mt-2">
                <p className="font-bold text-success">Onaylandı</p>
                <p>Kimliğiniz doğrulandı. Hizmetimizi tüm özellikleriyle kullanmaya başlayabilirsiniz!</p>
            </div>
        }

        if (status === "WARNING") {
            return <div className="mt-2">
                <p className="font-bold text-warning">İnceleniyor</p>
                <p>Kimliğiniz doğrulanmak üzere tarafımıza ulaştı. Lütfen daha sonra tekrar kontrol ediniz.</p>
            </div>

        }

        return <div className="mt-2">
            <p className="font-bold text-error">Onaylanmadı!</p>
            <p>Regüle edilen bir şirket olarak Kuveyt Menkul, müşterilerini doğrulamak ve müşterilerinin güvenli bir yatırım ortamındaki emniyetini sağlamak için gerekli tedbirleri almak zorundadır.<br /> <br />Kimliğinizi doğrulamak için lütfen belirtilen belgeleri sağlayınız:</p>
        </div>
    }


    function renderStatusContent(status: Status) {



        if (status === "ERROR") {

            return <IdentityForm submit={async (data) => {
                setLoading(true)
                const [_, err] = await uploadIdentity({ docType: data.belge, file: data.file })

                setLoading(false)

                if (err) {
                    return
                }

                setVerified("WARNING")
            }} />
        }

        return <></>

    }
    return <>
        <div className="flex flex-col prose max-w-none  gap-4">
            <h1 className="text-xl">Kimlik Doğrulama</h1>
            <hr />

            <div className="flex flex-col ">
                <span className="opacity-70 flex-1 text-sm">Kimlik Durumu</span>
                {loading ? <span className="loading loading-spinner loading-md"></span> : renderText(verified)}
                {loading ? null : renderStatusContent(verified)}
            </div>
        </div>
    </>
}