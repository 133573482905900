import { useCallback, useState } from "react"

export default function (opt: { description: string, onAccept: () => void, onDecline?: () => void }) {

    const [open, setOpen] = useState(false)


    function openPrompt() {
        setOpen(true)
    }

    function closePrompt() {
        setOpen(false)
    }

    function handleOnAccept() {
        opt.onAccept()
        closePrompt()
    }
    const renderPrompt = useCallback(() => {

        return open && <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" checked={open} id="my_modal_7" className="modal-toggle" />
            <div className="modal fixed w-screen h-screen top-0 left-0 z-[9999]">
                <div className="modal-box">
                    <p className="py-4">{opt.description}</p>
                    <div className="modal-action">
                        <div className="flex items-center gap-4">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn btn-primary btn-sm" onClick={handleOnAccept}>Tamam</button>

                            <button className="btn btn-secondary btn-sm" onClick={() => closePrompt()}>İptal</button>
                        </div>
                    </div>
                </div>
                <label className="modal-backdrop" onClick={() => closePrompt()} htmlFor="my_modal_7">Close</label>
            </div></>

    }, [open])

    return {
        prompt: renderPrompt, openPrompt
    }
}