import { getSuggestions } from "@/utils";
import { LoaderFunctionArgs } from "react-router-dom";
import { progress } from "../../main";


export default function ({ }: LoaderFunctionArgs) {

    return new Promise(async (res, _) => {

        progress.start()
        const [suggestions, suggestionsError] = await getSuggestions()


        if (suggestionsError) {
            return res(false)
        }

        progress.done()
        return res({ suggestions: suggestions })
    })
}