import { getExchangeRate } from "@/utils";
import { useEffect, useState } from "react";


export default function () {

    const [exchangeRate, setExchangeRate] = useState<null | string>(null)

    useEffect(() => {
        getExchangeRate().then(([data, err]) => {
            if (err) {
                return
            }

            setExchangeRate(data)
        })
    }, [])

    return { exchangeRate }

}