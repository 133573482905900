import constants from "@/constants";
import Error from "../error";
import { Result } from "../types";
import http from "./http";



export async function loginEmail(email: string, password: string): Promise<Result<any, Error>> {

    try {
        const res = await http.post("auth/login", { mail: email, pass: password })

        if (res.data.status === false) {
            throw new Error(res.data.msg)
        }
        return [res.data, null]
    } catch (err) {
        return [null, new Error("Invalid Credentials", { cause: err })]
    }
}


export async function loginPhone(phone: string, password: string): Promise<Result<any, Error>> {
    try {
        const res = await http.post("auth/login", { mail: phone, pass: password })

        if (res.data.status === false) {
            throw new Error(res.data.msg)
        }
        return [res.data, null]

    } catch (err) {
        return [null, new Error("Invalid Credentials", { cause: err })]
    }
}

export async function register(credentials = { name: "", mail: "", telefon: "", ulke: "", pass: "", ref: "" }): Promise<Result<any, Error>> {

    try {

        const res = await http.post("auth/register", credentials)
        if (res.data.status === false) {
            throw new Error(res.data.msg)
        }

        return [res.data, null]
    } catch (err) {
        return [null, new Error("Error while signing up", { cause: err })]
    }
}

export async function checkAuth(): Promise<Result<any, Error>> {

    try {
        const res = await http.get("auth/auth")
        if (res.data.status === false) {
            throw new Error(res.data.msg)
        }

        return [res.data, null]
    } catch (err) {
        return [null, new Error("Error while signing up", { cause: err })]
    }
}

export function getUserToken(): string | null {
    return localStorage.getItem(constants.AUTH_STORAGE_KEY)
}