import { CalendarEvent } from "@/types"
import { convertCurrencyToCountry, extractTimeFromDate } from "@/utils"
import CalendarFilters from "./CalendarFilters"

export default function ({ filteredEvents, originalEvents, onFilter, onClose }: { filteredEvents: CalendarEvent[], originalEvents: CalendarEvent[], onFilter: (list: CalendarEvent[]) => void, onClose: () => void }) {

    return <>
        {/* Put this part before </body> tag */}
        <input type="checkbox" id="my_modal_7" defaultChecked className="modal-toggle" />
        <div className="modal">
            <div className="modal-box max-w-[900px] min-h-[350px] h-full w-full flex-col flex">
                <CalendarFilters onFilter={(list) => {
                    onFilter(list.events)
                }} defaultValues={originalEvents} />
                <div className="my-4"></div>
                <div className="flex flex-col gap-2 overflow-y-auto h-[1]">
                    {filteredEvents.map((event, index) => {
                        return <div key={index} className="p-4 bg-base-300 border border-zinc-600 flex flex-col rounded-lg">
                            <div className="flex justify-between items-center">
                                <div className="flex gap-2 items-center">
                                    {event.country ? <img src={`https://flagsapi.com/${convertCurrencyToCountry(event.country)}/flat/32.png`} /> : ""}

                                    <span>{event.country ? event.country : "Tüm Gün"}</span>
                                    {event.importance > -1 ? <div className="rating rating-xs">
                                        {[1, 2, 3].map(level => {
                                            return <input type="radio" className="mask mask-star bg-orange-400" checked={level === event.importance} readOnly />
                                        })}
                                    </div> : null}
                                </div>

                                <span>{extractTimeFromDate(new Date(event.date))}</span>
                            </div>

                            <span className="mt-3">
                                {event.type}
                            </span>
                            {event.previous.length > 0 && <div className="grid grid-cols-3 gap-x-4 mt-3">

                                <div className="flex flex-col bg-base-200 p-4 text-center">
                                    <p>
                                        Önceki
                                    </p>
                                    <p className="font-bold text-error">
                                        {event.previous.length > 0 ? event.previous : "-"}
                                    </p>
                                </div>

                                <div className="flex flex-col bg-base-200 p-4 text-center">
                                    <p>
                                        Tahmin
                                    </p>
                                    <p>
                                        {event.estimate.length > 0 ? event.estimate : "-"}
                                    </p>
                                </div>

                                <div className="flex flex-col bg-base-200 p-4 text-center">
                                    <p>
                                        Açıklanan
                                    </p>
                                    <p>
                                        {event.actual.length > 0 ? event.actual : "-"}
                                    </p>
                                </div>
                            </div>}

                        </div>
                    })}
                </div>

            </div>
            <label className="modal-backdrop" htmlFor="my_modal_7" onClick={onClose}>Close</label>
        </div>
    </>
}