import { MarginsAtom, UserAtom } from "@/atoms";
import { Position } from "@/types";
import { calculateTraderPNL } from "@/utils";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

export default function (positions: Position[]) {
    const margins = useAtomValue(MarginsAtom)
    const user = useAtomValue(UserAtom)

    const result: Position[] = useMemo(() => {

        if (typeof positions === undefined) {
            return []
        }

        if (positions.length === 0) {
            return positions
        }

        if (!margins || !user) {
            console.log("NO USER OR MARGINS")
            return positions
        }

        return positions.map(item => {

            const calRes = calculateTraderPNL(positions, {
                id: item.Position,
                price: item.PriceOpen,
                priceCurrent: parseFloat(item.PriceCurrent),
                profit: parseFloat(item.Profit) + parseFloat(item.Storage),
                swap: parseFloat(item.Storage)
            }, margins, parseFloat(user.MarginLeverage))

            if (!calRes) {
                return item
            }

            return {
                ...item,
                PriceCurrent: item.PriceCurrent,
                PriceOpen: item.PriceOpen,
                Profit: calRes?.profit.toFixed(2),
                RateProfit: calRes?.rate.toString()
            }
        })

    }, [positions])


    return result
}