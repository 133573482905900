import Error from "@/error";
import { Bulletin, Result } from "@/types";
import http from "./http";

export async function getBulletins(): Promise<Result<Bulletin[], Error>> {


    try {
        const res = await http.get("user/bullettin")

        const data: Bulletin[] = res.data.data

        return [data, null];

    } catch (error) {
        return [null, new Error("Error getting bulletin list", { cause: error })]
    }
}
