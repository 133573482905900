import Error from "@/error";
import http from "./http";
import { CommentsResult, FollowingOptions, MongoId, Result, RobotList, Trader, TraderInfo, TraderList, TradersResult } from "@/types";

export async function getTraderList(opt: { page: number, search?: string, sort?: string, filter?: string } = { page: 1 }): Promise<Result<TradersResult, Error>> {
    try {
        const res = await http.get("trader/list", { params: { ...opt } })
        const data: TradersResult = res.data.data

        return [data, null]
    } catch (error) {
        return [null, new Error("Error while fetching traders list", { cause: error })]
    }
}

export async function getRobotList(opt = { page: 1 }): Promise<Result<RobotList, Error>> {
    try {
        const res = await http.get("trader/robot", { params: { page: opt.page } })
        const data: RobotList = res.data.data

        return [data, null]
    } catch (error) {
        return [null, new Error("Error while fetching robots list", { cause: error })]
    }
}

export async function getTopTrapders(): Promise<Result<TraderList, Error>> {
    try {
        const res = await http.get("trader/top_traders")
        const data: TraderList = res.data.data
        return [data, null]
    } catch (error) {
        return [null, new Error("Error while fetching top traders list", { cause: error })]
    }
}


export async function getBlockedTraders(): Promise<Result<TraderList, Error>> {

    try {
        const res = await http.get("trader/blocked_traders?m=1")
        const data: TraderList = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting blocked traders list")]
    }
}

export async function getBlockedFollowers(): Promise<Result<{
    isBlocked: boolean,
    name: string,
    _id: MongoId
}[], Error>> {
    try {
        const res = await http.get("trader/blocked_followers?m=1")
        const data: {
            isBlocked: boolean,
            name: string,
            _id: MongoId
        }[] = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting blocked followers")]
    }
}

export async function getTrader(opt: { traderId: null | string } = { traderId: null }): Promise<Result<Trader, Error>> {

    try {
        const res = await http.get("trader/trader", { params: { traderId: opt.traderId } })
        const data: Trader = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting trader", { cause: err })]
    }
}

export async function getTraderInfo(opt: { MetaUser: string | null } = { MetaUser: null }): Promise<Result<TraderInfo, Error>> {
    try {
        const res = await http.get("trader/info", { params: { MetaUser: opt.MetaUser } })

        const data: TraderInfo = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting trader info", { cause: err })]
    }
}

export async function getTraderFollowingOptions(traderId: string): Promise<Result<FollowingOptions, Error>> {
    try {
        const res = await http.get("trader/following_options", { params: { traderId } })
        const data: FollowingOptions = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting trader following options", { cause: err })]
    }
}

export async function followTrader(opt: { traderId: string, options: string, lotPercentage: number }): Promise<Result<Trader, Error>> {

    try {
        const res = await http.post("trader/follow", opt)
        const data: Trader = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while following trader", { cause: err })]
    }
}

export async function editTraderRequest(opt: { traderId: string, options: string, lotPercentage: number }): Promise<Result<Trader, Error>> {

    try {
        const res = await http.post("trader/edit", opt)
        const data: Trader = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while following trader", { cause: err })]
    }
}

export async function unfollowTraderRequest(opt: { traderId: string }): Promise<Result<Trader, Error>> {

    try {
        const res = await http.post("trader/unfollow", opt)
        const data: Trader = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while unfollowing trader", { cause: err })]
    }
}


export async function blockTrader(traderId: string): Promise<Result<any, Error>> {
    try {
        await http.post("trader/block", { traderId })

        return [{}, null]
    } catch (err) {
        return [null, new Error("Error while blocking trader", { cause: err })]
    }
}


export async function unblockTrader(traderId: string): Promise<Result<any, Error>> {
    try {
        await http.post("trader/unblock", { traderId })

        return [{}, null]
    } catch (err) {
        return [null, new Error("Error while unblocking trader", { cause: err })]
    }
}

export async function reportTraderRequest(opt: {
    "traderId": string,
    "reason": string,
    "message": string,
}): Promise<Result<any, Error>> {

    try {
        await http.post("trader/report_trader", opt)
        return [{}, null]
    } catch (err) {
        return [null, new Error("Error while reporting trader", { cause: err })]
    }
}



export async function filterTradersRequest(opt: { page: number, search: string, sort: string, filter: string }): Promise<Result<TradersResult, Error>> {
    try {
        const res = await http.get("trader/list", { params: { ...opt } })
        const data: TradersResult = res.data.data
        return [data, null]
    } catch (err) {
        return [null, new Error("Error while filtering traders", { cause: err })]
    }
}


export async function getTraderPositions(opt: { MetaUser: string | null } = { MetaUser: null }): Promise<Result<any, Error>> {

    try {
        const res = await http.get("trader/positions", { params: opt })
        if (!res.data.status) {
            throw new Error("Error while getting trader positions")
        }
        const data: any = res.data.data

        return [data, null]

    } catch (error) {

        return [null, new Error("Error while getting trader positions")]
    }

}

export async function getTraderOrders(opt: { MetaUser: string | null } = { MetaUser: null }): Promise<Result<any, Error>> {

    try {
        const res = await http.get("trader/orders", { params: opt })
        if (!res.data.status) {
            throw new Error("Error while getting trader orders")
        }
        const data: any = res.data.data

        return [data, null]

    } catch (error) {

        return [null, new Error("Error while getting trader orders")]
    }

}

export async function getTraderPositionHistory(opt: { MetaUser: string | null } = { MetaUser: null }): Promise<Result<any, Error>> {

    try {
        const res = await http.get("trader/trades", { params: opt })
        if (!res.data.status) {
            throw new Error("Error while getting trader position history")
        }
        const data: any = res.data.data

        return [data, null]

    } catch (error) {

        return [null, new Error("Error while getting trader position history")]
    }

}

export async function getTraderComments(opt: { page: number, traderId: string | null } = { page: 1, traderId: null }): Promise<Result<CommentsResult, Error>> {

    try {
        const res = await http.get("trader/comment", { params: opt })
        const data: CommentsResult = res.data.data

        return [data, null]

    } catch (error) {

        return [null, new Error("Error while getting trader comments")]
    }

}

export async function postComment(opt: { traderId: string, message: string, star: number, hideName: boolean }): Promise<Result<any, Error>> {

    try {
        const res = await http.post("trader/comment", opt)
        const data: any = res.data.data

        return [data, null]

    } catch (error) {

        return [null, new Error("Error while getting trader comments")]
    }

}

export async function reportCommentRequest(opt: {
    "commentId": string,
    "reason": string,
    "message": string,
}): Promise<Result<any, Error>> {

    try {
        await http.post("trader/report_comment", opt)
        return [{}, null]
    } catch (err) {
        return [null, new Error("Error while reporting comment", { cause: err })]
    }
}

