import { messaging } from "@/utils";
import { useEffect, useState } from "react";
import { emitEvent, eventConstants } from "../main";
import { getToken, onMessage } from "firebase/messaging";

export default function () {

    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(true)

    function handlePermissionRequest() {
        setShow(false)
        getToken(messaging).then((tokenVal) => {
            emitEvent(eventConstants.SET_FIREBASE_TOKEN, tokenVal)
        }).catch(err => {
            console.log("MESSAGING ERROR", err)
        })
        // requestPermission().then((token: string) => {
        //     console.log("FIREBASE MESSAGING TOKEN", token)
        //     emitEvent(eventConstants.SET_FIREBASE_TOKEN, token)
        // }).catch(err => {
        //     console.log("ERROR REQUESTING",
        //         Notification.permission
        //     )
        // })
    }

    useEffect(() => {

        if (!loading) return

        if (Notification.permission === "denied") {
            setShow(false)
            setLoading(false)

            return
        }

        if (Notification.permission !== "granted") {
            setShow(true)
            setLoading(false)
        }

        if (Notification.permission === "granted") {
            getToken(messaging).then((tokenVal) => {
                emitEvent(eventConstants.SET_FIREBASE_TOKEN, tokenVal)
                setLoading(false)
            }).catch(err => {
                console.log("MESSAGING ERROR", err)
                setLoading(false)
            })
        }

    }, [])

    useEffect(() => {

        const messageEvent = onMessage(messaging, (payload) => {
            console.log(payload);
        });

        return () => {
            messageEvent()
        }

    }, [loading, messaging])

    return <>{show && <div className="fixed bottom-0 right-0 z-[9998] p-10">
        <div role="alert" className="alert max-w-[600px] w-full">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <span>Son gelişmelerden haberder olmak için bildirimlere izin verin!</span>
            <div>
                <button className="btn btn-sm" onClick={() => setShow(false)}>Kapat</button>
                <button className="btn btn-sm btn-success" onClick={handlePermissionRequest}>Kabul Et</button>
            </div>
        </div>
    </div>}
    </>
}