import { CalendarEvent } from "@/types"
import { FormEvent, useRef } from "react"

export default function ({ onFilter, defaultValues }: { onFilter: (list: { events: CalendarEvent[] }, filters: { search: string, sort: string, filter: string }) => void, defaultValues: CalendarEvent[] }) {

    const formRef = useRef<null | HTMLFormElement>(null)

    async function handleFiltre(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const formData = new FormData(e.target as HTMLFormElement)

        const search = formData.get("search") as string
        const sort = formData.get("sort") as string
        const filter = formData.get("filter") as string

        const searchFilteredItems = defaultValues.filter(item => item.type.toUpperCase().includes(search.toUpperCase()))
        const filterItems = searchFilteredItems.filter(item => {
            if (filter === "DEFAULT") {
                return true
            }

            if (filter === "UNIMPORTANT") {
                return item.importance === -1
            }

            if (filter === "LEVEL_1") {
                return item.importance === 1
            }

            if (filter === "LEVEL_2") {
                return item.importance === 2
            }

            if (filter === "LEVEL_3") {
                return item.importance === 3
            }

        })

        const sortItems = filterItems.sort((a, b) => {

            if (sort === "IMPORTANT_ASC") {
                return a.importance - b.importance;

            }

            if (sort === "IMPORTANT_DESC") {
                return b.importance - a.importance;

            }

            if (sort === "COUNTRY_DESC") {
                return b.country.localeCompare(a.country);

            }

            if (sort === "COUNTRY_ASC") {
                return a.country.localeCompare(b.country);

            }

            if (sort === "DATE_DESC") {
                return new Date(b.date).getTime() - new Date(a.date).getTime();

            }

            if (sort === "DATE_ASC") {
                return new Date(a.date).getTime() - new Date(b.date).getTime();

            }

            return 1
        })


        onFilter({ events: sort === "DEFAULT" ? filterItems : sortItems }, { search, sort, filter: "" })
    }


    async function handleClearFunction(e: any) {
        e.preventDefault()
        if (!formRef.current) return
        formRef.current.reset()

        onFilter({ events: defaultValues }, { search: "", sort: "", filter: "" })
    }

    return <div className="flex gap-2 rounded-lg">
        <form ref={formRef} onSubmit={handleFiltre} className="flex flex-1 lg:flex-row flex-col gap-2 rounded-lg">
            <div className="join flex-1">
                <input type="text" placeholder="Ara" name="search" className="input input-bordered border-base-200 w-full bg-base-200 join-item" />
                <button className="btn border border-base-200 border-l-2 border-l-base-100 join-item">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clipRule="evenodd" />
                    </svg>

                </button>
            </div>

            <div className="flex gap-2">
                <div className="dropdown dropdown-end dropdown-bottom  max-lg:flex-1">
                    <label tabIndex={0} className="btn flex">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                        </svg>

                        <span className="lg:inline-block hidden">
                            Filtrele
                        </span>
                    </label>
                    <ul tabIndex={0} className="dropdown-content z-[50] menu p-2 shadow bg-base-300 rounded-box w-64 mt-5">
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Varsayılan</span>
                                    <input type="radio" className="radio checked:radio-primary" name="filter" value={"DEFAULT"} defaultChecked />
                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Önemsiz</span>
                                    <input type="radio" className="radio checked:radio-primary" name="filter" value={"UNIMPORTANT"} />
                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Seviye 1</span>
                                    <input type="radio" className="radio checked:radio-primary" name="filter" value={"LEVEL_1"} />
                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Seviye 2</span>
                                    <input type="radio" className="radio checked:radio-primary" name="filter" value={"LEVEL_2"} />
                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Seviye 3</span>
                                    <input type="radio" className="radio checked:radio-primary" name="filter" value={"LEVEL_3"} />
                                </label>
                            </a>
                        </li>
                        <button className="btn btn-sm btn-primary w-full mt-4">Uygula</button>
                    </ul>
                </div>

                <div className="dropdown dropdown-end dropdown-bottom   max-lg:flex-1">
                    <label tabIndex={0} className="btn flex">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                        </svg>
                        <span className="lg:inline-block hidden">

                            Sırala
                        </span>
                    </label>
                    <ul tabIndex={0} className="dropdown-content z-[50] menu p-2 shadow bg-base-300 rounded-box w-64 mt-5">
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Varsayılan Sıralama</span>
                                    <input type="radio" className="radio checked:radio-primary" name="sort" value={"DEFAULT"} defaultChecked />
                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Ülke A-Z</span>
                                    <input type="radio" className="radio checked:radio-primary" name="sort" value={"COUNTRY_ASC"} />
                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Ülke Z-A</span>
                                    <input type="radio" className="radio checked:radio-primary" name="sort" value={"COUNTRY_DESC"} />

                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">En Önemli</span>
                                    <input type="radio" className="radio checked:radio-primary" name="sort" value={"IMPORTANT_DESC"} />

                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">En Önemsiz</span>
                                    <input type="radio" className="radio checked:radio-primary" name="sort" value={"IMPORTANT_ASC"} />

                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Açıklama Tarihi Erken</span>
                                    <input type="radio" className="radio checked:radio-primary" name="sort" value={"DATE_ASC"} />

                                </label>
                            </a>
                        </li>
                        <li className="flex">
                            <a className="form-control p-2 bg-base-300 rounded-xl flex">
                                <label className="label cursor-pointer flex justify-between items-stretch w-full">
                                    <span className="label-text">Açıklama Tarihi Geç</span>
                                    <input type="radio" className="radio checked:radio-primary" name="sort" value={"DATE_DESC"} />

                                </label>
                            </a>
                        </li>
                        <button className="btn btn-sm btn-primary w-full mt-4">Uygula</button>
                    </ul>
                </div>

                <button className="btn" onClick={handleClearFunction}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                    </svg>
                    <span className="lg:inline-block hidden">
                        Temizle
                    </span>
                </button>
            </div>

        </form>

    </div>
}