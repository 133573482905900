import Error from "../error";
import { Result } from "../types";
import http from "./http";


export async function getExchangeRate(): Promise<Result<string, Error>> {

    try {
        const res = await http.get("api/kur")
        const data: string = res.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting exchange rate", { cause: err })]
    }

}
export async function sendDepositRequest(opt: { bankAccount: string, amount: number }): Promise<Result<any, Error>> {

    try {
        const res = await http.post("user/deposit_request?m=1", opt)
        const data: any = res.data
        return [data, null]
    } catch (err) {
        return [null, new Error("Error while creating deposit request", { cause: err })]
    }

}

export async function setWithdrawRequest(opt: { iban: string, amount: number, doviz: string }): Promise<Result<any, Error>> {

    try {
        const res = await http.post("user/withdraw_bank", opt)
        const data: any = res.data
        return [data, null]
    } catch (error) {

        return [null, new Error("Error while creating withdraw request")]

    }
}


export async function setCryptoWithdrawRequest(opt: { coin: string, ag: string, cuzdan: string, miktar: number }): Promise<Result<any, Error>> {

    try {
        const res = await http.post("acc/withdraw", opt)
        const data: any = res.data
        return [data, null]
    } catch (error) {

        return [null, new Error("Error while creating withdraw request")]

    }
}


export const getBankNameFromIban = (iban: string): string => {
    if (iban.length < 9) {
        return '';
    }
    const bankCode = iban.substring(4, 9);


    // Assuming you have a bankCodes object defined elsewhere
    return bankCodes[bankCode] ?? '';
};

const bankCodes: Record<string, string> = {
    '00001': 'TC Merkez Bankası',
    '00004': 'İller Bankası',
    '00010': 'Ziraat Bankası',
    '00012': 'Halk bank',
    '00014': 'Sınai Kalkınma Bankası',
    '00015': 'Vakıflar Bankası',
    '00016': 'Eximbank',
    '00017': 'Kalkınma Bankası',
    '00029': 'Birleşik Fon Bankası',
    '00032': 'Türk Ekonomi Bankası (TEB)',
    '00046': 'Akbank',
    '00059': 'Şekerbank',
    '00062': 'Garanti Bankası',
    '00064': 'İş Bankası',
    '00067': 'Yapı ve Kredi Bankası',
    '00091': 'Arap Türk Bankası',
    '00092': 'Citibank',
    '00094': 'Bank Mellat',
    '00096': 'Turkish Bank',
    '00098': 'JPMorgan Chase Bank',
    '00099': 'ING Bank',
    '00100': 'Adabank',
    '00103': 'Fibabanka',
    '00108': 'Turkland Bank',
    '00109': 'ICBC Turkey Bank',
    '00111': 'Finansbank',
    '00115': 'Deutsche Bank',
    '00116': 'Pasha Yatırım Bankası',
    '00121': 'Standard Chartered Yatırım Bankası',
    '00122': 'Societe Generale',
    '00123': 'HSBC',
    '00124': 'Alternatif Bank',
    '00125': 'Burgan Bank',
    '00129': 'Merrill Lynch Yatırım Bank',
    '00132': 'Takasbank',
    '00134': 'Denizbank',
    '00135': 'Anadolu Bank',
    '00137': 'Rabobank',
    '00138': 'Dilerbank',
    '00139': 'GSD Bank',
    '00141': 'Nurol Yatırım Bankası',
    '00142': 'Bankpozitif Kredi ve Kalkınma Bankası',
    '00143': 'Aktif Yatırım Bankası',
    '00146': 'Odea Bank',
    '00147': 'Bank of Tokyo-Mitsubishi UFJ Turkey',
    '00148': 'Intesa Sanpaolo S.p.A',
    '00203': 'Albaraka Türk Katılım Bankası',
    '00205': 'Kuveyt Türk Katılım Bankası',
    '00206': 'Türkiye Finans Katılım Bankası',
    '00209': 'Ziraat Katılım Bankası',
    '00210': 'Vakıf Katılım Bankası',
    '00806': 'Merkezi Kayıt Kuruluşu'
};