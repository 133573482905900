import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

export default function NumberInputTest({ defaultValue, digits, onChange, min, max }: {

    defaultValue: number, digits: number, onChange: (val: number | null | undefined
    ) => void, min?: number, max?: number
}) {

    const [val, setVal] = useState<string | undefined>(defaultValue.toFixed(digits))
    const steps = (1 / Math.pow(10, digits)) * 5



    const decrement = () => {
        setVal(prev => {
            if (prev != null || prev != undefined) {
                const floatVal = parseFloat(prev)
                onChange(floatVal - steps)
                return (floatVal - steps).toFixed(digits)

            }
        })
    }

    const increment = () => {
        setVal(prev => {
            if (prev != null || prev != undefined) {
                const floatVal = parseFloat(prev)
                onChange(floatVal + steps)
                return (floatVal + steps).toFixed(digits)
            }
        })
    }

    return <div className="content-container flex rounded-lg p-2 items-center">
        <button className="btn btn-sm font-bold content-container hover:bg-primary hover:text-primary-content flex items-center px-2" onClick={decrement}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
            </svg>
        </button>
        <CurrencyInput
            id="input-example"
            style={{ outline: "none", appearance: "textfield", MozAppearance: "textfield", WebkitAppearance: "textfield" }}
            className="input input-sm w-full flex-1 bg-base-100 text-center font-bold appearance-none"
            name="input-name"
            value={val}
            placeholder="Please enter a number"
            decimalsLimit={digits}
            min={min}
            max={max}
            onValueChange={(value, _, values) => {
                setVal(value)
                onChange(values?.float)
            }}
        />
        <button className="btn btn-sm font-bold content-container hover:bg-primary px-2 hover:text-primary-content flex items-center" onClick={increment}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
            </svg>
        </button>
    </div>
}
