import { Basket, Comment, CommentsResult, Position, PositionHistoryItem, Trader, TraderInfo } from "@/types"
import { useLoaderData } from "react-router-dom"
import TraderPositionsList from "./TraderPositionsList"
import { FormEvent, useEffect, useState } from "react"
import TraderPositionsHistoryList from "./TraderPositionsHistoryList"
import ReportModal from "./ReportModal"
import EditFollowModal from "./EditFollowModal"
import FollowModal from "./FollowModal"
import usePrompt from "@/hooks/usePrompt"
import { blockTrader, followTrader, getBasketList, getTraderComments, postComment, reportCommentRequest, reportTraderRequest, unfollowTraderRequest } from "@/utils"
import { emitEvent, eventConstants, onEvent } from "../main"
import ReportCommentModal from "./ReportCommentModal"
import MobileTraderInfo from "./mobile/MobileTraderInfo"
import MobileTraderRIO from "./mobile/MobileTraderRIO"
import MobileTraderStats from "./mobile/MobileTraderStats"
import MobileTraderComments from "./mobile/MobileTraderComments"
import ClientPaginatedList from "./ClientPaginatedList"
import TraderBasketList from "./TraderBasketList"
import useWindowSize from "@/hooks/useWindowSize"
import MobileReportModal from "./mobile/MobileReportModal"
import MobileEditFollowModal from "./mobile/MobileEditFollowModal"
import MobileFollowModal from "./mobile/MobileFollowModal"
import MobileReportCommentModal from "./mobile/MobileReportCommentModal"
import useTraderPositionsTransformer from "@/hooks/useTraderPositionsTransformer"
import useTraderPositionsHistoryTransformer from "@/hooks/useTraderPositionsHistoryTransformer"


export default function () {

    const {
        traderId,
        traderInfo,
        traderComments,
        traderPositions,
        traderPositionHistory,
        traderData: trader,
        traderBaskets: traderBasketsLoaderData
    } = useLoaderData() as {
        traderId: string,
        traderInfo: TraderInfo,
        traderComments: CommentsResult,
        traderPositions: Position[],
        traderOrders: [],
        traderPositionHistory: PositionHistoryItem[],
        traderData: Trader,
        traderBaskets: Basket[]
    }


    const updatedTraderPositions = useTraderPositionsTransformer(traderPositions)
    const updatedTraderHistoryPositions = useTraderPositionsHistoryTransformer(traderPositionHistory)
    const [selectedTrader, setSelectedTrader] = useState<Trader | null>(null)
    const [selectedComment, setSelectedComment] = useState<Comment | null>(null)
    const [editTrader, setEditTrader] = useState<Trader | null>(null)
    const [reportTrader, setReportTrader] = useState<Trader | null>(null)
    const [commentMode, setCommentMode] = useState(false)
    const [comments, setComments] = useState(traderComments.entries)
    const [traderBaskets, setTraderBaskets] = useState(traderBasketsLoaderData)
    const isMobile = useWindowSize(700)

    const { prompt: BlockTraderPrompt, openPrompt: openBlockTraderPrompt } = usePrompt({
        description: "Bu Traderi engellemek istediğinize emin misiniz?", onAccept: async () => {

            const [_, err] = await blockTrader(traderId)

            if (err) {
                emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Kullanıcı engellenirken bir hata oluştu!" })

                return
            }

            emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Kullanıcı Başarıyla Engellendi!" })

        }
    })

    const [tab, setTab] = useState(0)
    const rating = Math.floor(trader.star / trader.starCount)


    useEffect(() => {
        const refetchTraderBaskets = onEvent(eventConstants.RE_FETCH_TRADER_BASKETS, async () => {
            const [data, _] = await getBasketList(traderId)

            if (data) {
                setTraderBaskets([...data])
                return
            }
        })

        return () => {
            refetchTraderBaskets.unsubscribe()
        }
    }, [])

    function getStarRating(item: Trader) {
        const calc = item.star / item.starCount

        if (isNaN(calc)) {
            return "0.0"
        }

        return (calc).toFixed(1)
    }

    function handleOpenFollowModal(item: Trader) {
        if (item.isFollowing) {
            setEditTrader(item)
        } else {
            setSelectedTrader(item)
        }
    }

    async function handleFollowRequest(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const followData = new FormData(e.target as HTMLFormElement)

        const options = followData.getAll("option") as string[]
        const lotPercentage = followData.get("lotPercentage") as string
        const traderId = followData.get("traderId") as string

        const [_, err] = await followTrader({ options: options.join(","), lotPercentage: parseFloat(lotPercentage), traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Trader başarıyla takip edildi!" })
        setSelectedTrader(null)
        setEditTrader(null)

    }

    async function handleUnfollow(traderId: string) {
        const [_, err] = await unfollowTraderRequest({ traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Trader başarıyla takipten çıkıldı!" })
        setEditTrader(null)

    }


    async function handleReportRequest(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const followData = new FormData(e.target as HTMLFormElement)

        const reason = followData.get("reason") as string
        const message = followData.get("message") as string
        const traderId = followData.get("traderId") as string

        const [_, err] = await reportTraderRequest({ reason: reason, message: message, traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Bu kullanıcı daha önce şikayet edildi!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Trader başarıyla şikayet edildi!" })
        setReportTrader(null)
    }


    function handleOpenBlockTraderPrompt() {
        openBlockTraderPrompt()
    }

    function handleOpenReportModal(item: Trader) {
        setReportTrader(item)
    }

    async function handleCommentSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const formData = new FormData(e.target as HTMLFormElement)

        const stars = formData.get("stars") as string
        const message = formData.get("message") as string
        const hideName = formData.get("hideName") as string
        const traderId = formData.get("traderId") as string

        const [_, err] = await postComment({ message: message, star: parseInt(stars), hideName: hideName === null ? false : true, traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Yorum paylaşırken hata oluştu!" })
            return false
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Yorum başarıyla paylaşıldı!" })
        const res = await refreshComments(traderId)
        setComments([...res])
        setCommentMode(false)
    }

    async function refreshComments(traderId: string) {

        const [traderComments, traderCommentsError] = await getTraderComments({ page: 1, traderId })

        if (traderCommentsError) {
            return []
        }

        return traderComments.entries
    }


    async function handleReportComment(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const followData = new FormData(e.target as HTMLFormElement)

        const reason = followData.get("reason") as string
        const message = followData.get("message") as string
        const commentId = followData.get("commentId") as string

        const [_, err] = await reportCommentRequest({ reason: reason, message: message, commentId: commentId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Bu yorum daha önce şikayet edildi!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Yorum başarıyla şikayet edildi!" })
        setSelectedComment(null)
    }

    function handleOpenTraderSettings() {
        emitEvent(eventConstants.OPEN_TRADER_PROFILE_MODEL, "")
    }
    return <>
        {BlockTraderPrompt()}
        {reportTrader && !isMobile ? <ReportModal trader={reportTrader} onSubmit={handleReportRequest} onClose={() => setReportTrader(null)} /> : null}
        {reportTrader && isMobile ? <MobileReportModal trader={reportTrader} onSubmit={handleReportRequest} onClose={() => setReportTrader(null)} /> : null}

        {editTrader && !isMobile ? <EditFollowModal onUnfollow={handleUnfollow} trader={editTrader} onClose={() => setEditTrader(null)} onSubmit={handleFollowRequest} /> : null}
        {editTrader && isMobile ? <MobileEditFollowModal onUnfollow={handleUnfollow} trader={editTrader} onClose={() => setEditTrader(null)} onSubmit={handleFollowRequest} /> : null}

        {selectedTrader && !isMobile ? <FollowModal trader={selectedTrader} onClose={() => setSelectedTrader(null)} onSubmit={handleFollowRequest} /> : null}
        {selectedTrader && isMobile ? <MobileFollowModal trader={selectedTrader} onClose={() => setSelectedTrader(null)} onSubmit={handleFollowRequest} /> : null}


        {selectedComment && !isMobile ? <ReportCommentModal comment={selectedComment} onSubmit={handleReportComment} onClose={() => setSelectedComment(null)} /> : null}
        {selectedComment && isMobile ? <MobileReportCommentModal comment={selectedComment} onSubmit={handleReportComment} onClose={() => setSelectedComment(null)} /> : null}

        <div className="flex flex-col items-center p-6">
            <div className="container">

                <div className="flex lg:flex-row flex-col gap-4 lg:items-start">

                    <div className="flex flex-col gap-4 flex-1 ">

                        <div className="hidden lg:flex flex-col p-6 content-container rounded-lg justify-center">
                            <div className="flex gap-4 items-center">
                                <div className="avatar">
                                    <div className="w-24 rounded-full">
                                        <img src={trader.photo} />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 flex-1">
                                    <div className="flex items-center gap-3">
                                        <span>{trader.name}</span>
                                        {trader.isVerified && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-info">
                                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                        </svg>}
                                    </div>

                                    <div className="rating rating-sm pointer-events-none">
                                        {[1, 2, 3, 4, 5].map(ratingTrader => {
                                            return <input key={ratingTrader} type="radio" name="rating-5" className="mask mask-star-2 bg-orange-400" readOnly checked={rating >= ratingTrader} />
                                        })}
                                        <p className="text-sm text-base-content ml-1">({getStarRating(trader)})</p>
                                    </div>

                                    <div className="flex items-center gap-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 fill-base-content">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                        </svg>

                                        <p className="text-sm text-base-content">{trader.followers}</p>
                                    </div>
                                </div>

                                {!trader.owner && <div className="flex gap-3 self-start">
                                    <button className={"btn btn-sm " + (trader.isFollowing ? "btn-secondary" : "btn-primary")} onClick={() => handleOpenFollowModal(trader)}>
                                        {!trader.isFollowing ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                            <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                                        </svg> :
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                                <path d="M10.375 2.25a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25zM10.375 12a7.125 7.125 0 00-7.124 7.247.75.75 0 00.363.63 13.067 13.067 0 006.761 1.873c2.472 0 4.786-.684 6.76-1.873a.75.75 0 00.364-.63l.001-.12v-.002A7.125 7.125 0 0010.375 12zM16 9.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6z" />
                                            </svg>

                                        }

                                        {!trader.isFollowing ? "Takip Et" : "Takipten Çık"}
                                    </button>
                                    <button className={"btn btn-sm bg-base-300"} onClick={() => handleOpenBlockTraderPrompt()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                        </svg>

                                        Engelle</button>
                                    <button className="btn btn-sm bg-base-300" onClick={() => handleOpenReportModal(trader)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg>
                                        Şikayet Et</button>

                                </div>}


                                {trader.owner && <div className="flex gap-3 self-start">
                                    <button className={"btn btn-sm btn-primary"} onClick={() => handleOpenTraderSettings()}>

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                        Ayarlar
                                    </button>
                                </div>}


                            </div>
                        </div>

                        <div className="lg:hidden">
                            <MobileTraderInfo trader={trader} reportTrader={handleOpenReportModal} blockTrader={handleOpenBlockTraderPrompt} openFollowModal={handleOpenFollowModal} />
                        </div>

                        <div className="hidden lg:grid grid-cols-3 gap-x-4">

                            <div className="flex flex-col content-container rounded-lg p-6 items-center">
                                <span className="text-sm">Profit</span>
                                <span className="text-center text-success font-bold text-lg">{parseFloat(trader.profit).toLocaleString()}$</span>
                            </div>

                            <div className="flex flex-col content-container rounded-lg p-6 items-center">
                                <span className="text-sm">Last ROI</span>
                                <span className="text-center text-success font-bold text-lg">{parseFloat(trader.lastRoi).toLocaleString()}%</span>
                            </div>

                            <div className="flex flex-col content-container rounded-lg p-6 items-center">
                                <span className="text-sm">This ROI</span>
                                <span className="text-center text-success font-bold text-lg">{parseFloat(trader.thisRoi).toLocaleString()}%</span>
                            </div>

                        </div>

                        <MobileTraderRIO trader={trader} />

                        <MobileTraderStats traderInfo={traderInfo} />


                        <div className="flex flex-col gap-4 flex-1">
                            <div className="flex gap-2 overflow-x-auto">
                                <button className={"btn btn-sm " + (tab === 0 && "btn-primary")} onClick={() => setTab(0)}>Sepetler</button>
                                <button className={"btn btn-sm " + (tab === 1 && "btn-primary")} onClick={() => setTab(1)}>Pozisyonlar</button>
                                <button className={"btn btn-sm " + (tab === 2 && "btn-primary")} onClick={() => setTab(2)}>Geçmiş</button>
                                <button className={"btn btn-sm " + (tab === 3 && "btn-primary")} onClick={() => setTab(3)}>Sorularım</button>
                            </div>

                            {tab === 0 && <div className="flex flex-col gap-2">
                                <TraderBasketList key={traderBaskets.length} trader={trader} baskets={traderBaskets} />
                            </div>}

                            {tab === 1 && <div className="flex flex-col gap-2">
                                <TraderPositionsList positions={updatedTraderPositions} />
                            </div>}
                            {tab === 2 && <div className="flex flex-col gap-2">
                                <TraderPositionsHistoryList positions={updatedTraderHistoryPositions} />
                            </div>}
                            {tab === 3 && <div className="flex flex-col gap-2 items-center justify-center bg-base-200 rounded-lg p-4 max-h-[500px] h-[75vh]">
                                <span>Herhangi bir soru bulunamadı!</span>
                            </div>}
                        </div>

                        <MobileTraderComments trader={trader} traderComments={traderComments} />

                    </div>



                    <div className="w-96 flex flex-col gap-4">
                        <div className="hidden lg:flex flex-col gap-3 content-container rounded-lg p-4 ">
                            <div className="flex justify-between items-center">
                                <span className="text-sm">Toplam İşlem:</span>
                                <span className="font-bold">{traderInfo.totalCount}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-sm">Karlı İşlem:</span>
                                <span className="font-bold">{traderInfo.positiveCount}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-sm">Zararlı İşlem:</span>
                                <span className="font-bold">{traderInfo.negativeCount}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-sm">Ortalama ROI:</span>
                                <span className="font-bold">{traderInfo.roiAverage.toFixed(2)}%</span>
                            </div>
                        </div>

                        <div className="hidden lg:flex flex-col gap-3 rounded-lg">
                            <div className="flex justify-between items-center">
                                <h1 className="font-semibold">Yorumlar</h1>

                                {!trader.owner && <button className={commentMode ? "btn btn-sm btn-primary" : "btn btn-sm"} onClick={() => setCommentMode(!commentMode)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                                    </svg>

                                    Yorum Yap</button>}

                            </div>
                            {commentMode && <form onSubmit={handleCommentSubmit} className="flex flex-col gap-4 my-4 content-container p-4 rounded-lg">
                                <input type="hidden" name="traderId" value={trader._id} readOnly />
                                <div className="rating rating-sm">
                                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={1} required />
                                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={2} required />
                                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={3} required />
                                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={4} required />
                                    <input type="radio" name="stars" className="mask mask-star-2 bg-orange-400" value={5} required defaultChecked />
                                </div>
                                <textarea className="textarea textarea-bordered" name="message" placeholder="Bir mesaj bırak" required></textarea>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Adımı Sakla</span>
                                        <input type="checkbox" name="hideName" defaultChecked={false} className="checkbox checkbox-primary checkbox-sm" />
                                    </label>
                                </div>
                                <button className="btn btn-primary btn-sm">Paylaş</button>
                            </form>}

                            <ClientPaginatedList<Comment> items={comments} length={comments.length} paginationLength={10} listComponent={({ item }) => {

                                return <div key={item._id} className=" flex flex-col gap-2 content-container p-4 rounded-lg">
                                    <div className="flex items-center justify-between">
                                        <div className="flex gap-2 items-center">
                                            <span className="text-xs">{item.follower.tamad}</span>
                                            <div className="rating rating-xs pointer-events-none">

                                                {[1, 2, 3, 4, 5].map(ratingTrader => {
                                                    return <input type="radio" name="rating-5" className="mask mask-star-2 bg-orange-400" readOnly checked={item.star >= ratingTrader} />
                                                })}
                                            </div>
                                        </div>

                                        <button className="hover:text-error" onClick={() => setSelectedComment(item)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                            </svg>
                                        </button>
                                    </div>
                                    <span className="text-sm">{item.message.length > 0 ? item.message : "-"}</span>
                                </div>

                            }} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
}