import { ChangeEvent, FormEvent, useRef } from "react";

export default function ({ onClose, onSubmit }: { onClose: () => void, onSubmit: (event: FormEvent<HTMLFormElement>) => void }) {

    const photoInputRef = useRef<HTMLInputElement | null>(null)
    const imageElementRef = useRef<HTMLImageElement | null>(null)

    function handleOpenFileInput() {
        if (!photoInputRef.current) return

        photoInputRef.current.click()
    }

    function handleInputChange(event: ChangeEvent<HTMLInputElement>) {

        if (!event.target.files) return
        if (!imageElementRef.current) return

        const selectedFile = event.target.files[0]

        const reader = new FileReader();

        reader.onload = function (e) {
            // Set the src attribute of the img tag to the data URL of the selected image
            if (e.target && imageElementRef.current) {
                imageElementRef.current.src = e.target.result as string;
            }
        };

        reader.readAsDataURL(selectedFile);
    }

    return <>
        {/* Put this part before </body> tag */}
        <input type="checkbox" id="my_modal_6" className="modal-toggle" checked />
        <div className="modal">
            <div className="modal-box overflow-visible">
                <form onSubmit={onSubmit}>
                    <div className="flex items-center justify-center -mt-16 flex-col gap-2 ">
                        <div className="indicator">
                            <span className="indicator-item bg-primary text-primary-content px-0 w-7 h-7 cursor-pointer hover:bg-primary-focus flex items-center justify-center rounded-md right-6 top-6" onClick={handleOpenFileInput}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </span>
                            <div className="avatar">
                                <div className="w-36 rounded-full bg-base-200">
                                    <input name="file" type="file" className="hidden" ref={photoInputRef} onChange={e => handleInputChange(e)} accept=".jpg, .png" required />
                                    <img ref={imageElementRef} src={"https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"} />
                                </div>
                            </div>
                        </div>

                        <div className="form-control rounded-xl">
                            <input type="text" name="name" placeholder="İsminiz" className="input input-bordered w-full bg-base-200" required />
                        </div>
                    </div>
                    <div className="mt-4"></div>

                    <div className="flex mt-6 gap-3">
                        <button className="btn flex-1 btn-primary">Onayla</button>
                    </div>
                </form>
            </div>
            <label className="modal-backdrop" htmlFor="my_modal_6" onClick={onClose}>Close</label>
        </div>
    </>
}