import Stats from "@/utils/Stats";
import PositionListComponent from "./PositionListComponent";
import PositionHistoryList from "./PositionHistoryList";
import { useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { UserOrderCountAtom, UserOrdersAtom, UserPositionHistoryAtom, UserPositionHistoryCountAtom } from "@/atoms";
import OrderListComponent from "./OrderListComponent";
import useBoundStore from "./stores/store";


export default function () {

    const [tab, setTab] = useState(0)

    const btnClass = "btn btn-base-200 hover:bg-primary hover:text-black lg:btn-md btn-sm"
    const btnClassActive = "btn btn-primary lg:btn-md btn-sm"

    const orders = useAtomValue(UserOrdersAtom)
    const positionHistory = useAtomValue(UserPositionHistoryAtom)

    const [historyCount] = useAtom(UserPositionHistoryCountAtom)
    const [orderCount] = useAtom(UserOrderCountAtom)

    const zusPositions = useBoundStore((state) => state.positions)


    const render = (tab: number) => {

        switch (tab) {
            case 0:
                return <PositionListComponent />
            case 1:
                return <OrderListComponent orders={orders} />
            case 2:
                return <PositionHistoryList positionHistory={positionHistory} />
            default:
                return <PositionListComponent />
        }
    }
    return (
        <>
            <div className="flex gap-4 w-full ">
                <Stats stats={zusPositions.length} orders={orderCount} history={historyCount} />
            </div>
            <div className="flex items-center gap-4">
                <button className={tab === 0 ? btnClassActive : btnClass} onClick={() => setTab(0)}>
                    Pozisyonlar
                </button>
                <button className={tab === 1 ? btnClassActive : btnClass} onClick={() => setTab(1)}>
                    Emirler
                </button>
                <button className={tab === 2 ? btnClassActive : btnClass} onClick={() => setTab(2)}>
                    Geçmiş
                </button>
            </div>

            <div className="flex gap-4 w-full pb-4">
                {render(tab)}
            </div>
        </>
    )
}