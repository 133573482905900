import { Trader } from "@/types";

export default function ({ trader }: { trader: Trader }) {

    return <div className="lg:hidden flex flex-col content-container p-6 rounded-lg">

        <div className="flex justify-between items-center">
            <span className="text-sm">Profit</span>
            <span className="text-center text-success font-bold text-lg">${parseFloat(trader.profit).toLocaleString()}</span>
        </div>

        <div className="flex justify-between items-center">
            <span className="text-sm">Last ROI</span>
            <span className="text-center text-success font-bold text-lg">{parseFloat(trader.lastRoi).toLocaleString()}%</span>
        </div>

        <div className="flex justify-between items-center">
            <span className="text-sm">This ROI</span>
            <span className="text-center text-success font-bold text-lg">{parseFloat(trader.thisRoi).toLocaleString()}%</span>
        </div>

    </div>
}