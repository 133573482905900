
import { TradersResult } from "@/types"
import { filterTradersRequest } from "@/utils"
import { FormEvent, useRef, useState } from "react"
import MobileBottomDrawer from "./MobileBottomDrawer"

export default function ({ onFilter }: { onFilter: (list: TradersResult, filters: { search: string, sort: string, filter: string }) => void }) {

    const formRef = useRef<null | HTMLFormElement>(null)
    const [isOpen, setIsOpen] = useState({ filters: false, sort: false })


    async function handleFiltre(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const formData = new FormData(e.target as HTMLFormElement)

        const search = formData.get("search") as string
        const sort = formData.get("sort") as string
        const filter = formData.getAll("filter") as string[]

        setIsOpen({ filters: false, sort: false })

        const [data, err] = await filterTradersRequest({ page: 1, search: search as string, sort: sort as string, filter: filter.join(",") })

        if (err) {
            console.log(err)
            return
        }

        console.log(data)

        onFilter({ traders: data.traders, pagination: data.pagination }, { search, sort, filter: filter.join(",") })
    }


    async function handleClearFunction() {
        if (!formRef.current) return

        formRef.current.reset()

        const formData = new FormData(formRef.current as HTMLFormElement)


        const search = formData.get("search") as string
        const sort = formData.get("sort") as string
        const filter = formData.getAll("filter")


        const [data, err] = await filterTradersRequest({ page: 1, search: search as string, sort: sort as string, filter: filter.join(",") })

        if (err) {
            console.log(err)
            return
        }

        console.log(data)

        onFilter({ traders: data.traders, pagination: data.pagination }, { search, sort, filter: filter.join(",") })
    }

    return <form ref={formRef} onSubmit={handleFiltre} className="flex flex-col lg:flex-row gap-1  p-4 rounded-lg">
        <div className="join flex-1">
            <input type="text" placeholder="Ara" name="search" className="input input-bordered border-base-200 w-full bg-base-200 join-item" />
            <button className="btn border border-base-200 border-l-2 border-l-base-100 join-item">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clipRule="evenodd" />
                </svg>

            </button>
        </div>

        <div className="flex justify-end lg:justify-start gap-1">

            <label tabIndex={0} className="btn flex" onClick={() => setIsOpen(prev => {
                return { ...prev, filters: true }
            })}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                </svg>
                <span className="hidden lg:inline-block">Filtrele</span>

            </label>

            <label tabIndex={0} className="btn flex" onClick={() => setIsOpen(prev => {
                return { ...prev, sort: true }
            })}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                </svg>

                <span className="hidden lg:inline-block">Sırala</span>


            </label>


            <button className="btn" onClick={handleClearFunction}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M3.75 12a.75.75 0 01.75-.75h15a.75.75 0 010 1.5h-15a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                </svg>

                <span className="hidden lg:inline-block">Temizle</span>
            </button>


            <MobileBottomDrawer visible={isOpen.filters} outsideClicked={() => setIsOpen(prev => {
                return { ...prev, filters: false }
            })}>
                <p className="font-bold text-xl mb-3">Filtrele</p>
                <ul tabIndex={0} className="dropdown-content menu p-0 rounded-box w-full mt-5">
                    <li className="flex">
                        <a className="form-control p-1 rounded-xl flex">
                            <label className="label cursor-pointer flex justify-between items-center w-full">
                                <span className="label-text">Takip Ettiklerim</span>
                                <input type="checkbox" className="checkbox checkbox-primary" name="filter" value={"FOLLOWING"} />
                            </label>
                        </a>
                    </li>
                    <li className="flex">
                        <a className="form-control p-1 rounded-xl flex">
                            <label className="label cursor-pointer flex justify-between items-center w-full">
                                <span className="label-text">Onaylı Traderlar</span>
                                <input type="checkbox" className="checkbox checkbox-primary" name="filter" value={"VERIFIED"} />
                            </label>
                        </a>
                    </li>

                    <button className="btn btn-sm btn-primary w-full mt-4">Uygula</button>
                </ul>
            </MobileBottomDrawer>

            <MobileBottomDrawer visible={isOpen.sort} outsideClicked={() => setIsOpen(prev => {
                return { ...prev, sort: false }
            })}>
                <p className="font-bold text-xl mb-3">Sırala</p>
                <ul tabIndex={0} className="dropdown-content menu rounded-box p-0 w-full mt-5">
                    <li className="flex">
                        <a className="form-control p-1 rounded-xl flex">
                            <label className="label cursor-pointer flex justify-between items-center w-full">
                                <span className="label-text">Varsayılan Sıralama</span>
                                <input type="radio" className="radio checked:radio-primary" name="sort" value={""} />
                            </label>
                        </a>
                    </li>
                    <li className="flex">
                        <a className="form-control p-1 rounded-xl flex">
                            <label className="label cursor-pointer flex justify-between items-center w-full">
                                <span className="label-text">İsim A-Z</span>
                                <input type="radio" className="radio checked:radio-primary" name="sort" value={"NAME_ASC"} />
                            </label>
                        </a>
                    </li>
                    <li className="flex">
                        <a className="form-control p-1 rounded-xl flex">
                            <label className="label cursor-pointer flex justify-between items-center w-full">
                                <span className="label-text">İsim Z-A</span>
                                <input type="radio" className="radio checked:radio-primary" name="sort" value={"NAME_DESC"} />

                            </label>
                        </a>
                    </li>
                    <li className="flex">
                        <a className="form-control p-1 rounded-xl flex">
                            <label className="label cursor-pointer flex justify-between items-center w-full">
                                <span className="label-text">Takipçi Sayısı Artan</span>
                                <input type="radio" className="radio checked:radio-primary" name="sort" value={"FOLLOWER_ASC"} />

                            </label>
                        </a>
                    </li>
                    <li className="flex">
                        <a className="form-control p-1 rounded-xl flex">
                            <label className="label cursor-pointer flex justify-between items-center w-full">
                                <span className="label-text">Takipçi Sayısı Azalan</span>
                                <input type="radio" className="radio checked:radio-primary" name="sort" value={"FOLLOWER_DESC"} />

                            </label>
                        </a>
                    </li>
                    <li className="flex">
                        <a className="form-control p-1 rounded-xl flex">
                            <label className="label cursor-pointer flex justify-between items-center w-full">
                                <span className="label-text">Onaylı Traderlar</span>
                                <input type="radio" className="radio checked:radio-primary" name="sort" value={"VERIFIED"} />

                            </label>
                        </a>
                    </li>
                    <button className="btn btn-sm btn-primary w-full mt-4">Uygula</button>
                </ul>
            </MobileBottomDrawer>



        </div>

    </form>
}