import { SymbolCategoriesAtom } from "@/atoms";
import { useAtomValue } from "jotai";
import { FormEvent } from "react";

export default function ({ onClose, onSubmit }: { onClose: () => void, onSubmit: (event: FormEvent<HTMLFormElement>) => void }) {

    const categories = useAtomValue(SymbolCategoriesAtom)
    return <>
        {/* Put this part before </body> tag */}
        <input type="checkbox" id="my_modal_6" className="modal-toggle" checked />
        <div className="modal">
            <div className="modal-box">
                <div className="flex flex-col mb-4 gap-3">
                    <h1 className="text-semibold text-xl">Pozisyon Sepeti Talebi</h1>
                    <p className="text-sm">
                        Hangi kategorilerden sepet istiyorsanız, o kategorileri seçebilirsiniz.
                    </p>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="flex flex-col gap-2">
                        {categories?.map(category => {

                            return <div key={category._id} className="form-control p-2 bg-base-300 rounded-xl">
                                <label className="label cursor-pointer">
                                    <span className="label-text">{category.name}</span>
                                    <input type="checkbox" className="checkbox checked:checkbox-primary" name="categories" value={category._id} />
                                </label>
                            </div>
                        })}

                    </div>
                    <div className="mt-4"></div>
                    <div className="flex mt-2">
                        <button className="btn w-full btn-primary">Talep Et</button>
                    </div>
                </form>
            </div>
            <label className="modal-backdrop" htmlFor="my_modal_6" onClick={onClose}>Close</label>
        </div>
    </>
}