import { getBanners, getBestPositions, getSuggestions, getTopTrapders } from "@/utils";
import { getStories } from "@/utils/story";
import { LoaderFunctionArgs } from "react-router-dom";
import { progress } from "../../main";
import { Banner, Story, Suggestion, TraderList } from "@/types";


export default function ({ }: LoaderFunctionArgs) {

    return new Promise(async (res, _) => {
        progress.start()

        const results: { traders: null | TraderList, positions: null | string[], suggestions: null | Suggestion[], stories: null | Story[], banners: null | Banner[] } =
            { traders: null, positions: null, suggestions: null, stories: null, banners: null }

        const [topTradersPromise, bestPoisitionsPromise, suggestionsPromise, storiesPromise, bannersPromise] = await Promise.allSettled([getTopTrapders(), getBestPositions(), getSuggestions(), getStories(), getBanners()])


        if (topTradersPromise.status == "fulfilled") {
            results.traders = topTradersPromise.value[0]
        }

        if (bestPoisitionsPromise.status == "fulfilled") {
            results.positions = bestPoisitionsPromise.value[0]
        }

        if (suggestionsPromise.status == "fulfilled") {
            results.suggestions = suggestionsPromise.value[0]
        }

        if (storiesPromise.status == "fulfilled") {
            results.stories = storiesPromise.value[0]
        }

        if (bannersPromise.status == "fulfilled") {
            results.banners = bannersPromise.value[0]
        }

        progress.done()
        return res(results)
    })
}