import { TraderList } from "@/types"
import { getBlockedTraders, unblockTrader } from "@/utils"
import { useEffect, useState } from "react"
import { emitEvent, eventConstants } from "../main"

export default function () {

    const [traders, setTraders] = useState<TraderList>([])

    useEffect(() => {
        getBlockedTraders().then(([data, err]) => {
            if (err) {
                return
            }

            setTraders(data)
        })
    }, [])

    async function handleUnblock(traderId: string) {
        const [_, err] = await unblockTrader(traderId)

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Kullanıcının engelı kaldırılırken bir hata oluştu!" })

            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Kullanıcınin Engeli Başarıyla kaldırıldı!" })
        setTraders(prev => {
            return [...prev.filter(item => item._id !== traderId)]
        })


    }

    return <div className="flex flex-col flex-1 prose max-w-none  gap-4">
        <h1 className="text-xl">Blocked</h1>
        <hr />

        <div className="flex flex-col gap-2">
            {traders.map(item => {

                return <div className="content-container p-4 rounded-lg flex items-center gap-4" key={item._id}>
                    <div className="avatar">
                        <div className="w-12 rounded-full">
                            <img src={item.photo} />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 pr-4">
                        <div className="flex items-center gap-2">
                            <h1 className="font-semibold text-sm">{item.name}</h1>
                            {item.isVerified && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-info">
                                <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                            </svg>}


                        </div>
                    </div>
                    { }
                    <div className="flex-1 flex items-center justify-end">
                        <button className="btn btn-sm btn-error text-primary-content" onClick={() => handleUnblock(item._id)}>Engel Kaldır</button>
                    </div>
                </div>
            })}
        </div>
    </div>
}