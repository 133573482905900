import { useCallback } from "react"


const DIVIDER_STYLE = {
    fontSize: '1px',
    backgroundColor: 'currentColor',
    color: 'inherit'
}


export default function ({
    value,
    onChange,
    options,
    iconComponent,
    ...rest
}: any) {
    const onChange_ = useCallback((event: any) => {
        const value = event.target.value
        onChange(value === 'ZZ' ? undefined : value)
    }, [onChange])


    // "ZZ" means "International".
    // (HTML requires each `<option/>` have some string `value`).
    return (
        <select
            name="code"
            className="select max-w-[100px] rounded-none join-item rounded-l-lg select-bordered md:select-md select-sm"
            {...rest}
            value={value || 'ZZ'}
            onChange={onChange_}>
            {options.map(({ value, label, divider }: any) => (
                <option
                    key={divider ? '|' : value || 'ZZ'}
                    value={divider ? '|' : value || 'ZZ'}
                    disabled={divider ? true : false}
                    style={divider ? DIVIDER_STYLE : undefined}>
                    {label}
                </option>
            ))}
        </select>
    )
}
