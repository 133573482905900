
import SymbolSearch from "../components/SymbolSearch"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import { SelectedSymbolAtom, SymbolsAtom, UserOrderCountAtom, UserOrdersAtom, UserPositionHistoryAtom, UserPositionHistoryCountAtom } from "@/atoms"
import SymbolDetails from "../components/SymbolDetails"
import StatsContainer from "../components/StatsContainer"
import Chart from "../components/Chart"
import { useLoaderData } from "react-router-dom"
import { useEffect, useMemo, useState } from "react"
import { eventConstants, onEvent } from "../main"
import { PositionHistoryItem, SymbolData, SymbolSettings, UpdatedPosition } from "@/types"
import { getUserOrderList, getUserPositionHistory, getUserPositionList } from "@/utils"
import SymbolInformationDrawer from "../components/ui/SymbolInformationDrawer"
import PositionHistoryDetailDrawer from "../components/ui/PositionHistoryDetailDrawer"
import useBoundStore from "../components/stores/store"


function Panel() {


    const params = useLoaderData() as { symbol: string, sl: string, tp: string, volume: string, mode: "BUY" | "SELL" }
    const [selectedSymbol, setSelectedSymbol] = useAtom(SelectedSymbolAtom)
    const symbols = useAtomValue(SymbolsAtom)
    const [symbolSettings, setSymbolSettings] = useState<SymbolSettings | null>(null)
    const [symbolInformation, setSymbolInformation] = useState<SymbolData | undefined>(undefined)
    const [positionHistoryDetail, setPositionHistoryDetail] = useState<PositionHistoryItem | undefined>(undefined)
    const zusSetPosition = useBoundStore((state) => state.setPositions)
    const zusDeletePosition = useBoundStore((state) => state.deletePosition)
    const zusDeleteUpdatedPosition = useBoundStore((state) => state.deleteUpdatedPosition)
    const zusSetUpdatedPositions = useBoundStore((state) => state.setUpdatedPositions)


    const setOrders = useSetAtom(UserOrdersAtom)
    const setOrderCount = useSetAtom(UserOrderCountAtom)

    const setPositionHistory = useSetAtom(UserPositionHistoryAtom)
    const setHistoryCount = useSetAtom(UserPositionHistoryCountAtom)



    useMemo(async () => {


        getUserOrderList().then(([data, err]) => {
            if (err) {
                return
            }
            setOrderCount(data.length)
            setOrders(data)
        })

        getUserPositionHistory().then(([data, err]) => {

            if (err) {
                return
            }

            setHistoryCount(data.trades.length)
            setPositionHistory(data.trades)
        })

    }, [])


    useEffect(() => {

        const showPositionHistoryDetailEvent = onEvent(eventConstants.SHOW_POSITION_HISTORY_DETAIL, (data: PositionHistoryItem) => {
            setPositionHistoryDetail(data)
        })

        const closePositionHistoryDetailEvent = onEvent(eventConstants.CLOSE_POSITION_HISTORY_DETAIL, () => {
            setPositionHistoryDetail(undefined)
        })

        const showSymbolInformationEvent = onEvent(eventConstants.SHOW_SYMBOL_INFORMATION, (data: SymbolData) => {
            setSymbolInformation(data)
        })

        const closeSymbolInformationEvent = onEvent(eventConstants.CLOSE_SYMBOL_INFORMATION, () => {
            setSymbolInformation(undefined)
        })

        const closeSymbolSettings = onEvent(eventConstants.CLOSE_SYMBOL_SETTINGS, () => {
            setSymbolSettings(null)
        })

        const positionsUpdatedEvent = onEvent(eventConstants.POSITION_PNL_UPDATED, (updatedPositions: UpdatedPosition[]) => {
            zusSetUpdatedPositions(updatedPositions)
        })

        const resetPositionsEvent = onEvent(eventConstants.REMOVE_POSITION_FROM_LIST, (positionId: string) => {

            zusDeletePosition(positionId)
            zusDeleteUpdatedPosition(positionId)
        })

        const refetchPositionsEvent = onEvent(eventConstants.RE_FETCH_POSITIONS, async () => {
            const [data, err] = await getUserPositionList()

            if (err) {
                return
            }

            zusSetPosition(data)
        })


        const onOrderCloseEvent = onEvent(eventConstants.ON_ORDER_CLOSE, () => {

            getUserPositionHistory().then(([data, err]) => {

                if (err) {
                    return
                }

                setHistoryCount(data.trades.length)
                setPositionHistory(data.trades)
            })

        })

        let preSelectedSymbol: string | null = params.symbol

        const selectSymbolEvent = onEvent(eventConstants.SELECT_SYMBOL, (symbolName: string) => {
            const found = symbols?.find(item => item.Symbol === symbolName)
            if (found) {
                setSelectedSymbol(found)
                // const newSettings: SymbolSettings = {
                //     symbol: found,
                //     sl: null,
                //     tp: null,
                //     volume: "0.01",
                //     mode: "BUY"
                // }
                // setSymbolSettings({ ...newSettings })
            }
        })

        const setSymbolSettingsEvent = onEvent(eventConstants.SET_SYMBOL_SETTINGS, (symbolName: string) => {
            const found = symbols?.find(item => item.Symbol === symbolName)
            if (found) {
                const newSettings: SymbolSettings = {
                    symbol: found,
                    sl: null,
                    tp: null,
                    volume: "0.01",
                    mode: "BUY"
                }
                setSymbolSettings({ ...newSettings })
            } else {
                setSymbolSettings(null)
            }
        })

        if (preSelectedSymbol && !selectedSymbol) {
            const found = symbols?.find(item => item.Symbol === preSelectedSymbol)
            if (found) {
                setSelectedSymbol(found)
                setSymbolSettings({
                    symbol: found,
                    sl: params.sl,
                    tp: params.tp,
                    volume: params.volume ?? "0.01",
                    mode: params.mode as "BUY" | "SELL" ?? "BUY"
                })
            }
        }

        if (!preSelectedSymbol && !selectedSymbol) {
            const found = symbols?.find(item => item.Symbol === "GBPTRY")
            if (found) {
                setSelectedSymbol(found)
            }
        }

        return () => {
            selectSymbolEvent.unsubscribe()
            setSymbolSettingsEvent.unsubscribe()
            resetPositionsEvent.unsubscribe()
            positionsUpdatedEvent.unsubscribe()
            closeSymbolSettings.unsubscribe()
            showSymbolInformationEvent.unsubscribe()
            closeSymbolInformationEvent.unsubscribe()
            showPositionHistoryDetailEvent.unsubscribe()
            closePositionHistoryDetailEvent.unsubscribe()
            refetchPositionsEvent.unsubscribe()
            onOrderCloseEvent.unsubscribe()
        }

    }, [selectedSymbol])

    return <>
        <div className="flex flex-col px-2">
            <div className="flex flex-col lg:flex-row gap-1 w-full min-h-[70vh] ">
                <div className="flex flex-col flex-1 w-full ">
                    {!selectedSymbol && <div className="flex flex-1 flex-col items-center justify-center">Sembol Seçin</div>}
                    {selectedSymbol && <Chart selectedSymbol={selectedSymbol} />}
                </div>
                <SymbolSearch />
                {symbolSettings && <SymbolDetails key={symbolSettings.volume} symbolSettings={symbolSettings} />}
                {symbolInformation && <SymbolInformationDrawer symbolInformation={symbolInformation} />}
                {positionHistoryDetail && <PositionHistoryDetailDrawer positionHistoryDetail={positionHistoryDetail} />}
            </div>

            <div className="flex flex-col  h-screen mt-16 items-center">
                <div className="container h-full gap-4 flex flex-col">
                    <h1 className="font-bold lg:text-4xl text-2xl">Detaylar</h1>
                    <StatsContainer />
                </div>
            </div>
        </div>
    </>
}

export default Panel
