import { SymbolCategoriesAtom } from "@/atoms";
import { useAtomValue } from "jotai";
import { FormEvent } from "react";
import MobileBottomDrawer from "./MobileBottomDrawer";

export default function ({ onClose, onSubmit }: { onClose: () => void, onSubmit: (event: FormEvent<HTMLFormElement>) => void }) {

    const categories = useAtomValue(SymbolCategoriesAtom)
    return <MobileBottomDrawer outsideClicked={onClose}>
        {/* Put this part before </body> tag */}
        <div className="flex flex-col mb-4 gap-3">
            <h1 className="text-semibold text-xl">İşlem Açma Önerisi</h1>
            <p className="text-sm">
                Öneri alma istediğiniz kategorileri seçiniz. Dilerseniz, aynı anda birden fazla kategori seçebilirsiniz.
            </p>
        </div>
        <form onSubmit={onSubmit}>
            <div className="flex flex-col gap-2">
                {categories?.map(category => {

                    return <div key={category._id} className="form-control p-2 bg-base-100 rounded-xl">
                        <label className="label cursor-pointer">
                            <span className="label-text">{category.name}</span>
                            <input type="checkbox" className="checkbox checked:checkbox-primary" name="categories" value={category._id} />
                        </label>
                    </div>
                })}

            </div>
            <div className="mt-4"></div>
            <div className="flex mt-2">
                <button className="btn w-full btn-primary">Talep Et</button>
            </div>
        </form>
    </MobileBottomDrawer>
}