import Error from "@/error";
import { Result } from "@/types";
import http from "./http";


export async function acceptSuggestion(suggestionId: string): Promise<Result<any, Error>> {

    try {
        const res = await http.post("user/suggestion_done", { suggestionId })
        const data: any = res.data.data

        return [data, null]

    } catch (err) {

        return [null, new Error("Error while rejecting suggestion", { cause: err })]
    }
}
export async function rejectSuggestion(suggestionId: string): Promise<Result<any, Error>> {

    try {
        const res = await http.post("user/suggestion_reject", { suggestionId })
        const data: any = res.data.data

        return [data, null]

    } catch (err) {

        return [null, new Error("Error while rejecting suggestion", { cause: err })]
    }
}

export async function suggestionProposal(categories: string): Promise<Result<any, Error>> {
    try {
        const res = await http.post("user/suggestion_request", { categories })
        const data: any = res.data.data

        return [data, null]

    } catch (err) {

        return [null, new Error("Error while rejecting suggestion", { cause: err })]
    }
}