import { FavouritesAtom, SymbolsAtom } from "@/atoms"
import { Symbol, SymbolList, SymbolSettings } from "@/types";
import { getSymbolData, isFavourite, openPositionOrOrder, setFavouriteSymbol, unsetFavouriteSymbol } from "@/utils";
import { useAtom, useAtomValue } from "jotai"
import { useMemo, useRef, useState } from "react";
import PriceText from "./PriceText";
import Collapse from "./Collapse";
import RangeInput from "./RangeInput";
import { emitEvent, eventConstants } from "../main";
import NumberInputForRange from "./ui/NumberInputForRange";
import NumberInput from "./ui/NumberInput.tsx";

export default function ({ symbolSettings }: { symbolSettings: SymbolSettings }) {

    const [favourites, setFavourites] = useAtom(FavouritesAtom)
    const [mode, setMode] = useState(symbolSettings.mode)
    const symbols = useAtomValue(SymbolsAtom) as SymbolList
    const [lot, setLot] = useState(parseFloat(symbolSettings.volume))
    const drawerRef = useRef<HTMLInputElement | null>(null)
    const [positionSettings, setPositionSettings] = useState<{ order: null | number, stopLoss: null | number, takeProfit: null | number }>
        ({
            order: null,
            stopLoss: symbolSettings.sl ? parseFloat(symbolSettings.sl) : null,
            takeProfit: symbolSettings.tp ? parseFloat(symbolSettings.tp) : null,
        })

    const index = useMemo(() => {
        return symbols.findIndex(item => item.Symbol === symbolSettings.symbol?.Symbol) as number
    }, [symbols])

    async function setFavourite(symbol: Symbol) {
        const [res, err] = await setFavouriteSymbol(symbol);

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Sembol favorilere eklendi!" })
        setFavourites(res)
    }

    async function unsetFavourite(symbol: Symbol) {
        const [res, err] = await unsetFavouriteSymbol(symbol);

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Sembol favorilerden kaldırıldı!" })

        setFavourites(res)
    }

    function setTransactionMode(mode: "BUY" | "SELL") {
        setMode(mode)
    }

    function handleLotChange(val: string) {
        if (parseFloat(val) < 0.01) {
            return
        }

        if (parseFloat(val) > 25.00) {
            return
        }
        setLot(parseFloat(val))
    }

    function handleCheckedSetting(setting: "ORDER" | "STOP_LOSS" | "TAKE_PROFIT") {

        const price = mode === "BUY" ? symbolSettings.symbol?.Bid : symbolSettings.symbol?.Ask;
        if (setting === "ORDER") {
            setPositionSettings({ ...positionSettings, order: parseFloat(price as string) })
        }

        else if (setting === "STOP_LOSS") {
            setPositionSettings({ ...positionSettings, stopLoss: parseFloat(price as string) })
        }

        else {
            setPositionSettings({ ...positionSettings, takeProfit: parseFloat(price as string) })
        }
    }



    function handleUncheckSetting(setting: "ORDER" | "STOP_LOSS" | "TAKE_PROFIT") {

        if (setting === "ORDER") {
            setPositionSettings({ ...positionSettings, order: null })
        }

        else if (setting === "STOP_LOSS") {
            setPositionSettings({ ...positionSettings, stopLoss: null })
        }

        else {
            setPositionSettings({ ...positionSettings, takeProfit: null })
        }
    }


    function handleOrderChange(val: number) {
        setPositionSettings({ ...positionSettings, order: val })
    }

    function handleStopLossChange(val: number) {

        if (mode === "BUY" && val > parseFloat(symbolSettings.symbol.Bid)) {
            setPositionSettings({ ...positionSettings, stopLoss: parseFloat(symbolSettings.symbol.Bid) })
            return
        }

        if (mode === "SELL" && val < parseFloat(symbolSettings.symbol.Ask)) {
            setPositionSettings({ ...positionSettings, stopLoss: parseFloat(symbolSettings.symbol.Ask) })
            return
        }




        setPositionSettings({ ...positionSettings, stopLoss: val })

    }

    function handleTakeProfitChange(val: number) {

        if (mode === "BUY" && val < parseFloat(symbolSettings.symbol.Bid)) {
            setPositionSettings({ ...positionSettings, takeProfit: parseFloat(symbolSettings.symbol.Bid) })
            return
        }

        if (mode === "SELL" && val > parseFloat(symbolSettings.symbol.Ask)) {
            setPositionSettings({ ...positionSettings, takeProfit: parseFloat(symbolSettings.symbol.Ask) })
            return
        }

        setPositionSettings({ ...positionSettings, takeProfit: val })
    }


    async function submitRequest(type: number) {
        const [_, err] = await openPositionOrOrder({ emir: positionSettings.order ?? "0.0", lot: lot, sl: positionSettings.stopLoss ?? "0.0", tp: positionSettings.takeProfit ?? "0.0", Type: type, Symbol: symbolSettings.symbol?.Symbol as string, digit: symbolSettings.symbol?.Digits as number })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.CLOSE_SYMBOL_SETTINGS, "")
        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Pozisyon başarıyla açıldı." })
        emitEvent(eventConstants.RE_FETCH_POSITIONS, "")
    }

    async function showSymbolInformation(symbolName: string) {
        const [data, err] = await getSymbolData({ symbolName })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.SHOW_SYMBOL_INFORMATION, data)
    }

    return <>

        <div className="drawer drawer-end fixed z-[9998]">
            <input ref={drawerRef} id="symbolDetailsDrawer" type="checkbox" className="drawer-toggle" defaultChecked onChange={e => {
                if (!e.target.checked) {
                    emitEvent(eventConstants.SET_SYMBOL_SETTINGS, null)
                }
            }} />
            <div className="drawer-content">
            </div>
            <div className="drawer-side" >

                <label htmlFor="symbolDetailsDrawer" aria-label="close sidebar" className="drawer-overlay"></label>
                <ul className="menu p-4 min-h-full bg-base-100 text-base-content max-w-[500px] w-full">
                    {/* Sidebar content here */}
                    <div className="flex justify-start item-center">
                        <button className="btn btn-sm bg-base-100 btn-circle" onClick={() => {
                            emitEvent(eventConstants.SET_SYMBOL_SETTINGS, null)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                        </button>

                    </div>
                    <div className=" flex flex-col gap-4 rounded-lg p-4">
                        <div className="flex items-center gap-4">
                            <img className="h-8" src={`https://cdn.fxcrm.me/parite/${symbolSettings.symbol.Symbol.toLowerCase()}.png`} />
                            <p className="font-bold">{symbolSettings.symbol.displayName}</p>

                            <div className="flex-1 flex justify-end gap-2">
                                {isFavourite(favourites, symbolSettings.symbol.Symbol) ? <button onClick={() => unsetFavourite(symbolSettings.symbol)} className={"btn p-1 h-7 min-h-[30px] text-primary"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                    </svg>
                                </button> : <button onClick={() => setFavourite(symbolSettings.symbol)} className={"btn p-1 h-7 min-h-[30px]"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                    </svg>
                                </button>}
                                <button onClick={() => showSymbolInformation(symbolSettings.symbol.Symbol)} className={"btn p-1 h-7 min-h-[30px]"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                    </svg>

                                </button>

                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex justify-between">
                                <p>Alış Fiyatı</p>
                                <p className="font-bold"><PriceText value={symbols[index]?.Bid} /> </p>

                            </div>
                            <div className="flex justify-between">
                                <p>Satış Fiyatı</p>
                                <p className="font-bold"><PriceText value={symbols[index]?.Ask} /> </p>
                            </div>
                        </div>
                    </div>

                    <div className="bg-base-100 flex gap-4 rounded-lg p-4">
                        <div onClick={() => setTransactionMode("BUY")} className={`font-bold bg-base-200 flex p-2 rounded-lg flex-1 cursor-pointer border-dashed border hover:border-success ${mode === "BUY" ? "border-success" : " border-neutral"}`}>
                            <p className="flex-1">{symbols[index]?.Bid}</p>
                            <button className=" rounded px-2 text-sm flex items-center uppercase text-white bg-success">Al</button>
                        </div>
                        <div onClick={() => setTransactionMode("SELL")} className={`font-bold bg-base-200 flex p-2 rounded-lg flex-1 cursor-pointer border border-dashed hover:border-error ${mode === "SELL" ? "border-error" : " border-neutral"}`}>
                            <p className="flex-1">{symbols[index]?.Ask}</p>
                            <div className=" rounded px-2 text-sm flex items-center uppercase text-white bg-error">Sat</div>
                        </div>
                    </div>
                    <div className="bg-base-100 flex flex-col gap-4 rounded-lg p-4">
                        <p className="text-sm">Pozisyon Miktarı</p>
                        <NumberInputForRange onChange={(val) => handleLotChange(val?.toFixed(2) as string)} externalValue={isNaN(lot) ? "0.01" : lot.toString()} min={0.01} max={5.00} />
                        <div className="text-center">
                            <p className="font-bold text-primary">Lot</p>
                        </div>
                        <RangeInput min={0.01} max={5.00} value={lot.toFixed(2)} step={0.01} onChange={(e) => handleLotChange(e.target.value)} />
                    </div>

                    <div className="bg-base-100 flex flex-col gap-2 rounded-lg p-4">
                        <p className="text-sm">Pozisyon Ayarları</p>

                        <Collapse title="Emir Ver" defaultValue={positionSettings.order ? true : false} onChecked={() => handleCheckedSetting("ORDER")} onUnchecked={() => handleUncheckSetting("ORDER")}>
                            <NumberInput digits={symbolSettings.symbol.Digits} onChange={(val) => {
                                if (val) {
                                    handleOrderChange(val)
                                }
                            }} defaultValue={positionSettings?.order ? positionSettings.order : 0} />
                        </Collapse>
                        <Collapse title="Zarar Durdur" defaultValue={positionSettings.stopLoss ? true : false} onChecked={() => handleCheckedSetting("STOP_LOSS")} onUnchecked={() => handleUncheckSetting("STOP_LOSS")}>
                            <NumberInput digits={symbolSettings.symbol.Digits} onChange={(val) => {
                                if (val) {
                                    handleStopLossChange(val)
                                }
                            }} defaultValue={positionSettings?.stopLoss ? positionSettings.stopLoss : 0} />
                        </Collapse>
                        <Collapse title="Kar Al" defaultValue={positionSettings.takeProfit ? true : false} onChecked={() => handleCheckedSetting("TAKE_PROFIT")} onUnchecked={() => handleUncheckSetting("TAKE_PROFIT")}>

                            <NumberInput digits={symbolSettings.symbol.Digits} onChange={(val) => {
                                if (val) {
                                    handleTakeProfitChange(val)
                                }
                            }} defaultValue={positionSettings?.takeProfit ? positionSettings.takeProfit : 0} />
                        </Collapse>

                    </div>

                    {mode === "BUY" ? <button onClick={() => submitRequest(0)} className="btn w-full btn-success text-sm font-bold text-white">Al</button> : <button onClick={() => submitRequest(1)} className="btn w-full btn-error text-sm font-bold text-white">Sat</button>}

                </ul>
            </div>
        </div >

    </>

}