import { getBasketList, getTrader, getTraderComments, getTraderInfo, getTraderOrders, getTraderPositionHistory, getTraderPositions } from "@/utils";
import { LoaderFunctionArgs } from "react-router-dom";
import { progress } from "../../main";


export default function ({ params }: LoaderFunctionArgs) {

    const { traderId } = params;

    return new Promise(async (res, _) => {
        progress.start()

        if (!traderId) return res(false)

        const [traderData, traderErr] = await getTrader({ traderId: traderId })

        if (traderErr) {
            return res(false)
        }

        const [traderInfoPromise, traderPositionPromise, traderOrdersPromise, traderHistoryPromise, traderCommentsPromise, traderBasketsPromise] = await Promise.all([getTraderInfo({ MetaUser: traderData.traderNo.toString() }), getTraderPositions({ MetaUser: traderData.traderNo.toString() }), getTraderOrders({ MetaUser: traderData.traderNo.toString() }), getTraderPositionHistory({ MetaUser: traderData.traderNo.toString() }), getTraderComments({ page: 1, traderId }), getBasketList(traderId)])

        const [traderInfo, traderInfoError] = traderInfoPromise

        if (traderInfoError) {
            return res(false)
        }

        const [traderPositions, traderPositionsError] = traderPositionPromise

        if (traderPositionsError) {
            return res(false)
        }

        const [traderOrders, traderOrdersError] = traderOrdersPromise

        if (traderOrdersError) {
            return res(false)
        }

        const [traderPositionHistory, traderPositionHistoryError] = traderHistoryPromise

        if (traderPositionHistoryError) {
            return res(false)
        }


        const [traderComments, traderCommentsError] = traderCommentsPromise

        if (traderCommentsError) {
            return res(false)
        }


        const [traderBaskets, traderBasketsError] = traderBasketsPromise


        if (traderBasketsError) {
            return res(false)
        }

        progress.done()


        return res({ traderData, traderInfo, traderPositions, traderOrders, traderPositionHistory, traderComments, traderBaskets, traderId })
    })
}