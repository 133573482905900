import { Basket, Trader } from "@/types";
import ClientPaginatedList from "./ClientPaginatedList";
import { emitEvent, eventConstants } from "../main";
import { DRAWER_TYPES } from "./DrawerController";

export default function ({ baskets, trader }: { baskets: Basket[], trader: Trader }) {

    function handleBasketOpen(traderId: string, id: string) {
        emitEvent(eventConstants.OPEN_BASKET_WITH_SELECTED, { traderId, selectedBasketId: id });
    }


    function openCreateBasket() {
        emitEvent(eventConstants.OPEN_DRAWER, DRAWER_TYPES.CREATE_BASKET)
    }

    return <>

        {baskets.length === 0 ? <div className="flex flex-col gap-3 items-center justify-center bg-base-200 rounded-lg p-4 max-h-[500px] h-[75vh]">
            <span>Herhangi bir sepet bulunamadı!</span>

            {trader.owner && <button className="btn btn-primary btn-wide btn-sm" onClick={openCreateBasket}>Sepet Yarat</button>}
        </div> : <>

            <ClientPaginatedList<Basket> items={baskets} length={baskets.length} listComponent={({ item }) => {
                return <div key={item._id} onClick={() => handleBasketOpen(trader._id, item._id)} className="bg-base-200 p-4 rounded-lg flex hover:bg-base-300 cursor-pointer">

                    <div className="flex flex-col flex-1 gap-2">
                        <span className="font-semibold">{item.name} </span>
                        <div className="flex gap-2 items-center text-sm opacity-75">
                            <span>Sepetteki pozisyon sayisi: {item.positionBasketItems.length}</span>
                        </div>
                    </div>
                    <div className={"flex items-center font-semibold text-lg"}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </div>
                </div>
            }} />

            <div className="flex justify-center mt-4">
                <button className="btn btn-circle hover:btn-primary flex justify-center items-center" onClick={openCreateBasket}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </>
        }



    </>
}