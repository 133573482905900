import { Trader } from "@/types";
import CustomLink from "../CustomLink";
import { getStarRating } from "@/utils";

export default function ({ item, onBlock, onFollow, onReport }: { item: Trader, onBlock: (id: string) => void, onFollow: (trader: Trader) => void, onReport: (trader: Trader) => void }) {

    return <div className="p-4 flex flex-col rounded-md content-container">
        <div className="flex items-start justify-between">
            <div className="flex gap-3 flex-1">
                <div className="avatar w-12 h-12">
                    <div className="w-full rounded-full">
                        <img src={item.photo} />
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <div className="text-sm font-semibold flex items-start pr-2 gap-2">

                        <CustomLink to={"/traders/" + item._id} className="text-sm font-semibold hover:text-primary hover:underline">{item.name}</CustomLink>
                        {item.isVerified ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-info">
                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                        </svg> : null}
                    </div>

                    <div className="flex items-center gap-3">
                        <div className="flex items-center gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 fill-base-content">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>

                            <p className="text-sm text-base-content">{item.followers}</p>
                        </div>
                        <div className="flex text-primary items-center gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 fill-primary">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                            </svg>
                            <p className="text-sm text-base-content">{getStarRating(item)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex gap-2">
                {item.owner ? null : <><button onClick={() => onReport(item)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5" />
                    </svg>
                </button>
                    <button onClick={() => onBlock(item._id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                        </svg>
                    </button></>}

            </div>
        </div>


        <div className="flex flex-1 gap-2 items-end justify-between mt-4">

            <div className="text-xs flex items-center gap-2">
                <span>Son ROI</span>
                <span className={parseInt(item.lastRoi) >= 0 ? "text-success" : "text-error"}>{item.lastRoi}%</span>
            </div>

            <div className="text-xs flex items-center gap-2">
                <span>Pozisyonlar</span>
                <span>{item.positionCount}</span>
            </div>

            <div className="text-xs flex items-center gap-2">
                <span>Emirler</span>
                <span>{item.orderCount}</span>
            </div>

        </div>

        {item.owner ? null : <div className="mt-4">
            <button className={"btn btn-sm w-full " + (item.isFollowing ? "btn-secondary" : "btn-primary")} onClick={() => onFollow(item)}>
                {!item.isFollowing ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                    <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path d="M10.375 2.25a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25zM10.375 12a7.125 7.125 0 00-7.124 7.247.75.75 0 00.363.63 13.067 13.067 0 006.761 1.873c2.472 0 4.786-.684 6.76-1.873a.75.75 0 00.364-.63l.001-.12v-.002A7.125 7.125 0 0010.375 12zM16 9.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6z" />
                    </svg>

                }

                {!item.isFollowing ? "Takip Et" : "Takipten Çık"}
            </button>
        </div>}

    </div>
}
