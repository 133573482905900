import { Banner, Result } from "@/types";
import http from "./http";



export async function getBanners(): Promise<Result<Banner[], Error>> {

    try {
        const res = await http.get("api/banner", { params: { lang: "tr" } })
        const data: Banner[] = res.data

        return [data, null]

    } catch (error) {
        return [null, new Error("Error while retrieving banners")]
    }

}