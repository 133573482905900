

// I need to take some items
// I need to determine how many items i want to show at a timie
// I need to be able to render a custom component inside

import React, { useMemo, useState } from "react"


type ListComponentProps<T> = {
    item: T,
}

type ClientPaginatedListProps<T> = {
    length: number,
    paginationLength?: number,
    items: T[],
    listComponent: ({ item }: ListComponentProps<T>) => React.ReactNode
    fallback?: () => React.ReactNode | null
}

export default function <T>({ length, items, listComponent, fallback, paginationLength = 10 }: ClientPaginatedListProps<T>) {

    const [currentPage, setCurrentPage] = useState(1)

    const maxPage = Math.ceil(length / paginationLength)


    const visibleItems = useMemo(() => {

        return items.slice(0, paginationLength * currentPage)

    }, [currentPage, items])

    const nextPage = (currentPage: number) => {
        if (currentPage === maxPage) {
            return
        }

        setCurrentPage(prev => prev + 1)
    }

    return <> {length === 0 ? fallback && fallback() : <div className="flex flex-col gap-3">
        {visibleItems.map((item) => {
            const component = listComponent({ item })

            return component
        })}

        {currentPage !== maxPage ? <div className="flex justify-center">
            <button className="btn" onClick={() => nextPage(currentPage)}>
                DAHA FAZLA YÜKLE</button>
        </div> : <></>}
    </div>}


    </>

}

