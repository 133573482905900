import { Trader } from "@/types";
import { FormEvent, useEffect, useState } from "react";
import RangeInput from "./RangeInput";
import { getTraderFollowingOptions } from "@/utils";

export default function ({ trader, onClose, onSubmit, onUnfollow }: { trader: Trader, onClose: () => void, onSubmit: (event: FormEvent<HTMLFormElement>) => void, onUnfollow: (traderId: string) => void }) {

    const [lotValue, setLotValue] = useState(0.0)
    const [defaultValues, setDefaultValues] = useState<Record<string, boolean>>({
        "POSITION_OPEN": false,
        "POSITION_CLOSE": false,
        "POSITION_EDIT": false,
        "ORDER_OPEN": false,
        "ORDER_CLOSE": false,
    })

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getTraderFollowingOptions(trader._id).then(([data, err]) => {

            if (err) {
                setLoading(false)

                return
            }

            setLotValue(data.lotPercentage)


            setDefaultValues(prev => {

                data.options.forEach(item => {
                    prev[item] = true
                })

                return { ...prev }
            })
            setLoading(false)


        })
    }, [])

    return <>
        {/* Put this part before </body> tag */}
        <input type="checkbox" id="my_modal_6" className="modal-toggle" checked />
        <div className="modal">
            <div className="modal-box">
                {loading ?
                    <span className="loading loading-spinner loading-md"></span>
                    : <form onSubmit={onSubmit}>
                        <input type="hidden" name="traderId" value={trader._id} readOnly />
                        <h3 className="font-bold text-lg">Pozisyon</h3>
                        <div className="flex flex-col gap-2 mt-4">
                            <div className="form-control p-2 bg-base-100 rounded-xl">
                                <label className="label cursor-pointer">
                                    <span className="label-text">Pozisyon Aç</span>
                                    <input type="checkbox" className="checkbox checkbox-primary" name="option" defaultChecked={defaultValues.POSITION_OPEN} value={"POSITION_OPEN"} />
                                </label>
                            </div>
                            <div className="form-control p-2 bg-base-100 rounded-xl">

                                <label className="label cursor-pointer">
                                    <span className="label-text">Pozisyon Düzenle</span>
                                    <input type="checkbox" className="checkbox checkbox-primary" name="option" defaultChecked={defaultValues.POSITION_EDIT} value={"POSITION_EDIT"} />
                                </label>
                            </div>
                            <div className="form-control p-2 bg-base-100 rounded-xl">

                                <label className="label cursor-pointer">
                                    <span className="label-text">Pozisyon Kapa</span>
                                    <input type="checkbox" className="checkbox checkbox-primary" name="option" defaultChecked={defaultValues.POSITION_CLOSE} value={"POSITION_CLOSE"} />
                                </label>
                            </div>
                        </div>

                        <div className="mt-4"></div>
                        <h3 className="font-bold text-lg">Emir</h3>
                        <div className="flex flex-col gap-2 mt-4">
                            <div className="form-control p-2 bg-base-100 rounded-xl">
                                <label className="label cursor-pointer">
                                    <span className="label-text">Emir Aç</span>
                                    <input type="checkbox" className="checkbox checkbox-primary" name="option" defaultChecked={defaultValues.ORDER_OPEN} value={"ORDER_OPEN"} />
                                </label>
                            </div>
                            <div className="form-control p-2 bg-base-100 rounded-xl">
                                <label className="label cursor-pointer">
                                    <span className="label-text">Emir Kapa</span>
                                    <input type="checkbox" className="checkbox checkbox-primary" name="option" defaultChecked={defaultValues.ORDER_CLOSE} value={"ORDER_CLOSE"} />
                                </label>
                            </div>
                        </div>



                        <div className="mt-4"></div>
                        <h3 className="font-bold text-lg">Pozisyon Boyutu</h3>

                        <div className="flex flex-col p-4 gap-1 rounded-xl bg-base-100 my-4">
                            <div className="flex items-center justify-evenly">
                                <div onClick={() => setLotValue(25)} className="btn btn-primary btn-sm rounded-full text-primary-content">25%</div>
                                <div onClick={() => setLotValue(50)} className="btn btn-primary btn-sm rounded-full text-primary-content">50%</div>
                                <div onClick={() => setLotValue(75)} className="btn btn-primary btn-sm rounded-full text-primary-content">75%</div>
                                <div onClick={() => setLotValue(100)} className="btn btn-primary btn-sm rounded-full text-primary-content">100%</div>
                            </div>

                            <div className="mt-3 mb-3">
                                <RangeInput min={0} max={100.00} value={lotValue} onChange={e => setLotValue(parseFloat(e.target.value))} step={1} name="lotPercentage" />
                            </div>

                            <h4 className="text-center font-bold text-primary">{lotValue}%</h4>
                        </div>

                        <div className="flex gap-2 mt-4">
                            <button className="btn flex-1 btn-primary">Güncelle</button>
                            <div className="btn flex-1 btn-secondary" onClick={() => onUnfollow(trader._id)}>Takipten Çık</div>
                        </div>
                    </form>
                }

            </div>
            <label className="modal-backdrop" htmlFor="my_modal_6" onClick={onClose}>Close</label>
        </div>
    </>
}