import { MarginsAtom, UserAtom } from "@/atoms";
import { PositionHistoryItem } from "@/types";
import { calculateTraderHistoryPNL, } from "@/utils";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

export default function (positions: PositionHistoryItem[]) {
    const margins = useAtomValue(MarginsAtom)
    const user = useAtomValue(UserAtom)

    const result: PositionHistoryItem[] = useMemo(() => {

        if (typeof positions === undefined) {
            return []
        }

        if (positions.length === 0) {
            return positions
        }

        if (!margins || !user) {
            console.log("NO USER OR MARGINS")
            return positions
        }

        return positions.map(item => {

            const calRes = calculateTraderHistoryPNL(positions, {
                id: item.PositionID,
                price: item.Price,
                priceCurrent: parseFloat(item.PricePosition),
                profit: parseFloat(item.Profit) + parseFloat(item.Storage),
                swap: parseFloat(item.Storage)
            }, margins, parseFloat(user.MarginLeverage))

            if (!calRes) {
                return item
            }

            return {
                ...item,
                Profit: calRes?.profit.toFixed(2),
                RateProfit: calRes?.rate.toString()
            }
        })
    }, [positions])


    return result
}