import { DepositHistoryItem } from "@/types"
import { formatDateTime } from "@/utils"
import { useLoaderData } from "react-router-dom"

export default function () {

    const { history } = useLoaderData() as { history: DepositHistoryItem[] }


    function getItemType(type: string) {
        const types: Record<string, string> = {
            "bank": "Banka Transferi",
            "card": "Kredi Kartı",
            "coin": "Kripto Tranferi"
        }

        return types[type]
    }

    return <div className="flex flex-col flex-1 prose max-w-none  gap-4">
        <h1 className="text-xl">Para Yatırma Geçmişi</h1>
        <hr />

        <div className="flex-1 overflow-y-auto pt-4">
            <div className="flex flex-col gap-3 lg:h-[1px]">

                {history.map(item => {
                    return <div key={item._id} className="flex lg:gap-6 gap-3  items-center content-container lg:p-4 p-2 rounded-lg">

                        {item.onay === 1 && <div className="text-success">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="lg:w-10 lg:h-10 w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>}
                        {item.onay === 2 && <div className="text-error">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="lg:w-10 lg:h-10 w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                        </div>}
                        {item.onay === 0 && <div className="text-warning">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="lg:w-10 lg:h-10 w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                            </svg>

                        </div>}

                        <div className="flex flex-col gap-2">
                            <span className="text-xs lg:text-base">{getItemType(item.type)}  {item.type === "coin" && <><span className="text-xs lg:text-base"> - {item.coin.toUpperCase()}</span> <span className="text-xs lg:text-base"> - {item.ag.toUpperCase()}</span></>}</span>
                            {/* {item.type === "coin" && <span className="text-xs lg:text-sm">TxID: {item.adres}</span>}
                            {item.type === "bank" && <span className="text-xs lg:text-sm">{item.coin}</span>} */}

                            <span className="text-xs">{formatDateTime(new Date(item.create_date))}</span>
                        </div>

                        <div className="flex flex-1 items-center justify-end">
                            <span className="text-success font-semibold text-xs lg:text-base">{item.miktar.toFixed(2)} {item.doviz}</span>
                        </div>
                    </div>
                })}

            </div>
        </div>
    </div>
} 