import Error from "../error";
import { CHART_TIMES, ChartData, FavouritesList, MarginInitialRecords, Result, Symbol, SymbolChartItem, SymbolData, SymbolResult } from "../types";
import http from "./http";


export async function getSymbols(): Promise<Result<SymbolResult, Error>> {
    try {
        const res = await http.get("user/symbol_v4")

        const data: SymbolResult = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting symbols", { cause: err })]
    }
}


export async function getBestSymbols(): Promise<Result<string[], Error>> {

    try {
        const res = await http.get("api/bestparite")

        const data: string[] = res.data.veri

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting symbols", { cause: err })]
    }
}


export async function getSymbolChart(): Promise<Result<SymbolChartItem[], Error>> {
    try {
        const res = await http.get("api/SymbolChart")
        const data: SymbolChartItem[] = res.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting symbols", { cause: err })]
    }
}

export async function getMarginInitials(): Promise<Result<MarginInitialRecords, Error>> {
    try {
        const res = await http.get("api/margin_initials")
        const data: MarginInitialRecords = res.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting symbols", { cause: err })]
    }
}

export async function setFavouriteSymbol(symbol: Symbol): Promise<Result<FavouritesList, Error>> {

    try {
        const res = await http.post("user/FavSet", { symbol: symbol.Symbol })

        if (!res.data.status) {
            throw new Error("Error while setting favourite symbol")
        }
        const data: FavouritesList = res.data.fav
        return [data, null]

    } catch (err) {
        return [null, new Error("Error while setting favourite symbol", { cause: err })]
    }

}

export async function unsetFavouriteSymbol(symbol: Symbol): Promise<Result<FavouritesList, Error>> {

    try {
        const res = await http.post("user/FavDelete", { symbol: symbol.Symbol })

        if (!res.data.status) {
            throw new Error("Error while un-setting favourite symbol")
        }
        const data: FavouritesList = res.data.fav
        return [data, null]

    } catch (err) {
        return [null, new Error("Error while un-setting favourite symbol", { cause: err })]
    }

}

export async function symbolGraphData(opt: { symbolName: string, time: CHART_TIMES }): Promise<Result<ChartData[], Error>> {

    try {
        const res = await http.post("user/graph", { symbolName: opt.symbolName, time: opt.time, m: 1 })


        const data: ChartData[] = res.data.data.map((item: any) => {
            return {
                "timestamp": item.time * 1000,
                "open": item.open,
                "high": item.high,
                "low": item.low,
                "close": item.close,

            }
        })


        return [data, null]

    } catch (err) {
        return [null, new Error("Error while un-setting favourite symbol", { cause: err })]
    }

}



export async function getSymbolData(opt: { symbolName: string }): Promise<Result<SymbolData, Error>> {

    try {
        const res = await http.post("user/symbol_data", {
            "m": 1,
            symbolName: opt.symbolName
        })

        const data: SymbolData = res.data.data

        return [data, null]

    } catch (err) {
        return [null, new Error("Error while getting symbol data", { cause: err })]
    }


}