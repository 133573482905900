import useExchangeRate from "@/hooks/useExchangeRate"
import { setCryptoWithdrawRequest } from "@/utils"
import { FormEvent, useState } from "react"
import { emitEvent, eventConstants } from "../main"

type Currency = {
    id: number,
    currency: string
}

const currencies: Currency[] = [
    {
        id: 1,
        currency: "TL",
    },
    {
        id: 2,
        currency: "USD",
    }
]


export default function () {
    const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(currencies[1])

    const renderCurrencySelection = () => {

        if (selectedCurrency) return null

        return currencies.map(item => {
            return <button key={item.id} onClick={() => setSelectedCurrency(item)} className="btn max-h-[30%] h-full flex-item bg-base-100 flex flex-col gap-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                </svg>
                <p className="leading-5">
                    {item.currency}
                </p>
            </button>
        })
    }


    const renderDepositForm = () => {
        if (!selectedCurrency) return null

        return <WithdrawForm selectedCurrency={selectedCurrency} />
    }

    return <div className="flex flex-1 gap-3 flex-wrap">
        {renderCurrencySelection()}
        {renderDepositForm()}
    </div>
}



function WithdrawForm({ selectedCurrency }: { selectedCurrency: Currency }) {

    const { exchangeRate } = useExchangeRate()
    const [value, setValue] = useState("0")
    const [valueWithExchange, setValueWithExchange] = useState(0)
    const isTL = selectedCurrency.currency === "TL"

    function handleAmountChange(val: string) {

        const filtered = val.replace(/[^0-9]/g, '');

        setValue(filtered)

        if (exchangeRate && isTL) {
            setValueWithExchange(parseFloat(val) / parseFloat(exchangeRate))
        }
    }

    async function createCryptoWithdrawRequest(e: FormEvent<HTMLFormElement>) {

        e.preventDefault()

        const formData = new FormData(e.target as HTMLFormElement)

        const ag = formData.get("ag") as string
        const coin = formData.get("coin") as string
        const miktar = formData.get("miktar") as string
        const cuzdan = formData.get("cuzdan") as string


        const [_, err] = await setCryptoWithdrawRequest({ ag, coin, miktar: parseFloat(miktar), cuzdan })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Para yatırma isteğiniz başarılı bir şekilde yapıldı!" })
        handleAmountChange("0")
    }

    return <form onSubmit={createCryptoWithdrawRequest} className="flex flex-col prose max-w-none  gap-4">
        <div className="join rounded-lg">
            <div className="flex content-container p-4 join-item">
                <p className="text-xs">Minimum Çekim Tutarı: <b>100 USD</b></p>
            </div>
            <div className="join-item bg-base-300 px-4 flex items-center content-container">
                {exchangeRate && <p className="text-xs font-bold">{parseFloat(exchangeRate).toFixed(2)} TL</p>}

            </div>
        </div>

        <div className="flex flex-col gap-2">

            <span className="opacity-70 flex-1">Coin</span>
            <select name="coin" className="select select-bordered w-full max-w-xs" required>
                <option disabled selected>Coin Seçin</option>
                <option value={"usdt"}>USDT</option>
            </select>
        </div>
        <div className="flex flex-col gap-2">
            <span className="opacity-70 flex-1">Ağ</span>
            <select name="ag" className="select select-bordered w-full max-w-xs" required>
                <option disabled selected>Ağ Seçin</option>
                <option value={"trc20"}>TRC20</option>
            </select>
        </div>

        <div className="flex flex-col gap-2">
            <span className="opacity-70 flex-1">Adres</span>
            <input name="cuzdan" className="input input-bordered appearance-none" type="text" required />
        </div>


        <div className="flex flex-col gap-2">
            <span className="opacity-70 flex-1">Tutar</span>
            <div className="join">
                <input name="miktar" className="input input-bordered join-item appearance-none" type="text" placeholder="Tutar" defaultValue={0.0} value={value} onChange={(e) => handleAmountChange(e.target.value)} required />
                <span className="btn bg-base-300 join-item cursor-default border-base-300 pointer-events-none">{selectedCurrency.currency}</span>
            </div>
            {isTL && <div className="flex items-center justify-between">
                <p className="text-xs">Dolar Karşılığı:</p>
                <p className="text-xs">${valueWithExchange.toFixed(2)} USD</p>

            </div>}
        </div>


        <div className="mt-4">
            <button className="btn btn-sm btn-primary text-primary-content">Para çekme isteği gönder</button>
        </div>

    </form>

}