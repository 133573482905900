import Error from "../error";
import { OrderList, PositionHistoryResult, PositionList, Result, Suggestion, SymbolName } from "../types";
import http from "./http";
import translate from "./translate";


export async function getUserPositionList(): Promise<Result<PositionList, Error>> {

    try {
        const res = await http.get("user/position")

        if (!res.data.status) {
            throw new Error("Error while getting user positions")
        }

        const data: PositionList = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting user positions", { cause: err })]
    }

}

export async function getUserOrderList(): Promise<Result<OrderList, Error>> {
    try {
        const res = await http.get("user/orders")

        if (!res.data.status) {
            throw new Error("Error while getting user orders")
        }

        const data: OrderList = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting user orders", { cause: err })]
    }
}


export async function getUserPositionHistory(): Promise<Result<PositionHistoryResult, Error>> {
    try {
        const res = await http.get("user/trades")

        if (!res.data.status) {
            throw new Error("Error while getting user position history")
        }

        const data: PositionHistoryResult = res.data.data
        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting user position history", { cause: err })]
    }
}


export async function getBestPositions(): Promise<Result<SymbolName[], Error>> {
    try {
        const res = await http.get("api/bestparite")
        const data: SymbolName[] = res.data.veri

        return [data, null]
    } catch (error) {
        return [null, new Error("Error while getting best positions", { cause: error })]
    }
}

export async function openPositionOrOrder(opt: { emir: number | string, sl: number | string, tp: number | string, Symbol: string, Type: null | number, lot: number, digit: null | number } = { emir: 0.0, sl: 0.0, tp: 0.0, Symbol: "", Type: null, lot: 0.01, digit: null }): Promise<Result<any, Error>> {
    try {
        const res = await http.post("user/order", opt)

        if (!res.data.status) {
            if (res.data.msg) {
                throw translate(res.data.msg)
            }

            if (res.data.data) {
                throw translate(res.data.data)
            }


            throw "Beklenmedik bir hata oluştu"
        }

        const data: any = res.data.data

        return [data, null]
    } catch (err: any) {
        return [null, new Error(err, { cause: err })]
    }
}

export async function getSuggestions(): Promise<Result<Suggestion[], Error>> {
    try {
        const res = await http.get("user/suggestions")
        const data: Suggestion[] = res.data.data

        return [data, null]
    } catch (err) {
        return [null, new Error("Error while getting positions suggestions", { cause: err })]
    }
}


export async function EditPosition(opt: { id: string, sl: number | string, tp: number | string, symbolName: string, digit: number }): Promise<Result<any, Error>> {

    try {
        const res = await http.post("user/order_update", opt)

        if (!res.data.status) {
            throw new Error("Error while opening position")
        }

        const data: any = res.data.data

        return [data, null]
    } catch (error) {
        return [null, new Error("Error while editing position", { cause: error })]
    }
}


export async function ClosePosition(opt: { id: string }): Promise<Result<any, Error>> {

    try {
        const res = await http.post("user/close", { id: opt.id })
        if (!res.data.status) {
            throw res.data.data ? translate(res.data.data) : "Beklenmedik bir hata (Hata kodu bulunamadı)"
        }

        const data: any = res.data.data

        return [data, null]
    } catch (err: any) {

        return [null, new Error(err, { cause: err })]
    }
}