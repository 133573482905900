import PriceText from "../PriceText";
import { emitEvent, eventConstants } from "../../main";
import { Symbol } from "@/types";

export default function ({ item, selectedSymbol, isFavourite, askPrice, bidPrice, onUnsetFavourite, onSetFavourite }: { item: Symbol, selectedSymbol: any, isFavourite: boolean, askPrice: any, bidPrice: any, onUnsetFavourite: (item: any) => void, onSetFavourite: (item: any) => void }) {

    function selectSymbol(symbol: Symbol) {
        if (marketClosed(symbol)) {
            return
        } else {
            emitEvent(eventConstants.SELECT_SYMBOL, symbol.Symbol)
        }
    }

    function marketClosed(ctx: Symbol): boolean {
        const now = Date.now()
        const symbolTime = ctx.Time
        return now - symbolTime > (60 * 60 * 100)
    }



    return <tr onClick={() => selectSymbol(item)} className={"relative " + (marketClosed(item) ? "stock-closed" : "") + (selectedSymbol?.Symbol !== item.Symbol ? ` cursor-pointer hover:bg-base-200` : ` bg-base-200`)}>
        {/* {marketClosed(item) && <div className="absolute top-0 left-0 w-full h-full bg-base-300 bg-opacity-70 flex items-center justify-center font-semibold">
            Piyasa Kapalı
        </div>} */}
        <td className="lg:text-md text-xs lg:px-4 px-0">{item.displayName}</td>
        <th className="lg:text-md text-xs lg:px-4 px-0"><PriceText value={bidPrice} /> </th>
        <th className="lg:text-md text-xs lg:px-4 px-0"><PriceText value={askPrice} /> </th>
        <td className="lg:px-4 px-0">
            {isFavourite ? <button onClick={() => onUnsetFavourite(item)} className="text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>
            </button> : <button onClick={() => onSetFavourite(item)} className="hover:text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                </svg>
            </button>}
        </td>
    </tr>


}