import { CalendarEvent } from "@/types";
import { getDaysInMonth } from "@/utils"
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import CalendarEventModal from "./CalendarEventModal";
import useWindowSize from "@/hooks/useWindowSize";
import MobileCalendarEventModal from "./mobile/MobileCalendarEventModal";
import ErrorMessage from "./ui/ErrorMessage";

export default function () {
    const events = useLoaderData() as CalendarEvent[]

    const days = ["Pzt", "Sal", "Çar", "Per", "Cum", "Cmt", "Paz"]
    const monthsObject: { [key: number]: string } = {
        0: "Ocak",
        1: "Şubat",
        2: "Mart",
        3: "Nisan",
        4: "May",
        5: "Haziran",
        6: "Temmuz",
        7: "Ağustos",
        8: "Eylül",
        9: "Ekim",
        10: "Kasım",
        11: "Aralık",
    };

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
    const [_, setSelectedDate] = useState<null | Date>(null)
    const [dateEvents, setDateEvents] = useState<CalendarEvent[]>([])
    const [originalEventsArray, setOriginalEventsArray] = useState<CalendarEvent[]>([])
    const [eventModalOpen, setEventModalOpen] = useState(false)
    const isMobile = useWindowSize(700)

    function decrementMonth() {
        setCurrentMonth(prev => {

            if (prev - 1 < 0) {
                return 11
            }

            return prev - 1
        })
    }


    function incrementMonth() {
        setCurrentMonth(prev => {

            if (prev + 1 > 11) {
                return 0
            }

            return prev + 1
        })
    }

    function selectDate(date: Date) {

        setSelectedDate(date)
        const filteredDates = events.filter(event => {
            const targetDate = new Date(event.date)
            return (
                date.getDate() === targetDate.getDate() &&
                date.getMonth() === targetDate.getMonth() &&
                date.getFullYear() === targetDate.getFullYear()
            );
        })

        setDateEvents(filteredDates)
        setOriginalEventsArray(filteredDates)

        setEventModalOpen(true)
    }


    function getDateEventsCount(date: Date) {
        return events.filter(item => {
            const targetDate = new Date(item.date)
            return (
                date.getDate() === targetDate.getDate() &&
                date.getMonth() === targetDate.getMonth() &&
                date.getFullYear() === targetDate.getFullYear()
            );
        }).length
    }


    function isToday(date: Date) {
        const targetDate = new Date()
        return (
            date.getDate() === targetDate.getDate() &&
            date.getMonth() === targetDate.getMonth() &&
            date.getFullYear() === targetDate.getFullYear()
        );
    }

    return <>
        {events ? <>
            <div className="flex flex-col p-6 items-center">
                <div className="container">

                    <h1 className="font-bold lg:text-4xl md:text-2xl text-xl">Ekonomik Takvim</h1>

                    <div className="max-w-[1000px] w-full ml-auto mr-auto lg:mt-20 mt-10">
                        <div className="flex items-center justify-center gap-10">
                            <button onClick={decrementMonth}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                </svg>
                            </button>
                            <p className="w-[100px] text-center">{monthsObject[currentMonth]}</p>
                            <button onClick={incrementMonth}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </button>
                        </div>
                        <div className="grid grid-cols-7 mt-10">
                            {days.map((day, index) => {
                                return <div key={index} className="flex justify-center items-center">
                                    {day}
                                </div>
                            })}
                        </div>

                        <div className="grid grid-cols-7 mt-10">
                            {getDaysInMonth(2023, currentMonth).map((date, index) => {
                                const eventCount = getDateEventsCount(date.date)
                                const today = isToday(date.date)
                                return <div onClick={() => selectDate(date.date)} key={index} className="lg:h-24 h-12 p-2"> <div className={"indicator w-full lg:border lg:border-zinc-500 lg:hover:border-primary cursor-pointer rounded-lg h-full p-3 flex-col flex lg:justify-between justify-center " + (date.isPreviousMonth === true ? "opacity-30" : "") + (today ? "border-purple-500" : "")}>
                                    <span className="self-center">{date.dayNumber}</span>
                                    {/* <span className="self-end text-xs opacity-40">{date.dayName}</span> */}
                                    {eventCount > 0 && <span className="self-end text-xs badge badge-primary badge-sm mt-2 indicator-item indicator-bottom">{eventCount} <span className="lg:inline-block hidden">Etkinlik</span></span>}
                                    {today && <span className="self-end text-xs badge badge-sm mt-2 indicator-item indicator-start bg-purple-500 text-primary-content">Bugün</span>}

                                </div></div>
                            })}
                        </div>
                    </div>
                </div>

                {eventModalOpen && !isMobile ? <CalendarEventModal onClose={() => setEventModalOpen(false)} onFilter={(list) => { setDateEvents([...list]) }} filteredEvents={dateEvents} originalEvents={originalEventsArray} /> : null}
                {eventModalOpen && isMobile ? <MobileCalendarEventModal onClose={() => setEventModalOpen(false)} onFilter={(list) => { setDateEvents([...list]) }} filteredEvents={dateEvents} originalEvents={originalEventsArray} /> : null}
            </div>
        </> : <div className="flex flex-col h-full items-center justify-center">
            <ErrorMessage>Takvim Yüklenirken Hata Oluştu</ErrorMessage>
        </div>}
    </>

}