import nProgress from "nprogress";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface CustomLink extends LinkProps {
    children: React.ReactNode
}
export default function ({ children, ...linkProps }: CustomLink) {


    return <Link onClick={() => {
        nProgress.start()
    }} {...linkProps}>{children}</Link>
}