import { useEffect } from 'react';
import { useState } from 'react';

const PriceText = ({ children, value }: { children?: React.ReactNode, value: string | number }) => {
    const [initialValue, setInitialValue] = useState(value)
    const [oldValue, setOldValue] = useState<number | string>("0.0")

    useEffect(() => {
        setOldValue(initialValue)
        setInitialValue(value)
    }, [value])

    return (
        <span className={parseFloat(oldValue as string) > parseFloat(value as string) ? "text-error" : "text-success"}>
            {children}{value}
        </span>
    );
}

export default PriceText;
