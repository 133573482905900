import { LoaderFunctionArgs } from "react-router-dom";
import constants from "@/constants";


export default function (fn: LoaderFunctionArgs) {


    return new Promise((res, _) => {

        const auth = localStorage.getItem(constants.AUTH_STORAGE_KEY)

        if (!auth) {
            window.location.replace("/login")

        } else {
            return res(fn)
        }

    })
}