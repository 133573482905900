import Username from "./Username";
import CustomLink from "./CustomLink";
import HeaderMenu from "./HeaderMenu";
import { AppSettings } from "@/types";
import useWindowSize from "@/hooks/useWindowSize";
import MobileHeaderMenu from "./mobile/MobileHeaderMenu";
import Notifications from "./Notifications";
import MobileNotifications from "./mobile/MobileNotifications";
import { emitEvent, eventConstants } from "../main";
import useBoundStore from "./stores/store";
import ThemeToggle from "./ui/ThemeToggle";


export default function () {

    const isMobile = useWindowSize(700)
    const userSettings = useBoundStore((state) => state.settings)

    const handleOpenBasket = () => {
        emitEvent(eventConstants.OPEN_BASKET, null)
    }

    return <div className="flex items-center p-4 shadow-2xl shadow-base-300">
        <div className="flex-1 flex justify-start">
            <CustomLink to={"/"} className="font-bold lg:text-xl text-md">
                <div className="lg:w-40 w-32">
                    <img src="/logo.png" />
                </div>
            </CustomLink>
        </div>
        <div className="flex items-center gap-2">
            <div className="mr-4 font-bold hidden lg:block">
                <Username />
            </div>


            {userSettings?.isBasketEnabled && <label className="btn btn-circle" onClick={handleOpenBasket}>
                <div className="indicator">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                    </svg>
                </div>
            </label>}

            {isMobile ? <MobileNotifications /> : <Notifications />}
            {isMobile ? <MobileHeaderMenu userSettings={userSettings as AppSettings} /> : <HeaderMenu userSettings={userSettings as AppSettings} />}
            <div className="divider divider-horizontal mx-1"></div>
            <ThemeToggle />
        </div>
    </div>
}