import { Bulletin } from "@/types"
import { groupItemsByDate } from "@/utils"
import { getBulletins } from "@/utils/bulletins"
import { useEffect, useState } from "react"

export default function () {

    const [bulletins, setBulletins] = useState<{ key: string; items: Bulletin[]; }[]>([])

    useEffect(() => {
        getBulletins().then(([data, err]) => {
            if (err) {
                return
            }

            const formatted = groupItemsByDate<Bulletin>(data)
            setBulletins(formatted)
        })


    }, [])


    return <div className="flex flex-col p-6 items-center">
        <div className="container">
            <h1 className="font-bold text-4xl">Bültenler</h1>

            <div className="flex flex-col gap-2 mt-5">
                {bulletins.map(bulletin => {
                    return <div key={bulletin.key} className="collapse collapse-plus  content-container">
                        <input type="checkbox" />
                        <div className="collapse-title text-xl font-medium">

                            <div className="flex items-center gap-4">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                                </svg>
                                <div className="flex flex-col">
                                    {bulletin.key}
                                    <span className="text-sm">{bulletin.items.length} Bülten</span>
                                </div>

                            </div>


                        </div>

                        <div className="collapse-content flex flex-col gap-2">
                            {
                                bulletin.items.map(item => {
                                    return <a key={item._id} href={item.url} className="content-container p-4 rounded-lg flex items-center" target="_blank">
                                        <span className="flex-1">{item.title}</span>
                                        <div className="btn btn-sm btn-primary text-primary-content">Oku</div>
                                    </a>
                                })
                            }
                        </div>
                    </div>
                })}

            </div>

        </div>
    </div>
}