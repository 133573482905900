import React from "react";
import { useRouteError } from "react-router-dom"
import Error403 from "./Error403";

export default function () {
    const error = useRouteError() as { status: number }

    function renderError(status: number) {

        let component: React.ReactNode;

        switch (status) {
            case 403:
                component = <Error403 />
                break;

            default:
                component = <div>Some other error</div>
                break;
        }

        return component
    }
    return <>{renderError(error.status)}</>
}