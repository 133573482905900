import { Subject } from "rxjs";

const alerts = new Subject();

export const eventConstants = {
    "ALERT_SUCCESS": "ALERT_SUCCESS",
    "ALERT_FAIL": "ALERT_FAIL",
    "RE_FETCH_POSITIONS": "RE_FETCH_POSITIONS",
    "RE_FETCH_USER_INFO": "RE_FETCH_USER_INFO",
    "SELECT_SYMBOL": "SELECT_SYMBOL",
    "SET_SYMBOL_SETTINGS": "SET_SYMBOL_SETTINGS",
    "SET_FIREBASE_TOKEN": "SET_FIREBASE_TOKEN",
    "SUB_TO_TOPIC": "SUB_TO_TOPIC",
    "PNL_UPDATED": "PNL_UPDATED",
    "POSITION_PNL_UPDATED": "POSITION_PNL_UPDATED",
    "OPEN_TRADER_PROFILE_MODEL": "OPEN_TRADER_PROFILE_MODEL",
    "NAVIGATE_TRADER_PROFILE": "NAVIGATE_TRADER_PROFILE",
    "OPEN_BASKET": "OPEN_BASKET",
    "OPEN_BASKET_WITH_SELECTED": "OPEN_BASKET_WITH_SELECTED",
    "CLOSE_BASKET_DRAWER": "CLOSE_BASKET_DRAWER",
    "OPEN_DRAWER": "OPEN_DRAWER",
    "CLOSE_DRAWER": "CLOSE_DRAWER",
    "RE_FETCH_TRADER_BASKETS": "RE_FETCH_TRADER_BASKETS",
    "CLOSE_SYMBOL_SETTINGS": "CLOSE_SYMBOL_SETTINGS",
    "SHOW_SYMBOL_INFORMATION": "SHOW_SYMBOL_INFORMATION",
    "CLOSE_SYMBOL_INFORMATION": "CLOSE_SYMBOL_INFORMATION",
    "SHOW_POSITION_HISTORY_DETAIL": "SHOW_POSITION_HISTORY_DETAIL",
    "CLOSE_POSITION_HISTORY_DETAIL": "CLOSE_POSITION_HISTORY_DETAIL",
    "REMOVE_POSITION_FROM_LIST": "REMOVE_POSITION_FROM_LIST",
    "LOGOUT": "LOGOUT",
    "ON_ORDER_CLOSE": "ON_ORDER_CLOSE"
}


export const onEvent = (eventName: string, callback: (data: any) => void, done?: (eventName: string) => void) => {
    const sub = alerts.subscribe((event: any) => {
        if (event.name === eventName) {
            callback(event.data);
        }
    });
    if (done) {
        done(eventName)
    }
    return sub
}

export const emitEvent = (eventName: string, eventData: any) => {
    console.debug(eventName, alerts);
    alerts.next({ name: eventName, data: eventData });
};