import usePrompt from "@/hooks/usePrompt";
import { RobotList, Trader } from "@/types"
import { blockTrader, followTrader, getRobotList, reportTraderRequest, unfollowTraderRequest } from "@/utils"
import { FormEvent, useEffect, useState } from "react"
import { emitEvent, eventConstants } from "../main"
import ReportModal from "./ReportModal";
import EditFollowModal from "./EditFollowModal";
import FollowModal from "./FollowModal";
import RobotListItem from "./ui/RobotListItem";

let idToBlock: null | string = null;

export default function () {

    const [robots, setRobots] = useState<RobotList>([])
    const [selectedTrader, setSelectedTrader] = useState<Trader | null>(null)
    const [editTrader, setEditTrader] = useState<Trader | null>(null)
    const [reportTrader, setReportTrader] = useState<Trader | null>(null)


    const { prompt: BlockTraderPrompt, openPrompt: openBlockTraderPrompt } = usePrompt({
        description: "Bu Robotu engellemek istediğinize emin misiniz?", onAccept: async () => {

            if (!idToBlock) return

            const [_, err] = await blockTrader(idToBlock)

            if (err) {
                emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Robot engellenirken bir hata oluştu!" })

                return
            }

            emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Robot Başarıyla Engellendi!" })
            setRobots(prev => {
                return [...prev.filter(item => item.trader._id !== idToBlock)]
            })
        }
    })


    useEffect(() => {
        getRobotList().then(([data, err]) => {

            if (err) {
                return
            }

            setRobots(data)
        })
    }, [])



    function handleOpenFollowModal(item: Trader) {
        if (item.isFollowing) {
            setEditTrader(item)
        } else {
            setSelectedTrader(item)
        }
    }

    function updateUserFollow(traderId: string, status: boolean) {

        setRobots(prev => {
            const index = prev.findIndex(item => item.trader._id === traderId)

            prev[index].trader.isFollowing = status

            return [...prev]
        })

    }

    async function handleFollowRequest(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const followData = new FormData(e.target as HTMLFormElement)

        const options = followData.getAll("option") as string[]
        const lotPercentage = followData.get("lotPercentage") as string
        const traderId = followData.get("traderId") as string

        const [_, err] = await followTrader({ options: options.join(","), lotPercentage: parseFloat(lotPercentage), traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Robot başarıyla takip edildi!" })
        setSelectedTrader(null)
        setEditTrader(null)
        updateUserFollow(traderId, true)

    }

    async function handleUnfollow(traderId: string) {
        const [_, err] = await unfollowTraderRequest({ traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: err.message })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Robot başarıyla takipten çıkıldı!" })
        setEditTrader(null)
        updateUserFollow(traderId, false)

    }

    function handleOpenBlockTraderPrompt(traderId: string) {
        openBlockTraderPrompt()
        idToBlock = traderId
    }

    function handleOpenReportModal(item: Trader) {
        setReportTrader(item)
    }

    async function handleReportRequest(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const followData = new FormData(e.target as HTMLFormElement)

        const reason = followData.get("reason") as string
        const message = followData.get("message") as string
        const traderId = followData.get("traderId") as string

        const [_, err] = await reportTraderRequest({ reason: reason, message: message, traderId: traderId })

        if (err) {
            emitEvent(eventConstants.ALERT_FAIL, { type: "FAIL", message: "Bu Robot daha önce şikayet edildi!" })
            return
        }

        emitEvent(eventConstants.ALERT_SUCCESS, { type: "SUCCESS", message: "Robot başarıyla şikayet edildi!" })
        setReportTrader(null)
    }


    return <>

        {BlockTraderPrompt()}
        {reportTrader && <ReportModal trader={reportTrader} onSubmit={handleReportRequest} onClose={() => setReportTrader(null)} />}
        {editTrader && <EditFollowModal onUnfollow={handleUnfollow} trader={editTrader} onClose={() => setEditTrader(null)} onSubmit={handleFollowRequest} />}
        {selectedTrader && <FollowModal trader={selectedTrader} onClose={() => setSelectedTrader(null)} onSubmit={handleFollowRequest} />}

        <div className="flex flex-col p-6">
            <h1 className="font-bold text-4xl">Robotlar</h1>

            <div className="grid grid-cols-1 lg:grid-cols-4 2xl:grid-cols-5 gap-6 py-6 w-full">

                {robots.map(item => {

                    return <RobotListItem
                        key={item._id}
                        item={item}
                        onBlock={handleOpenBlockTraderPrompt}
                        onReport={handleOpenReportModal}
                        onFollow={handleOpenFollowModal} />
                })}
            </div>

        </div>

    </>
}