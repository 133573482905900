import { Story, StoryNavigation } from "@/types";
import { storyRead } from "@/utils/story";
import { formatDistanceToNow } from "date-fns";
import { tr } from "date-fns/locale";
import { useEffect, useState } from "react";
import { emitEvent, onEvent } from "../main";
import CustomLink from "./CustomLink";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { SelectedSymbolAtom } from "@/atoms";

const storyEventConstants = {
    "STORY_SEEN": "STORY_SEEN"
}

export default function ({ stories }: { stories: Story[] }) {

    const [selectedCategory, setSelectedCategory] = useState<null | { key: string; photo: string, items: Story[] }>(null)

    const [formattedStories, setFormattedStories] = useState<{ key: string; photo: string, items: Story[] }[]>(() => {

        const groupedItems = new Map<string, Story[]>();

        stories.forEach(story => {

            const dataKey = story.storyCategory.name;

            if (groupedItems.has(dataKey)) {

                groupedItems.get(dataKey)?.push(story)
            } else {
                groupedItems.set(dataKey, [story])
            }

        })

        const result: { key: string; photo: string, items: Story[] }[] = [];
        groupedItems.forEach((value, key) => {
            result.push({ key, photo: value[0].storyCategory.imageUrl, items: value });
        });

        return result

    })

    useEffect(() => {

        const storySeenEvent = onEvent(storyEventConstants.STORY_SEEN, ({ story }: { story: Story }) => {
            storyRead(story._id)
            setFormattedStories(prev => {
                const categoryIndex = prev.findIndex(item => item.key === story.storyCategory.name)
                let category = prev[categoryIndex]

                if (!category) {
                    return prev
                }

                const categoryStories = category.items
                story.isSeen = true

                const index = categoryStories.findIndex(item => item._id === story._id)
                categoryStories[index] = { ...story }
                category.items = [...categoryStories]

                prev[categoryIndex] = { ...category }

                return [...prev]
            })

        })

        return () => {
            storySeenEvent.unsubscribe()
        }

    }, [formattedStories])


    return <>
        {selectedCategory && <StoryModal stories={selectedCategory.items} onClose={() => setSelectedCategory(null)} />}
        <div className="flex gap-3 items-center">
            {formattedStories.map(item => {
                const hasUnseenStory = item.items.filter(item => item.isSeen === true)

                return <div className="flex flex-col items-center gap-3 w-24">
                    <div className="avatar cursor-pointer" onClick={() => setSelectedCategory(item)}>
                        <div className={`w-16 rounded-full ring ${hasUnseenStory ? "ring-primary ring-offset-base-100" : "ring-base-200 ring-offset-base-100"}  ring-offset-[3px]`}>
                            <img src={item.photo} />
                        </div>
                    </div>
                    <span className="text-xs">{item.key}</span>
                </div>
            })}
        </div>
    </>
}



function StoryModal({ stories, onClose }: { stories: Story[], onClose: () => void }) {


    const [startStoryIndex, setStartStoryIndex] = useState(() => {
        const index = stories.findIndex(item => item.isSeen === false)
        return index !== -1 ? index : 0
    })


    const startStory = stories[startStoryIndex]
    const range = Array.from({ length: (stories.length - 0) / 1 }, (_, index) => 0 + index)
    const navigate = useNavigate()
    const setSelectedSymbol = useSetAtom(SelectedSymbolAtom)

    useEffect(() => {

        const interval = setInterval(() => {
            nextStory()
        }, 4000)


        return () => {
            clearInterval(interval)
        }

    }, [startStoryIndex])


    function nextStory() {
        if (startStoryIndex + 1 > stories.length - 1) {
            setStartStoryIndex(0)
            return
        }

        setStartStoryIndex(prev => prev + 1)
        emitEvent(storyEventConstants.STORY_SEEN, { story: stories[startStoryIndex + 1] })
    }

    function previousStory() {
        if (startStoryIndex - 1 < 0) {
            return
        }

        setStartStoryIndex(prev => prev - 1)
        emitEvent(storyEventConstants.STORY_SEEN, { story: stories[startStoryIndex + 1] })

    }


    function storyTypeAction(storyNavigation: StoryNavigation) {
        let element = null;

        if (!storyNavigation?.type) {
            return <CustomLink to={"/"} className="invisible btn btn-primary w-full">Hidden</CustomLink>
        }

        switch (storyNavigation.type) {
            case "DEPOSIT":
                element = <CustomLink to="/deposit" className="btn btn-primary w-full">Para Yatır</CustomLink>
                break;
            case "WITHDRAW":
                element = <CustomLink to="/withdraw" className="btn btn-primary w-full">Para Çek</CustomLink>
                break;
            case "ROBOT":
                element = <CustomLink to="/robots" className="btn btn-primary w-full">Robotlara Git</CustomLink>
                break;
            case "TRADER":
                element = <CustomLink to={"/traders/" + storyNavigation.args?.traderId} className="btn btn-primary w-full">Tradera Git</CustomLink>
                break;
            case "POSITION":
                element = <CustomLink to={"/"} className="btn btn-primary w-full" onClick={e => {
                    e.preventDefault()
                    setSelectedSymbol(null)
                    navigate(`/panel?symbol=${storyNavigation.args.symbol}&type=${storyNavigation.args.type}&tp=${storyNavigation.args.tp}&volume=${storyNavigation.args.volume}`)
                }}>Pozisyona Git</CustomLink>
                break;
            case "APP_CHAT":
                element = <CustomLink to="/deposit" className="btn btn-primary w-full">Para Yatır</CustomLink>
                break;
            case "BULLETTIN":
                element = <CustomLink to="/bulletins" className="btn btn-primary w-full">Bültenlere Git</CustomLink>
                break;
            case "LIVE_SUPPORT":
                element = <CustomLink to="/deposit" className="btn btn-primary w-full">Canlı Desteğe Git</CustomLink>
                break;
        }


        return element
    }

    return <>
        {/* Put this part before </body> tag */}
        <input type="checkbox" id="my_modal_6" className="modal-toggle" checked />
        <div className="modal">
            <div className="modal-box max-w-sm max-h-[650px] w-full h-full relative p-0 story-container">
                <img src={startStory.imageUrl} className="w-full h-full" />
                <div className="flex-col flex gap-3 absolute top-0 left-0 w-full h-full">
                    <div className="flex flex-col gap-3 bg-base-200 bg-opacity-10 story-tick-container p-6 pb-2">
                        <div className="flex gap-1">
                            {range.map((tick) => {
                                return <div key={tick} className="relative flex-1">
                                    <div className={tick < startStoryIndex ? "h-1 bg-white" : "h-1 bg-gray-500"}></div>
                                    {tick === startStoryIndex && <div className={"h-1 bg-white story-tick-item-progress-loading"}></div>}
                                </div>
                            })}
                        </div>

                        <div className="flex gap-2 items-center story-category-container">
                            <div className="avatar">
                                <div className="w-8 rounded-full">
                                    <img src={startStory.storyCategory.imageUrl} />
                                </div>
                            </div>
                            <span className="font-semibold text-white">{startStory.storyCategory.name}</span>
                            <span className="font-semibold text-xs text-white ">-</span>
                            <span className="font-semibold text-white text-xs">{formatDistanceToNow(new Date(startStory.createdDate), { addSuffix: true, locale: tr })}</span>
                        </div>
                    </div>


                    <div className="flex flex-1 justify-between px-6">
                        <div className="h-full flex justify-center items-center story-controls">
                            <button onClick={previousStory} className="btn btn-circle btn-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                </svg>

                            </button>
                        </div>
                        <div className="h-full flex justify-center items-center story-controls">
                            <button onClick={nextStory} className="btn btn-circle btn-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>

                            </button>
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 p-6">
                        <p className="font-semibold bg-zinc-600 bg-opacity-60 w-full p-4 rounded-lg text-center text-white">{startStory.text}</p>
                        {storyTypeAction(startStory.storyNavigation)}
                    </div>

                </div>
            </div>
            <label className="modal-backdrop" htmlFor="my_modal_6" onClick={onClose}>Close</label>
        </div>
    </>

}