import clsx from "clsx";
import { CandleTooltipCustomCallbackData } from "klinecharts";


function getPercentageChange(oldNumber: number, newNumber: number) {
    var difference = oldNumber - newNumber;
    return (difference / oldNumber) * 100;
}


export default function ChartSymbolStats({ chartSymbolData }: { chartSymbolData: CandleTooltipCustomCallbackData }) {

    // {"₺"}
    return <div className="flex items-center gap-2 font-semibold">
        <span className="font-semibold text-base-content"> {chartSymbolData?.current.close.toFixed(2)}</span>
        <ChartSymbolStatsPercentageChange val={getPercentageChange(chartSymbolData.prev?.close || chartSymbolData.current.open, chartSymbolData.current.close)} />
    </div>
}


function ChartSymbolStatsPercentageChange({ val }: { val: number }) {


    return <span className={clsx({ "font-semibold": true, "text-white": val == 0.0, "text-success": val > 0.0, "text-error": val < 0.0 })}> ({val.toFixed(2) + "%"})</span>
}